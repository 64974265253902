import { RouteRecordRaw } from 'vue-router';
import Faqs from './faqNodesConfigurator.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/faq',
        component: Faqs,
        meta: { title: "Faqs"},
    },
];

export default routes;