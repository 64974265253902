
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import Toggler from '@/components/toggler.vue';
import CourseContentModal from './courseContentModal.vue';

@Options({})
export default class CourseContentEditor extends Vue {
 
    @Prop() contents: OM.CourseContent[];

    openContentModal(item: OM.CourseContent){
        this.$opModal.show(CourseContentModal, {
            content: item
        })
    }

    pushContent(){
        this.contents.push(new OM.CourseContent());
    }

    removeContent(i: number){
        if(this.contents[i].url){
            if(!confirm("Confermi rimozione?"))
                return;
        }
        this.contents.splice(i, 1);
    }

    moveSx(i){
        this.array_move(this.contents, i, i - 1);
    }

    moveDx(i){
        this.array_move(this.contents, i, i + 1);
    }

    array_move(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            return;
        }
        let old = arr.splice(old_index, 1);
        arr.splice(new_index, 0, old[0]);
    };
}

