import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 1,
  class: "form-control"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_auto_textarea = _resolveComponent("auto-textarea")!
  const _component_polizza_data = _resolveComponent("polizza-data")!
  const _component_file_previewer = _resolveComponent("file-previewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Professione",
          text: "Professione"
        })
      ]),
      (!_ctx.value.professione || !_ctx.value.professione.name)
        ? _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 0,
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempProfessione) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setProfessione && _ctx.setProfessione(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableProfessioni, (professione, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: professione
              }, _toDisplayString(professione.name), 9, _hoisted_2))
            }), 128))
          ], 544)), [
            [_vModelSelect, _ctx.tempProfessione]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.value.professione.name) + " ", 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-danger",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.value.professione = null))
            }, " X ")
          ]))
    ]),
    (_ctx.value.professione && _ctx.value.professione.identifier)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Qualifica professionale",
                text: "Qualifica professionale"
              })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.qualificaProfessionale) = $event))
            }, null, 512), [
              [_vModelText, _ctx.value.qualificaProfessionale]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Descrizione",
                text: "Descrizione"
              })
            ]),
            _createVNode(_component_auto_textarea, {
              class: "form-control",
              modelValue: _ctx.value.description,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.description) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Numero ordine",
                text: "Numero ordine"
              })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.numeroOrdine) = $event))
            }, null, 512), [
              [_vModelText, _ctx.value.numeroOrdine]
            ])
          ]),
          _createVNode(_component_polizza_data, {
            value: _ctx.value.datiPolizza
          }, null, 8, ["value"]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Immagine firma",
                text: "Immagine firma"
              })
            ]),
            (!_ctx.user.identifier)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Salvare una volta prima di poter caricare l'immagine",
                    text: "Salvare una volta prima di poter caricare l'immagine"
                  })
                ]))
              : (_openBlock(), _createBlock(_component_file_previewer, {
                  key: 1,
                  preview: _ctx.value.firma && _ctx.value.firma.publicUrl,
                  modelValue: _ctx.fileFirma,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fileFirma) = $event))
                }, null, 8, ["preview", "modelValue"])),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary mt-1",
                disabled: !_ctx.fileFirma,
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.uploadFirma && _ctx.uploadFirma(...args)))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Carica immagine (ricordati di salvare dopo)",
                  text: "Carica immagine (ricordati di salvare dopo)"
                })
              ], 8, _hoisted_10)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Immagine timbro",
                text: "Immagine timbro"
              })
            ]),
            (!_ctx.user.identifier)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Salvare una volta prima di poter caricare l'immagine",
                    text: "Salvare una volta prima di poter caricare l'immagine"
                  })
                ]))
              : (_openBlock(), _createBlock(_component_file_previewer, {
                  key: 1,
                  preview: _ctx.value.timbro && _ctx.value.timbro.publicUrl,
                  modelValue: _ctx.fileTimbro,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fileTimbro) = $event))
                }, null, 8, ["preview", "modelValue"])),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary mt-1",
                disabled: !_ctx.fileTimbro,
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.uploadTimbro && _ctx.uploadTimbro(...args)))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Carica immagine (ricordati di salvare dopo)",
                  text: "Carica immagine (ricordati di salvare dopo)"
                })
              ], 8, _hoisted_14)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}