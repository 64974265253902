
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient, DashboardClient } from '@/services/Services';
import { getFineTrimestre, getInizioTrimestre } from '@/utils';

@Options({})
export default class Performance extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    useNegatives: boolean = false;
    earningsData: OM.DateValueOfDecimal[] = [];
    obiettivoData: OM.DateValueOfDecimal[] = [];
    network: number = 0;
    personal: number = 0;
    loaded: boolean = false;
    timePeriod: string = "mese";

    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";
    
    created(){
        this.periodo = moment().startOf('month').toISOString();
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    @Watch('useNegatives')
    onUseNegativesChange(next, prev){
        this.init();
    }

    init(){
        this.fromDate = this.periodo;
        this.toDate = moment(this.fromDate).endOf('month').toISOString();
        DashboardClient.getPerformanceChartValues(this.user.identifier, this.fromDate, this.toDate)
        .then( x => {
            this.earningsData = x.values;
            this.network = x.network;
            this.personal = x.personali;
            this.loaded = true;
        });
    }

}

