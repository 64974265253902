

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { baseUrl } from '@/config';
import { ProvvigioniClient } from '@/services/Services';
import { QualificaProvvigioniConfigClient } from '@/services/Services';

@Options({})
export default class Simulatore extends Vue {

    result: OM.QualificaProvvigioni = null;
    vm: OM.SimulaProvvigioniRequestVm = new OM.SimulaProvvigioniRequestVm();
    selectedProvvigioni: OM.QualificaProvvigioniConfig = null;
    provvigioniConfig: OM.QualificaProvvigioniConfig[] = [];
    
    created(){
        QualificaProvvigioniConfigClient.getAll()
        .then(x => {
            this.provvigioniConfig = x;
        })
    }
    
    onSelectedProvvigioniChange(){
        this.vm.qualificaAttuale.codiceQualifica = this.selectedProvvigioni.codiceQualifica;
        this.vm.qualificaAttuale.puntiTotaliNecessari = this.selectedProvvigioni.puntiTotaliNecessari;
        this.vm.qualificaAttuale.euroPerPunto = this.selectedProvvigioni.euroPerPunto;
        this.vm.qualificaAttuale.modalitaCalcolo = this.selectedProvvigioni.modalitaCalcolo;
        // this.emitModel(this.localValue);
    }

    calculate(){
        ProvvigioniClient.simula(this.vm)
        .then(x => {
            this.result = x;
        })
    }

}

