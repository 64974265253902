
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient, DashboardClient, LeadClient, NetworkClient } from '@/services/Services';
import Node from '@/components/nodeTree/node.vue';
import barreRisultato from './barreRisultato.vue'
import store from '@/store';

@Options({
    components: {
        barreRisultato,
        Node
    }
})
export default class IlMioTeam extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;
    @Prop() rootUserName: string;
    
    loading: boolean = false;
    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";

    subUsers: OM.UserObiettivoCardVm[] = [];
    fatturato: number = 0;

    firstNode: VM.NetworkUserVM = null;

    created(){
        this.fromDate = moment(new Date()).startOf('month').toISOString();
        this.toDate = moment(new Date()).endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    prevMonth(){
        this.fromDate = moment(this.fromDate).subtract(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    nextMonth(){
        this.fromDate = moment(this.fromDate).add(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).add(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    init(){
        this.loading = true;
        Promise.all([
            AcquistoClient.getFatturatoByPeriod(this.fromDate, this.toDate, this.user.identifier),
            DashboardClient.getSubUserObiettivoCard(this.user.identifier, this.fromDate, this.toDate),
            NetworkClient.getCommercialeDescendantsOf(this.user.identifier, true, true, false)
        ])
        .then(x => {
            this.fatturato = x[0];
            this.subUsers = x[1];

            this.firstNode = {
                createdOn: null,
                role: null,
                level: 0,
                username: this.user.personalData.completeName,
                qualifica: this.user.pianoProvvigioni.qualifica,
                puntiReteMaturati: this.user.pianoProvvigioni.puntiReteMaturati,
                identifier: this.user.identifier,
                parentIdentifier: '',
                nome: this.rootUserName,
                imageUrl: this.user.personalData.profilePicture,
                referralCode: "",
                totaleClienti: x[2].filter(x => x.role == Enum.UserRoles.Cliente).length,
                disattivaCalcolo: false,
                nonFumatore: false
            }
        })
        .finally(() => this.loading = false);
    }

    getRisultatoPerc(data: OM.UserObiettivoCardVm){
        if(this.fatturato == 0)
            return 0;
        let ris = data.fatturato * 100 / this.fatturato;
        return ris;
    }

    calcPadding(){
        this.$nextTick(() => {
            let networkContainer = this.$refs.networkContainer as HTMLDivElement;
            let networkContainerScroller = this.$refs.networkContainerScroller as HTMLDivElement;
            let nodi = networkContainer.querySelectorAll('._nodeContainer:not(._singleNode)');
            if(nodi.length == 0){
                return;
            }
            let nodoWidth = nodi[0].getBoundingClientRect().width;
            let totalWidth = nodoWidth * nodi.length;
            networkContainer.style.width = totalWidth + 'px';

            networkContainerScroller.scrollLeft = networkContainer.scrollWidth / 2 - networkContainerScroller.clientWidth / 2;
            // networkContainer.style.paddingLeft = nodoWidth * (Math.floor(nodi.length / 2)) + 'px';
        })
    }

}

