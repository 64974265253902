import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "modalBody" }
const _hoisted_4 = { class: "text-right mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_tiny_editor = _resolveComponent("tiny-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Testo faq",
          text: "Testo faq"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tiny_editor, {
        modelValue: _ctx.local.nodeText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.local.nodeText) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-success mr-2",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Salva",
            text: "Salva"
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-danger",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Annulla",
            text: "Annulla"
          })
        ])
      ])
    ])
  ]))
}