
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class StripeConfigDetails extends mixins(WithModel) {

    declare localValue: OM.StripeConfigDetails;

}

