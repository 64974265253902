
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';

@Options({})
export default class dashboardCentro extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;
    
}

