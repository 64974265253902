import { RouteRecordRaw } from 'vue-router';
import VenditeList from './VenditeList.vue';
import VenditeStorno from './VenditeStorno.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/vendite/list',
        component: VenditeList,
        meta: { title: "Vendite"},
    },
    {
        path: '/vendite/storno',
        component: VenditeStorno,
        meta: { title: "Storno"},
    }
];

export default routes;