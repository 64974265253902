
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import BonificoConfigDetails from './bonificoConfigDetails.vue';
import StripeConfigDetails from './stripeConfigDetails.vue';
import WithModel from '@/mixins/withModel';

@Options({})
export default class Preziario extends mixins(WithModel) {

    declare localValue: OM.PaymentConfigDetails;

}

