

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { AcquistoClient } from '@/services/Services';
import { ExportServices } from '../../services/ExportServices';
import OffersModal from '@/components/offersModal.vue';

@Options({})
export default class VenditeListView extends Vue {

    tableResult: OM.PagedResultOfAcquistoTableVm = new OM.PagedResultOfAcquistoTableVm();
    filter: OM.AcquistoListFilterVm = new OM.AcquistoListFilterVm();

    created(){
        this.filter.pageNumber = 1;
        this.filter.perPage = 25;
        this.filter.sortBy = null;
        this.init();
    }

    init(){
        AcquistoClient.getByFilterVm(this.filter)
        .then( x => {
            this.tableResult = x;
        });
    }

    selectedRow: string = "";

    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    remove(item: OM.AcquistoTableVm) {
        let self: any = this;
        if(!confirm("Sicuro?"))
            return;
        
        AcquistoClient.delete(item.identifier)
        .then(x => {
            this.init();
        })
    }
    
    ricevuta(item: OM.AcquistoTableVm) {
        ExportServices.generaRicevutaCliente(item.identifier)
    }
    
    exportExcel(){
        AcquistoClient.exportExcel()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }
    
    openOffersModal(offers){
        this.$opModal.show(OffersModal, {
            offers
        })
    }
}

