import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nome della nuova lista",
          text: "Nome della nuova lista"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        disabled: !_ctx.name,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_4)
    ])
  ]))
}