
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import { NazioneClient } from '@/services/Services';
import WithModel from '@/mixins/withModel';

@Options({})
export default class Preziario extends mixins(WithModel) {

    declare localValue: { [key: string]: OM.PreziarioEntry };

    nazioniAttive: OM.Nazione[] = [];
    created(){
        NazioneClient.getEnabled()
        .then(x => {
            this.nazioniAttive = x;
            this.nazioniAttive.forEach(naz => {
                if(!this.localValue[naz.alpha2Code]){
                    this.localValue[naz.alpha2Code] = new OM.PreziarioEntry();
                }
            })
        })
    }

}

