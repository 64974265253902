
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class PrefissiModal extends Vue {

    @Prop() selectCb: (item: OM.Nazione) => void;
    @Prop() nazioni: OM.Nazione[];

    select(item){
        this.selectCb(item);
        this.$emit('close');
    }

}

