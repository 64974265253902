
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient, LeadClient } from '@/services/Services';
import store from '@/store';
import ActionModal from './actionModal.vue';

@Options({})
export default class leadActions extends Vue {
 
    @Prop() user: OM.Lead;

    lists: string[] = [];
    statuses: string [] = Enum.LeadStatus.GetAll();
    subStatuses: string [] = [];

    openAction(title: string, commentType: string, status: string, showDate: boolean, nota: string){
        this.$opModal.show(ActionModal, {
            title: title,
            commentType: commentType,
            showDate: showDate,
            status: status,
            leadIdentifier: this.user.identifier,
            nota: nota
        }, () => {
            this.$emit('reload');
        })
    }

    get isChiamato(){
        return !!this.user.chiamato;
    }
    set isChiamato(value: boolean){
        if(value)
            this.user.chiamato = new Date().toISOString();
        else
            this.user.chiamato = null;
    }
    
    setChiamato(){
        if(!confirm("Confermi di aver chiamato il lead?")){
            this.user.chiamato = null;
            return;
        }

        LeadClient.setChiamato(this.user.identifier, !!this.user.chiamato);
    }

}

