

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient } from '@/services/Services';
import store from '@/store';
import OrariPrenotazioneModal from './orariPrenotazioneModal.vue';
import inclusioneOrariModal from '../centriFumo/inclusioneOrariModal.vue';
import esclusioneOrariModal from '../centriFumo/esclusioneOrariModal.vue';

@Options({})
export default class utenzeDetail extends Vue {
 
    user: OM.UpdateCollaboratoreVm = null;
    showAs: string = "DatiBase";

    rolesList: string[] = [];

    created(){
        this.rolesList.push(Enum.UserRoles.Cartella);
        this.rolesList.push(Enum.UserRoles.CentroFumo);
        this.rolesList.push(Enum.UserRoles.Dottore);
        this.rolesList.push(Enum.UserRoles.Collaboratore);
        this.rolesList.push(Enum.UserRoles.Manager);
        this.rolesList.push(Enum.UserRoles.Admin);

        if(<string>this.$route.params.identifier == '0'){
            this.user = new OM.UpdateCollaboratoreVm();
            this.user.role = Enum.UserRoles.Collaboratore;
        } else {
            this.init();
        }
    }

    init(){
        CollaboratoreClient.getByIdVm(<string>this.$route.params.identifier)
        .then( x => {
            this.user = x;
            this.user.authData.password = "";
        });
    }

    save(){
        CollaboratoreClient.save(this.user)
        .then(x => {
            window.toastr.success("OK!");
            if(<string>this.$route.params.identifier == '0'){
                this.$router.replace('/utenze/detail/' + x);
            }
            this.init();
        })
    }

    openModalOrari(){
        this.$opModal.show(OrariPrenotazioneModal, {
            userIdentifier: this.user.identifier
        })
    }

    openInclusioneOrari(){
        this.$opModal.show(inclusioneOrariModal, {
            userIdentifier: this.user.identifier
        })
    }

    openEsclusioneOrari(){
        this.$opModal.show(esclusioneOrariModal, {
            userIdentifier: this.user.identifier
        })
    }


}

