
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import Toggler from '@/components/toggler.vue';
import CourseContentModal from './courseContentModal.vue';

@Options({})
export default class ProductCourse extends Vue {
 
    @Prop() product: OM.ProductCourse;

    created(){
        if(!this.product.courseContents)
            this.product.courseContents = [];
    }
    
}

