import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as OM from '@/Model'
import * as VM from '@/viewModel'

class _UploadServices {

    UploadImage(file: File){
        return CommonServices.uploadFileToUrl<OM.Attachment>(baseUrl + "Upload/UploadImage", {
            file: file
        })
    }

    UploadUserImage(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl(baseUrl + 'Upload/UploadUserImage', vm);
    }

    UploadFile(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<OM.Attachment>(baseUrl + "Upload/UploadFile", vm)
    }

}

export let UploadServices = new _UploadServices();

class _LeadServicesUpload {

    UploadNoteAttachment(vm: VM.UploadCommentAttachmentVm){
        return CommonServices.uploadFileToUrl<Comment>(baseUrl + "Lead/UploadNoteAttachment", vm)
    }
    
    UploadCommentAttachment(vm: VM.UploadCommentAttachmentVm){
        return CommonServices.uploadFileToUrl<Comment>(baseUrl + "Lead/UploadCommentAttachment", vm)
    }
}

export let LeadServicesUpload = new _LeadServicesUpload();
