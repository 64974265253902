
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CertificazionePostVisitaClient, LeadClient, PrenotazioneClient } from '@/services/Services';

@Options({})
export default class uploadPostVisitaModal extends Vue {

    @Prop() prenotazioneIdentifier: string;

    prenotazione: OM.Prenotazione = null;
    mesiPiano: string = "";
    durataGlobaleIntervento: string = "";
    tipologiaPrestazione: string = "";
    certificazione: OM.CertificazionePostVisita = null;
    sent: boolean = false;

    created(){
        CertificazionePostVisitaClient.getByPrenotazioneIdentifier(this.prenotazioneIdentifier).then(x => this.certificazione = x);
        PrenotazioneClient.getById(this.prenotazioneIdentifier).then(x => this.prenotazione = x)
    }

    get canSave(){
        return this.prenotazione && this.mesiPiano;
    }

    save(){
        CertificazionePostVisitaClient.generateCertificazionePostVisita(
            this.prenotazione.cliente.identifier,
            this.prenotazione.user.identifier,
            this.prenotazione.identifier,
            this.prenotazione.professione.identifier,
            this.mesiPiano,
            this.durataGlobaleIntervento,
            this.tipologiaPrestazione
        )
        .then(x => {
            this.certificazione = x;
        })
    }

    sendMail(){
        CertificazionePostVisitaClient.sendCertificazioneMail(this.certificazione.identifier)
        .then(x => {
            this.sent = true;
        })
    }

}

