
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as ENUM from '@/enum';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import store from '@/store';
import router from '@/router';
import toastr from 'toastr';
import { ChatRoomServices } from '@/services/ChatRoomServices';
import { UploadServices } from '@/services/UploadServices';
import linkify from 'linkifyjs/html';
import ChatMessage from './chatMessage.vue';
import FileSelect from '@/components/fileSelect/fileSelect.vue';
import { ChatRoomClient, CollaboratoreClient, HelpTicketClient } from '@/services/Services';

@Options({})
export default class ChatView extends Vue {

    @Prop() chatIdentifier: string;
    @Prop() readTicket: boolean;

    intervalId: any;
    chat: OM.ChatRoomVm = new OM.ChatRoomVm();
    messages: OM.ChatMessage[] = [];
    messagesToRemove: OM.ChatMessage[] = [];
    messageText: string = "";
    quotedMessage: OM.QuotedMessage = null;
    userId: string = store.state.loginData.userIdentifier;
    messageContainer: Element;
    scrollListenerOn: boolean;
    showNuoviMessaggi: boolean = false;
    isTicket: boolean = false;
    ws: WebSocket;
    fetchingData: boolean = false;
    doneFetching: boolean;
    take: number = 20;

    readonly: boolean = false;
    descendants: OM.NameIdentifier[] = []

    get canEdit(){
        return store.state.user.role == ENUM.UserRoles.Admin ||
            store.state.user.role == ENUM.UserRoles.Manager && this.chat.clienteIdentifier != store.state.user.identifier
    }

    created(){
        this.readonly = this.readTicket;
        CollaboratoreClient.getAllNameIdentifier()
        .then(x => this.descendants = x);
    }

    mounted(){
        this.init();
    }

    @Watch('chatIdentifier')
    onChatIdentifierChange(next, prev){
        this.init();
    }

    init(){
        this.messageContainer = document.querySelector('.chatMessageContainer') as Element;
        ChatRoomClient.getVmById(this.chatIdentifier)
        .then( x => {
            this.chat = x;
            if(x.ticketNumber){
                this.isTicket = true;
            }
            ChatRoomClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, true)
            .then(msgs => {
                this.messages = msgs;
                this.$emit('loadedmessages', this.messages.length);
                this.$nextTick( () => {
                    this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
                    this.messageContainer.addEventListener('scroll', this.onTopScroll);
                });
                let webSocket = WebSocket; // || MozWebSocket;
                ChatRoomServices.ConnectSocket(this.chatIdentifier, this.chat.title, store.state.loginData.userIdentifier);
                ChatRoomServices.OnMessage(this.onMessage, this.onDisable, this.onRemove);
            })
        });
    }

    imgPreview: string = '';
    fileToUpload: File = null;
    uploadFile(){
        if(!this.fileToUpload)
            return;

        this.imgPreview = URL.createObjectURL(this.fileToUpload);
    }

    onDblClick(val: OM.ChatMessage){
        this.quotedMessage = {
            sender: val.sender,
            text: val.text,
            fromBackend: val.fromBackend
        };
    }
    cancelQuote(){
        this.quotedMessage = null;
    }

    assignToUser(){
        HelpTicketClient.assignToUser(this.chatIdentifier, this.chat.assignedToUser)
        .then(x => {
            toastr.success('Ticket assegnato');
        })
    }
    
    onTopScroll(){
        if(!this.isAtTop())
            return;
        if(this.fetchingData || this.doneFetching)
            return;
        this.fetchingData = true;
        ChatRoomClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, false)
        .then(msgs => {
            this.fetchingData = false;
            let scrollBottom = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
            this.messages.unshift(...msgs);
            this.$emit('loadedmessages', this.messages.length);
            this.$nextTick(() => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight - scrollBottom;
            });
            if(msgs.length == 0){
                this.doneFetching = true;
                this.messageContainer.removeEventListener('scroll', this.onTopScroll);
            }
        })
    }
    
    isAtTop(){
        return this.messageContainer.scrollTop == 0;
    }
    

    chiudiTicket(){
        if(!this.isTicket){
            return;
        }
        HelpTicketClient.chiudiTicket(this.chatIdentifier, this.chat.disabled)
        .then( x => {
            ChatRoomServices.SendDisableMessage(this.chatIdentifier, this.chat.disabled);
            toastr.success("Stato cambiato");
        })
    }
    destroyed(){
        ChatRoomServices.Disconnect();
    }
    onMessage(message: OM.ChatMessage){
        this.messages.push(message);
        this.$emit('loadedmessages', this.messages.length);
        if(this.isAtBottom()){
            setTimeout( () => {
                this.goToBottom();
            }, 0);
        } else {
            //nuovi messaggi
            this.showNuoviMessaggi = true;
            this.scrollListenerOn = true;
            this.messageContainer.addEventListener('scroll', this.scrollListener);
        }
        this.$emit('message');
    }
    onDisable(data: VM.WsDisableMessage){
        this.chat.disabled = data.disable;
    }
    onRemove(data: VM.WsRemoveMessage){
        let index = this.messages.findIndex(x => x.identifier == data.messageIdentifier);
        this.messages.splice(index, 1);
        this.$emit('messageremove');
        this.$emit('loadedmessages', this.messages.length);
    }
    sendImg(){
        UploadServices.UploadImage(this.fileToUpload)
        .then(x => {
            let newMessage: VM.SendMessageVm = {
                fromBackend: true,
                chatRoomIdentifier: this.chatIdentifier,
                text: `<div class="_chatImg" style="background-image: url(` + x.publicUrl + `);"></div>`,
                senderIdentifier: store.state.user.identifier,
                senderName: store.state.user.personalData.completeName,
                quotedMessage: this.quotedMessage,
                clienteIdentifier: this.chat.clienteIdentifier
            }
            ChatRoomServices.SendMessage(newMessage);
            this.messageText = "";
            this.imgPreview = null;
            this.quotedMessage = null;
            this.fileToUpload = null;
            this.goToBottom();
        }).catch(err => this.fileToUpload = null);
    }
    sendMessage(){
        if(!this.messageText.trim()) {
            return;
        }
        let parsed = linkify(this.messageText);
        let newMessage: VM.SendMessageVm = {
            fromBackend: true,
            chatRoomIdentifier: this.chatIdentifier,
            text: parsed,
            senderIdentifier: store.state.user.identifier,
            senderName: store.state.user.personalData.completeName,
            quotedMessage: this.quotedMessage,
            clienteIdentifier: this.chat.clienteIdentifier
        }
        ChatRoomServices.SendMessage(newMessage);
        this.messageText = "";
        this.quotedMessage = null;
        this.goToBottom();
    }
    
    addReturn(){
        this.messageText += `
`;
    }
    
    isAtBottom(){
        return Math.abs(Math.floor(this.messageContainer.clientHeight - this.messageContainer.scrollHeight)) == Math.floor(this.messageContainer.scrollTop);
    }
    scrollListener(){
        if(this.isAtBottom()){
            this.showNuoviMessaggi = false;
            this.scrollListenerOn = false;
            this.messageContainer.removeEventListener('scroll', this.scrollListener);
        }
    }
    goToBottom(){
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }

    toggleRemoveMessage(item: OM.ChatMessage){
        let index = this.messagesToRemove.indexOf(item);
        if(index > -1){
            this.messagesToRemove.splice(index, 1);
        } else {
            this.messagesToRemove.push(item);
        }
    }

    removeMessages(){
        if(confirm("Confermi?")){
            ChatRoomClient.removeMessageMultiple({ 
                chatRoomIdentifier: this.chatIdentifier,
                messagesIdentifier: this.messagesToRemove.map(x => x.identifier)
            })
            .then( x => {
                this.messagesToRemove.forEach(c => {
                    ChatRoomServices.SendRemoveMessage(this.chatIdentifier, c.identifier);
                })
                this.messagesToRemove = [];
            })
        }
    }
}

