

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import Toggler from '@/components/toggler.vue';
import { BaseProductClient } from '@/services/Services';

@Options({})
export default class AllProducts extends Vue {

    items: OM.BaseProductVm[] = [];
    
    created(){
        setTimeout(this.init, 0);
    }

    init(){
        BaseProductClient.getAll()
        .then(x => {
            this.items = x;
        })
    }
    
    duplicaItem(item){
        BaseProductClient.duplicaProdotto(item.identifier)
        .then(x => {
            this.init();
        });
    }
    
    removeItem(item){
        if(!confirm("Sicuro di voler eliminare il prodotto?")){
            return;
        }
        BaseProductClient.delete(item.identifier)
        .then(x => {
            this.init();
        });
    }

}

