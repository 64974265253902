
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient, ConfigurazioneProfessioneClient, LeadClient } from '@/services/Services';
import store from '@/store';
import WithModel from '@/mixins/withModel';

@Options({})
export default class professionistiAssegnati extends mixins(WithModel) {

    declare localValue: OM.ProfessioneUserPair[];

    dottori: OM.NameIdentifier[] = [];
    professioni: OM.NameIdentifier[] = [];

    created(){
        ConfigurazioneProfessioneClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
            this.professioni.forEach(x => {
                if(!this.localValue.some(c => c.professione == x)){
                    this.localValue.push({
                        professione: x,
                        user: null
                    })
                }
            })
        })
        CollaboratoreClient.getListDottori()
        .then(x => {
            this.dottori = x;
        })
    }

    removeLine(i){
        this.localValue.splice(i, 1);
    }

}

