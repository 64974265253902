

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { ShopCategoryClient } from '@/services/Services';

@Options({})
export default class CategorieProdotti extends Vue {

    categories: OM.ShopCategory[] = [];

    created(){
        setTimeout(this.init, 0);
    }
    init(){
        ShopCategoryClient.getAll()
        .then( x => {
            this.categories = x;
        });
    }

    // addCategory(){
    //     this.newCategory = new OM.ShopCategory();
    //     if(this.categories[0] && this.categories[0].identifier){
    //         this.categories.unshift(this.newCategory);
    //     } else {
    //         if(this.categories[0]){
    //             this.categories[0] = this.newCategory;
    //         } else {
    //             this.categories.unshift(this.newCategory);
    //         }
    //     }
    // }

    goToCategory(item: OM.ShopCategory){
        this.$router.push('/shopConfig/offers/' + item.identifier)
    }

    removeItem(item: OM.ShopCategory, i){
        if(!confirm("Vuoi eliminare " + item.name + "?"))
            return;

        ShopCategoryClient.delete(item.identifier)
        .then(x => {
            this.init();
        });
    }

    saveItem(item: OM.ShopCategory){
        ShopCategoryClient.save(item)
        .then(x => {
            window.toastr.success("Salvato");
        })
        .catch(err => {
            if(err == 'upsell_category_already_set'){
                window.toastr.error("C'è già un'altra categoria impostata come categoria di upsell");
                item.isUpsellCategory = false;
            }
        });
    }

}

