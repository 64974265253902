
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import linkify from 'linkifyjs/html';

@Options({})
export default class CommentText extends Vue {

    @Prop() comment: OM.Comment;

    finalText: string = "";

    created(){
        if(this.comment.text){
            this.finalText = linkify(this.comment.text, {
                target: '_blank'
            });
        }
    }

    @Watch('comment.text')
    onTextChange(next, prev){
        if(this.comment.text){
            this.finalText = linkify(this.comment.text);
        }
    }

}

