

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { AppModules } from '@/router';
import router from '@/router';
import toastr from 'toastr';
import { CollaboratoreClient } from '@/services/Services';

@Options({})
export default class UtenzeNewView extends Vue {

    user: OM.CreateUserVm = new OM.CreateUserVm();
    modules: VM.AppRoute[] = AppModules;
    addUtente(){
        CollaboratoreClient.create(this.user)
        .then(x => {
            toastr.success("Salvato");
            router.push("/utenze/detail/" + x.identifier);
        });
    }

}

