
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class socialNetworksData extends Vue {

    @Prop() value: OM.SocialNetworksData;
    @Prop() readonly: boolean;

}
