import { wsBaseUrl } from '@/config';
import * as VM from '@/viewModel';
import { CommonServices } from './common';
import { StorageServices } from './StorageServices';

class _ChatRoomServices {

    SendMessage(message: VM.SendMessageVm){
        let msg = new VM.SocketMessage();
        msg.type = "message";
        msg.content = message;
        this.ws.send(JSON.stringify(msg));
    }


    private ws: WebSocket;
    private _chatRoomIdentifier: string;
    private _chatRoomName: string;
    private _userIdentifier: string;
    ConnectSocket(chatRoomIdentifier: string, chatRoomName: string, userIdentifier: string){
        this._chatRoomIdentifier = chatRoomIdentifier;
        this._chatRoomName = chatRoomName;
        this._userIdentifier = userIdentifier;

        let token = StorageServices.getLoginData()?.token;

        this.ws = new WebSocket(wsBaseUrl + "?token=" + token);
        this.ws.onopen = (e) => {
            let present = new VM.WsPresentMessage();
            present.chatRoomIdentifier = chatRoomIdentifier;
            present.userIdentifier = userIdentifier;
            present.chatRoomName = chatRoomName;
            let msg = new VM.SocketMessage();
            msg.type = "present";
            msg.content = present;
            this.ws.send(JSON.stringify(msg));
        };
        this.ws.onclose = (e) => {
            this.ws.close();
            setTimeout(() => {
                this.ConnectSocket(this._chatRoomIdentifier, this._chatRoomName, this._userIdentifier);
                this.OnMessage(this._messageListener, this._disableListener, this._removeListener);
            }, 1000);
        }
    }
    private _messageListener;
    private _disableListener;
    private _removeListener;
    OnMessage(messageListener: (msg) => void, 
        disableListener: (disable: VM.WsDisableMessage) => void,
        removeListener: (remove: VM.WsRemoveMessage) => void){
        this._messageListener = messageListener;
        this._disableListener = disableListener;
        this._removeListener = removeListener;
        this.ws.onmessage = (wsMsg: MessageEvent) => {
            let data = <VM.WsMessage<any>>JSON.parse(wsMsg.data);
            if(data.type == 'message'){
                messageListener(data.content);
            } else if(data.type == 'disable'){
                disableListener(data.content);
            } else if(data.type == 'remove'){
                removeListener(data.content);
            }
        }
    }
    Reconnect(callBack){
        this.ws = new WebSocket(wsBaseUrl);
        this.ws.onopen = (e) => {
            let present = new VM.WsPresentMessage();
            present.chatRoomIdentifier = this._chatRoomIdentifier;
            present.userIdentifier = this._userIdentifier;
            let msg = new VM.SocketMessage();
            msg.type = "present";
            msg.content = present;
            this.ws.send(JSON.stringify(msg));
            callBack();
        };
        this.OnMessage(this._messageListener, this._disableListener, this._removeListener);
    }
    SendDisableMessage(chatRoomIdentifier, disable){
        let msg = new VM.SocketMessage();
        msg.type = "disable";
        let disableMessage = new VM.WsDisableMessage();
        disableMessage.chatRoomIdentifier = chatRoomIdentifier;
        disableMessage.disable = disable;
        msg.content = disableMessage;
        this.ws.send(JSON.stringify(msg));
    }
    
    SendRemoveMessage(chatRoomIdentifier, messageIdentifier){
        let msg = new VM.SocketMessage();
        msg.type = "remove";
        let removeMessage = new VM.WsRemoveMessage();
        removeMessage.chatRoomIdentifier = chatRoomIdentifier;
        removeMessage.messageIdentifier = messageIdentifier;
        msg.content = removeMessage;
        this.ws.send(JSON.stringify(msg));
    }

    Disconnect(){
        this.ws.onopen = null;
        this.ws.onmessage = null;
        this.ws.onclose = null;
        this.ws.close();
    }

}

export let ChatRoomServices = new _ChatRoomServices();