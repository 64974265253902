

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import { MailSentLogClient } from '@/services/Services';

@Options({})
export default class mailSentLog extends Vue {

    filter: OM.MailSentLogListFilterVm = new OM.MailSentLogListFilterVm();
    tableResult: OM.PagedResultOfMailSentLog = new OM.PagedResultOfMailSentLog();

    created(){
        this.filter.pageNumber = 1;
        this.filter.perPage = 25;
        this.filter.sortBy = null;
        setTimeout(this.init, 0);
    }
    
    init(){
        MailSentLogClient.getAllPaginated(this.filter)
        .then(x => {
            this.tableResult = x;
        });
    }
    
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

}
