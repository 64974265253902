import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53747173"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalCloser" }
const _hoisted_3 = { class: "pointer" }
const _hoisted_4 = { class: "boxTitle" }
const _hoisted_5 = {
  key: 0,
  class: "form-group"
}
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_date_time = _resolveComponent("op-date-time")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("i", {
          class: "fa fa-times",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
    (_ctx.showDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Seleziona data e ora",
              text: "Seleziona data e ora"
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_op_date_time, {
              class: "form-control",
              initialvalue: null,
              modelValue: _ctx.scadenza,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scadenza) = $event)),
              "min-date": _ctx.today,
              inline: false
            }, null, 8, ["modelValue", "min-date"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Dettagli",
          text: "Dettagli"
        })
      ]),
      _withDirectives(_createElementVNode("textarea", {
        placeholder: "Scrivi i dettagli importanti",
        class: "form-control",
        rows: "4",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.xnota) = $event))
      }, null, 512), [
        [_vModelText, _ctx.xnota]
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        disabled: !_ctx.canSave,
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_9)
    ])
  ]))
}