import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "{{author}} ha lasciato una nota su {{cliente}}",
        text: "{{author}} ha lasciato una nota su {{cliente}}",
        object: { author: _ctx.item.author.name, cliente: _ctx.item.cliente.name }
      }, null, 8, ["object"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_router_link, {
        class: "btn btn-link",
        to: 'lead/detail/' + _ctx.item.cliente.identifier + '/visualizza'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_localized_text, {
            localizedKey: "Vedi scheda",
            text: "Vedi scheda"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ], 64))
}