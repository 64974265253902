import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comments_container = _resolveComponent("comments-container")!

  return (_openBlock(), _createBlock(_component_comments_container, {
    comments: _ctx.user.cronologiaStati,
    modify: false,
    onDeleteComment: _ctx.remove
  }, null, 8, ["comments", "onDeleteComment"]))
}