import { RouteRecordRaw } from 'vue-router';
import prenotazioni from './prenotazioni.vue';
import prenotazioniGestione from './prenotazioniGestione.vue';
import prenotazioniDetail from './prenotazioniDetail.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/prenotazioni',
        component: prenotazioni,
        meta: { title: "Prenotazioni"},
    },
    {
        path: '/prenotazioniGestione',
        component: prenotazioniGestione,
        meta: { title: "Prenotazioni Gestione"},
    },
    {
        path: '/prenotazioni/:identifier',
        component: prenotazioniDetail,
        meta: { title: "Dettaglio prenotazione"},
    },
];

export default routes;