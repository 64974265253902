import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d52c8e84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalHeader" }
const _hoisted_2 = { class: "modalBody" }
const _hoisted_3 = { class: "modalFooter text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_appuntamento_centro_fumo = _resolveComponent("appuntamento-centro-fumo")!
  const _component_appuntamento_video_call = _resolveComponent("appuntamento-video-call")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["opModal", { large: _ctx.ticketPrenotazione.ruoloPrenotazione == 'CentroFumo' }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Prenota il tuo appuntamento!",
          text: "Prenota il tuo appuntamento!"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.ticketPrenotazione.ruoloPrenotazione == 'CentroFumo')
        ? (_openBlock(), _createBlock(_component_appuntamento_centro_fumo, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.ticketPrenotazione.ruoloPrenotazione == 'Dottore')
        ? (_openBlock(), _createBlock(_component_appuntamento_video_call, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-danger",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ], 2))
}