
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { RicevuteUtenteClient, DocumentoDashboardClient, CollaboratoreClient, EsclusioneOrariPrenotazioneClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';
import { ModalServices } from '@/services/ModalServices';
import EventBus from '@/services/EventBus';
import { JsonClone } from '@/utils';
import prenotaPerClienteModal from './prenotaPerClienteModal.vue';

@Options({})
export default class appuntamentiOfDateModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() vm: OM.OrariPerPrenotareVm;
    @Prop() prenotazioni: OM.PrenotazioneForListVm[];
    @Prop() professione: OM.NameIdentifier;
    @Prop() date: Date;

    localOrari: OM.OrariPerPrenotareVm = new OM.OrariPerPrenotareVm();
    esclusioni: OM.EsclusioneOrariPrenotazione[] = [];
    professioni: OM.NameIdentifier[] = [];
    selectedProfessione: OM.NameIdentifier = null;

    created(){
        if(this.professione){
            this.selectedProfessione = this.professione;
        }
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        if(this.vm){
            this.localOrari = JsonClone(this.vm);
            EsclusioneOrariPrenotazioneClient.getByDate(this.userIdentifier, this.selectedProfessione.identifier, this.vm.date)
            .then(x => {
                this.esclusioni = x;
                this.localOrari.fasceOrarie.push(...x.map(c => c.orario));
                this.localOrari.fasceOrarie = this.localOrari.fasceOrarie.sort((a, b) => parseInt(a.from) - parseInt(b.to))
            })
        } else {
            this.localOrari = new OM.OrariPerPrenotareVm();
        }
    }

    prenota(item: OM.FasciaOraria){
        this.$opModal.show(prenotaPerClienteModal, {
            userIdentifier: this.userIdentifier,
            fasciaOraria: item,
            date: this.date
        })
    }

    isEsclusione(item: OM.FasciaOraria){
        return this.esclusioni.some(x => x.orario.from == item.from && x.orario.to == item.to);
    }

    disabilita(item: OM.FasciaOraria){
        ModalServices.confirmModal("Confermi?", "Vuoi disabilitare questo slot?", null, null, () => {
            let toSave = new OM.EsclusioneOrariPrenotazione();
            toSave.date = this.localOrari.date;
            toSave.orario = item;
            toSave.userIdentifier = this.userIdentifier;
            toSave.professione = this.professione;
            EsclusioneOrariPrenotazioneClient.save([toSave])
            .then(x => {
                toSave.identifier = x[0];
                this.esclusioni.push(toSave);
                window.toastr.success("Salvato");
                EventBus.$emit('reloaddash');
            })
        })
    }

    riabilita(item: OM.FasciaOraria){
        let esclusione = this.esclusioni.find(x => x.orario.from == item.from && x.orario.to == item.to);
        EsclusioneOrariPrenotazioneClient.deleteById(esclusione.identifier)
            .then(x => {
                this.esclusioni = this.esclusioni.filter(c => c.identifier != esclusione.identifier);
                window.toastr.success("Fatto");
                EventBus.$emit('reloaddash');
            })
    }

}

