
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreServices } from '@/services/CollaboratoreServices'
import { ConfigurazioneProfessioneClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';

@Options({})
export default class dottoreData extends Vue {

    @Prop() user: OM.Collaboratore;
    @Prop() value: OM.DottoreData;

    fileFirma: File = null;
    fileTimbro: File = null;
    professioni: OM.NameIdentifier[] = [];
    tempProfessione: OM.NameIdentifier = null;;

    created(){
        ConfigurazioneProfessioneClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
    }

    uploadFirma(){
        UploadServices.UploadFile({
            file: this.fileFirma,
            itemIdentifier: this.user.identifier
        })
        .then(x => {
            this.value.firma = x;
            this.fileFirma = null;
        })
    }
    uploadTimbro(){
        UploadServices.UploadFile({
            file: this.fileTimbro,
            itemIdentifier: this.user.identifier
        })
        .then(x => {
            this.value.timbro = x;
            this.fileTimbro = null;
        })
    }
    
    get availableProfessioni(){
        return this.professioni.filter(x => !this.user.dottoreDatas.map(x => x.professione).some(c => c && c.identifier == x.identifier));
    }

    setProfessione(){
        this.value.professione = this.tempProfessione;
        this.tempProfessione = null;
    }
    
}
