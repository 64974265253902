
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class spesaItem extends mixins(WithModel) {

    declare localValue: OM.SpesaItem;

}

