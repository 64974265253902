import ChatRoom from './ChatRoom.vue';
import ChatPage from './ChatPage.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/chat',
        component: ChatRoom,
        meta: { title: "Stanze chat"},
        children: [
            {
                path: ':identifier',
                component: ChatPage
            }
        ]
    }
];

export default routes;