import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "comments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment = _resolveComponent("comment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (item, i) => {
      return (_openBlock(), _createBlock(_component_comment, {
        comment: item,
        key: i,
        onRemove: _ctx.remove,
        removable: _ctx.removable,
        "always-removable": _ctx.alwaysRemovable
      }, null, 8, ["comment", "onRemove", "removable", "always-removable"]))
    }), 128))
  ]))
}