
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { NazioneClient } from '@/services/Services';
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class bankAccountData extends mixins(WithModel) {

    @Prop() label: string;
    @Prop() readonly: boolean;

    prefixList: OM.NumberNazione[] = [];

    created(){
        NazioneClient.getPrefissiTelefonici()
        .then(x => {
            this.prefixList = x;
        })
    }

}
