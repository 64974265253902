import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between mb-2" }
const _hoisted_2 = { class: "bigTitle" }
const _hoisted_3 = {
  width: "80",
  filter: "createdOn",
  filterType: "dateRange",
  filterFrom: "fromDate",
  filterTo: "toDate",
  sort: "createdOn"
}
const _hoisted_4 = {
  width: "120",
  filter: "user.name",
  sort: "user.name"
}
const _hoisted_5 = {
  width: "120",
  filter: "orario.from",
  sort: "orario.from"
}
const _hoisted_6 = {
  filter: "cliente",
  sort: "cliente.name"
}
const _hoisted_7 = {
  width: "100",
  filter: "statoHistory[0].value",
  sort: "statoHistory[0].value"
}
const _hoisted_8 = {
  width: "200",
  filter: "note",
  sort: "note"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { colspan: "99" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_localized_text, {
          localizedKey: "Prenotazioni della tua rete",
          text: "Prenotazioni della tua rete"
        })
      ])
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.items
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_localized_text, {
                localizedKey: "Data",
                text: "Data"
              })
            ]),
            _createElementVNode("td", _hoisted_4, [
              _createVNode(_component_localized_text, {
                localizedKey: "Centro",
                text: "Centro"
              })
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: "Fascia oraria",
                text: "Fascia oraria"
              })
            ]),
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_localized_text, {
                localizedKey: "Cliente",
                text: "Cliente"
              })
            ]),
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_localized_text, {
                localizedKey: "Stato",
                text: "Stato"
              })
            ]),
            _createElementVNode("td", _hoisted_8, [
              _createVNode(_component_localized_text, {
                localizedKey: "Note",
                text: "Note"
              })
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                _createElementVNode("td", _hoisted_10, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.PrenotazioneForListVm[]), (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.data)), 1),
              _createElementVNode("td", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-link text-left",
                  onClick: ($event: any) => (_ctx.centroDetails(item.user))
                }, [
                  _createElementVNode("u", null, _toDisplayString(item.user.name), 1)
                ], 8, _hoisted_11)
              ]),
              _createElementVNode("td", null, _toDisplayString(_ctx.$filters.timespan(item.fasciaOraria.from)) + " - " + _toDisplayString(_ctx.$filters.timespan(item.fasciaOraria.to)), 1),
              _createElementVNode("td", null, _toDisplayString(item.cliente.name), 1),
              _createElementVNode("td", null, _toDisplayString(item.stato.value), 1),
              _createElementVNode("td", null, _toDisplayString(item.note), 1)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}