
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import { CollaboratoreClient } from '@/services/Services';

@Options({})
export default class dashboardManager extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    dottori: OM.DottoreForListVm[] = [];
    selectedDottore: string = "";
    reloadDottore: number = 1;

    created(){
        CollaboratoreClient.getDottoriForList(null)
        .then(x => {
            this.dottori = x;
            if(this.dottori.length > 0)
                this.selectedDottore = this.dottori[0].identifier
        })
    }

    @Watch('selectedDottore')
    onSelectedDottoreChange(next, prev){
        this.reloadDottore++;
    }

    
}

