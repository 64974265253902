
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import Calendar from '@/components/calendar.vue';
import RiepilogoModal from '@/views/gestioneLead/scadenze/riepilogoModal.vue';
import { LeadClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class CalendarPrenotazione extends Vue {

    @Prop() availabilities: OM.OrariPerPrenotareVm[];
    @Prop() value: OM.MakePrenotazioneVm;

    selectedOrari: OM.FasciaOraria[] = [];

    selectDate(day){
        if(this.getFasceOfDay(day).length == 0)
            return;
            
        this.value.date = day.date;
        this.selectedOrari = this.getFasceOfDay(day);
    }

    getFasceOfDay(day){
        let ofDate = this.availabilities.find(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        if(ofDate)
            return ofDate.fasceOrarie;
        else
            return [];
    }

    setFascia(fascia: OM.FasciaOraria){
        this.value.fasciaOraria.from = fascia.from;
        this.value.fasciaOraria.to = fascia.to;
    }

    back(){
        this.value.date = null;
        this.value.fasciaOraria.from = "";
        this.value.fasciaOraria.to = "";
    }

}

