
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import addLinkedAccountModal from './addLinkedAccountModal.vue'
import { LeadClient, LoginClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import { CommonServices } from '@/services/common';
import store from '@/store';

@Options({})
export default class linkedAccountModal extends Vue {

    addAccountModal(){
        this.$opModal.show(addLinkedAccountModal, {});
    }

    switchToThis(item: OM.NameIdentifier){
        LoginClient.switchToAccount(item.identifier)
        .then(x => {
            LoginServices.loginCallback(x)
            .then(x => {
                this.$router.replace('/dashboard')
                .then(x => {
                    store.state.showSpinner = 99;
                    window.location.reload();
                });
            });
        })
    }

    removeThis(item: OM.NameIdentifier){
        LeadClient.removeLinkedAccount(item.identifier)
        .then(x => {
            store.state.user.linkedAccounts = store.state.user.linkedAccounts.filter(x => x != item);
        })
    }

}

