

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

import { baseUrl } from '@/config';
import ProvvigioniTable from './provvigioniTable.vue';
import { DashboardClient, ProvvigioniClient, RicevuteUtenteClient } from '@/services/Services';

@Options({})
export default class ProvvigioniUtente extends Vue {

    portafoglio: OM.DashboardPortafoglioVm = null;

    nazioni: string[] = [];
    nazioneSelected: string = "";

    ricevute: OM.RicevutaUtente[] = [];
    ricevuta: OM.RicevutaUtenteInsertVm = new OM.RicevutaUtenteInsertVm();
    
    loadingProvvigioni: boolean = false;
    vm: OM.ProvvigioniListFilterVm = new OM.ProvvigioniListFilterVm();
    pagedResult: OM.PagedResultOfProvvigioniListVm = new OM.PagedResultOfProvvigioniListVm();

    insertRicevuta(){
        this.ricevuta.userIdentifier = this.$route.params.identifier as string;

        RicevuteUtenteClient.add(this.ricevuta)
        .then(x => { 
            this.init() 
        });
    }
    created(){
        this.vm.userIdentifier = this.$route.params.identifier as string;
        setTimeout(this.init, 0);
    }

    download(identifier: string){
        window.open(baseUrl +"ricevuteutente/download?ricevutaIdentifier="+identifier,"_blank");
    }

    init(){
        DashboardClient.getNazioniByPagamenti(this.$route.params.identifier as string).then(x => this.nazioni = x);
        DashboardClient.getDashPortafoglio(this.$route.params.identifier as string, this.nazioneSelected, false)
        .then( x => this.portafoglio = x);
        DashboardClient.getDashRicevute(this.$route.params.identifier as string, false)
        .then( x => this.ricevute = x);

        this.getProvvigioni();
    }

    getProvvigioni(){
        this.loadingProvvigioni = true;
        ProvvigioniClient.list(this.vm)
        .then( x => {
            this.pagedResult = x;
            this.loadingProvvigioni = false;
            this.calcPagination();
        })
    }


    setSelectedPage(i){
        this.vm.pageNumber = i;
        this.init();
    }
    subtractPage(){
        if(this.vm.pageNumber <= 1)
            return;

        this.vm.pageNumber--;
        this.init();
    }
    addPage(){
        if(this.vm.pageNumber >= this.totalPages)
            return;

        this.vm.pageNumber++;
        this.init();
    }
    get totalPages(){
        return Math.ceil(this.pagedResult.count / this.vm.perPage);
    }

    lowerPages: number = 0;
    upperPages: number = 0;
    pages: number[] = [];
    calcPagination(){
        if(this.vm.pageNumber > this.totalPages && this.vm.pageNumber > 1){
            this.vm.pageNumber = this.totalPages;
        }
        this.lowerPages = this.vm.pageNumber - 5;
        this.upperPages = this.vm.pageNumber + 5;
        if(this.lowerPages <= 0)
            this.lowerPages = 1;
        if(this.upperPages > this.totalPages)
            this.upperPages = this.totalPages;
        this.pages = [];
        for(let i = this.lowerPages; i <= this.upperPages; i++){
            this.pages.push(i);
        }
    }
}

