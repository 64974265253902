

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr';
import productCourse from './productCourse.vue';
import productAppuntamento from './productAppuntamento.vue';
import productPianoAlimentare from './productPianoAlimentare.vue';
import { BaseProductClient, ProductAppuntamentoClient, ProductCourseClient, ProductPianoAlimentareClient, ProductSpedibileClient } from '@/services/Services';

@Options({
    components: {
        productCourse,
        productAppuntamento,
        productPianoAlimentare
    }
})
export default class ProductDetail extends Vue {
 
    product: OM.BaseProduct = null;
    productTypes: string[] = Enum.ProductType.GetAll();

    created(){
        if(this.$route.params.identifier as string == '0'){
            this.product = new OM.BaseProduct();
        } else {
            BaseProductClient.getById(this.$route.params.identifier as string)
            .then(x => {
                this.product = x;
            })
        }
    }

    save(){
        let promise;
        if(this.product.productType == Enum.ProductType.Spedibile) {
            promise = ProductSpedibileClient.save(this.product as OM.ProductSpedibile)
        } else if(this.product.productType == Enum.ProductType.Corso) {
            promise = ProductCourseClient.save(this.product as OM.ProductCourse)
        } else if(this.product.productType == Enum.ProductType.Appuntamento) {
            promise = ProductAppuntamentoClient.save(this.product as OM.ProductAppuntamento)
        } else if(this.product.productType == Enum.ProductType.PianoAlimentare) {
            promise = ProductPianoAlimentareClient.save(this.product as OM.ProductPianoAlimentare)
        }
        promise.then(x => {
            this.product.identifier = x;
            if(this.$route.params.identifier as string == '0'){
                this.$router.replace('/shopConfig/product/' + x);
            }
            toastr.success("Salvato");
        })
    }

}

