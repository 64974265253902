
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, ClienteClient, LeadClient, LoginClient, PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { validateEmail } from '@/utils';
import EventBus from '@/services/EventBus';

@Options({})
export default class prenotaPerClienteModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() fasciaOraria: OM.FasciaOraria;
    @Prop() date: Date;

    clientiForDropdown: OM.NameIdentifier[] = [];
    selectedCliente: OM.NameIdentifier = null;
    clienteDetails: OM.Cliente = new OM.Cliente();
    isMailAvailable: boolean = true;
    mode: string = "search";

    registerVm: OM.RegisterVm = new OM.RegisterVm();
    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();

    created(){

        if(!(<OM.Collaboratore>store.state.user).commercialeDiRiferimento){
            ModalServices.alertModal("Errore", "Non è stato associato il commerciale di riferimento. Contattare il supporto", "", () => {
                this.$opModal.closeAll();
            });
        }

        TicketPrenotazioneClient.getAvailableTicketsWithRole(this.userIdentifier)
        .then(x => {
            this.vm.ticketPrenotazioneIdentifier = x[0].identifier;
            if(x.length == 0){
                return;
            }
            ClienteClient.searchCliente("", (<OM.Collaboratore>store.state.user).commercialeDiRiferimento.identifier)
            .then(x => {
                this.clientiForDropdown = x;
                if(this.$route.params.identifier as string){
                    this.selectedCliente = this.clientiForDropdown.find(x => x.identifier == this.$route.params.identifier as string);
                }
            })
        })
    }

    searchCb(input: string){
        return this.clientiForDropdown.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    @Watch('selectedCliente')
    onSelectedClienteChange(next, prev){
        this.vm.cliente = this.selectedCliente;
        ClienteClient.getById(this.vm.cliente.identifier)
        .then(x => {
            this.clienteDetails = x;
        });
    }

    prenota(){
        if(!this.canNext){
            return;
        }

        LeadClient.getById(this.userIdentifier)
        .then(x => {

            this.registerVm.nazione = x.personalData.nazione;
            this.registerVm.referralCode = x.referralCode;
            this.registerVm.nomeCentro = x.personalData.completeName;

            let prom;
            if(this.mode == "new"){
                prom = LoginClient.registerFromCentro(this.registerVm);
            } else {
                prom = Promise.resolve();
            }
            prom.then(cliente => {
                if(cliente){
                    this.vm.cliente = {
                        identifier: cliente.identifier,
                        name: cliente.personalData.completeName
                    }
                } else {
                    this.vm.cliente = this.selectedCliente;
                }
                this.vm.date = this.date.toISOString();
                this.vm.fasciaOraria = this.fasciaOraria;
                this.vm.user = {
                    identifier: x.identifier,
                    name: x.personalData.completeName
                };
                PrenotazioneClient.makePrenotazione(this.vm)
                .then(x => {
                    ModalServices.alertModal(
                        'Appuntamento confermato!',
                        'Appuntamento registrato con successo',
                        null,
                        () => {
                            this.$opModal.closeAll();
                            EventBus.$emit('reloaddash');
                        }
                    )
                })
            })
        })
    }

    get canNext(){
        if(this.mode == 'new'){
            return this.isMailAvailable && this.registerVm.name && this.registerVm.surname && validateEmail(this.registerVm.email) && 
                this.registerVm.telephone.number && this.registerVm.telephone.prefix;
        } else {
            return !!this.selectedCliente;
        }
    }

    debuncio: any = null;
    @Watch('registerVm.email')
    onEmailChange(next, prev){
        clearTimeout(this.debuncio);
        this.debuncio = setTimeout(() => {
            LoginClient.checkEmailAvailable(this.registerVm.email)
            .then(x => {
                this.isMailAvailable = x;
            })
        }, 800);
    }

}

