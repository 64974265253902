import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trello" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "trelloMove left",
      onDragover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveTrelloLeft($event)))
    }, null, 32),
    _createElementVNode("div", {
      class: "trelloMove right",
      onDragover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.moveTrelloRight($event)))
    }, null, 32),
    _createElementVNode("div", {
      ref: "trello",
      class: "trelloScroll",
      onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
      onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
      onMousemove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onMouseMove && _ctx.onMouseMove(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 544)
  ]))
}