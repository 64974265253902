import Utenze from './collaboratori.vue';
import UtenzeNew from './UtenzeNew.vue';
import UtenzeDetail from './UtenzeDetail.vue';
import RichiestePagamento from './RichiestePagamento.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/utenze/list',
        component: Utenze,
        meta: { title: "Collaboratori"},
    },
    {
        path: '/utenze/detail/:identifier',
        component: UtenzeDetail,
        meta: { title: "Dettaglio utente"},
    },
    {
        path: '/utenze/new',
        component: UtenzeNew,
        meta: { title: "Aggiungi utente"},
    },
    {
        path: '/utenze/richiestePagamento',
        component: RichiestePagamento,
        meta: { title: "Richieste pagamento"},
    }
];

export default routes;