
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { LeadClient, ProductAppuntamentoClient, TicketPrenotazioneClient } from '@/services/Services';

@Options({})
export default class caricaTicketModal extends Vue {

    vm: OM.TicketPrenotazione = new OM.TicketPrenotazione();
    howMany: number = 1;

    namesForDropDown: OM.NameIdentifier[] = [];
    selectedName: OM.NameIdentifier = null;
    prodsForDropDown: OM.NameIdentifier[] = [];
    selectedProd: OM.NameIdentifier = null;

    created(){
        Promise.all([
            LeadClient.getNameIdentifiers(),
            ProductAppuntamentoClient.getAllNameIdentifier()
        ])
        .then(x => {
            this.namesForDropDown = x[0];
            this.prodsForDropDown = x[1];
        })
    }

    searchCbNames(input: string){
        return this.namesForDropDown.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    searchCbProds(input: string){
        return this.prodsForDropDown.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    @Watch('selectedName')
    onSelectedClienteChange(next, prev){
        this.vm.cliente = this.selectedName;
    }

    @Watch('selectedProd')
    onSelectedProdChange(next, prev){
        this.vm.product = this.selectedProd;
    }

    save(){
        let proms = [];
        for(let i = 0; i < this.howMany; i++){
            proms.push(TicketPrenotazioneClient.saveTicket(this.vm));
        }
        Promise.all(proms)
        .then(x => {
            window.toastr.success("Ticket caricati");
            this.$emit('close');
        })
    }

}

