

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { MisuraClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class misureTable extends Vue {
 
    @Prop() user: OM.UpdateLeadVm;

    misure: OM.Misura[] = [];

    created(){
        this.init();
    }

    init(){
        MisuraClient.getMisureOfUser(this.user.identifier).then(x => this.misure = x);
    }

    saveMisura(item: OM.Misura){
        MisuraClient.save(item)
        .then(x => {
            delete (<any>item).modify;
        });
    }

    addMisura(){
        let toSave = new OM.Misura();
        toSave.cliente = {
            identifier: this.user.identifier,
            name: this.user.personalData.completeName
        };
        toSave.data = new Date().toISOString();
        MisuraClient.save(toSave)
        .then(x => {
            this.init();
        })
    }
    
    removeMisura(item: OM.Misura, i: number){
        if(!confirm("Confermi?"))
            return;

        MisuraClient.deleteById(item.identifier)
        .then(x => {
            this.misure.splice(i, 1);
        });
    }

}

