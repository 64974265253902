
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';

@Options({})
export default class dashboardLead extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    localReload: number = 0;

    created(){
        this.localReload = this.reload;
    }

    @Watch('reload')
    onReload(next, prev){
        this.localReload = this.reload;
    }
    
}

