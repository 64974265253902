
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { LeadClient } from '@/services/Services';
import { LeadServices } from '@/services/LeadServices';
import EventBus from '@/services/EventBus';

@Options({})
export default class commenti extends Vue {

    @Prop() user: OM.Lead;
    @Prop() readonly: boolean;

    addCommento(vm: VM.AddCommentEvent){
        LeadServices.AddCommento({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.user.identifier
        })
        .then(x => {
            this.user.comments.unshift(x);
            window.toastr.success("Salvato!");
            EventBus.$emit('newcomment');
        })
    }
    
    remove(item: OM.Comment){
        LeadClient.removeComment(item.identifier, this.user.identifier)
        .then(x => {
            let index = this.user.comments.findIndex(x => x.identifier == item.identifier);
            this.user.comments.splice(index, 1);
        })
    }

}
