import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_form_input, {
      type: "text",
      label: "Facebook",
      modelValue: _ctx.value.facebook,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.facebook) = $event)),
      placeholder: "Facebook",
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "readonly"]),
    _createVNode(_component_form_input, {
      type: "text",
      label: "Instagram",
      modelValue: _ctx.value.instagram,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.instagram) = $event)),
      placeholder: "Instagram",
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "readonly"]),
    _createVNode(_component_form_input, {
      type: "text",
      label: "Twitter",
      modelValue: _ctx.value.twitter,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.twitter) = $event)),
      placeholder: "Twitter",
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "readonly"])
  ]))
}