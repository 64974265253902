import PagamentoBonifico from './pagamentoBonificos.vue';
import PagamentoBonificoDetail from './pagamentoBonificoDetail.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/pagamentoBonifico',
        component: PagamentoBonifico,
        meta: { title: "Pagamenti con bonifico"},
    },
    {
        path: '/pagamentoBonifico/:identifier',
        component: PagamentoBonificoDetail,
        meta: { title: "Dettaglio pagamento"},
    },
];

export default routes;