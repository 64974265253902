import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flexRow pb-5"
}
const _hoisted_2 = { class: "bigTitle" }
const _hoisted_3 = { class: "midTitle mt-3" }
const _hoisted_4 = {
  key: 0,
  class: "my-2"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 2,
  class: "text-center"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_select_and_buy_offers = _resolveComponent("select-and-buy-offers")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_localized_text, {
            localizedKey: "Categoria negozio",
            text: "Categoria negozio"
          })
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.shopCategory.name), 1),
        (_ctx.clientiCollegati.length > 0 && !_ctx.cardPaid && !_ctx.bonificoPaid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Attiva il prodotto per:",
                    text: "Attiva il prodotto per:"
                  })
                ]),
                _withDirectives(_createElementVNode("select", {
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activateOnCliente) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clientiCollegati, (item, i) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: i,
                      value: item
                    }, _toDisplayString(item.name), 9, _hoisted_6))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.activateOnCliente]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.cardPaid && !_ctx.bonificoPaid && _ctx.offers.length > 0 && (_ctx.activateOnCliente || _ctx.clientiCollegati.length == 0))
          ? (_openBlock(), _createBlock(_component_select_and_buy_offers, {
              key: 1,
              offers: _ctx.offers,
              onCardpaid: _ctx.onCardPaid,
              onBonificopaid: _ctx.onBonificoPaid,
              activateOnCliente: _ctx.activateOnCliente
            }, null, 8, ["offers", "onCardpaid", "onBonificopaid", "activateOnCliente"]))
          : _createCommentVNode("", true),
        (_ctx.cardPaid || _ctx.bonificoPaid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.cardPaid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Il pagamento è andato a buon fine! Ora puoi prenotare presso il centro antifumo più vicino a te!",
                      text: "Il pagamento è andato a buon fine! Ora puoi prenotare presso il centro antifumo più vicino a te!"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.bonificoPaid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Grazie per l'acquisto! Ti abbiamo inviato una mail con le coordinate per effettuare il bonifico.",
                      text: "Grazie per l'acquisto! Ti abbiamo inviato una mail con le coordinate per effettuare il bonifico."
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Torna allo shop!",
                    text: "Torna allo shop!"
                  })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}