
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { DashboardClient, ProvvigioniClient, RicevuteUtenteClient } from '@/services/Services';
import OffersModal from '@/components/offersModal.vue';

@Options({})
export default class Provvigioni extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    tableResult: OM.PagedResultOfProvvigioniListVm = new OM.PagedResultOfProvvigioniListVm();
    filter: OM.ProvvigioniFilterVm = new OM.ProvvigioniFilterVm();

    created(){
        this.filter.userIdentifier = this.user.identifier;
        this.filter.perPage = 10;
        this.filter.pageNumber = 1;
        this.init();
    }

    init(){
        ProvvigioniClient.getByFilterVm(this.filter)
        .then(x => {
            this.tableResult = x;
        })
    }
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    openOffersModal(offers){
        this.$opModal.show(OffersModal, {
            offers
        })
    }

}

