import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/acquisti/acquistoCliente',
        component: () => import ('./acquistiCliente.vue'),
        meta: { title: "Acquisti dei clienti"},
    },
    {
        path: '/acquisti/acquistoCliente/:identifier',
        component: () => import('./acquistoClienteDetail.vue'),
        meta: { title: "Dettaglio acquisto"},
    },
    {
        path: '/acquisti/newCart/:identifier?',
        component: () => import('./newCart.vue'),
        meta: { title: "Nuovo acquisto"},
    },
];

export default routes;