import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_form_input, {
      type: "text",
      label: "Intestatario",
      modelValue: _ctx.value.intestatario,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.intestatario) = $event)),
      placeholder: "Intestatario"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_input, {
        class: "col-sm-3",
        type: "text",
        label: "Nome banca",
        modelValue: _ctx.value.bankName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.bankName) = $event)),
        placeholder: "Nome banca"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_input, {
        class: "col-sm-9",
        type: "text",
        label: "Swift",
        modelValue: _ctx.value.swift,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.swift) = $event)),
        placeholder: "Swift"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_form_input, {
      type: "text",
      label: "Iban",
      modelValue: _ctx.value.iban,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.iban) = $event)),
      placeholder: "Iban"
    }, null, 8, ["modelValue"])
  ]))
}