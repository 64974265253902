import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "breadcrumb" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_faq_node = _resolveComponent("faq-node")!

  return (_ctx.node)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Configurazione FAQ",
            text: "Configurazione FAQ"
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item, i) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "pointer",
              key: i,
              onClick: ($event: any) => (_ctx.goUp(item, i))
            }, _toDisplayString(item.text) + " \\ ", 9, _hoisted_3))
          }), 128))
        ]),
        _createVNode(_component_faq_node, {
          node: _ctx.node,
          onNavigate: _ctx.navigate
        }, null, 8, ["node", "onNavigate"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-success btn-sm",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Salva",
              text: "Salva"
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}