
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import OrariPrenotazioneModal from '@/views/utenze/orariPrenotazioneModal.vue';
import inclusioneOrariModal from '@/views/centriFumo/inclusioneOrariModal.vue';
import esclusioneOrariModal from '@/views/centriFumo/esclusioneOrariModal.vue';
import { BaseUserClient, CollaboratoreClient, ConfigurazionePrenotazioniClient, LeadClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';

@Options({})
export default class impostaOrariModal extends Vue {

    @Prop() userIdentifier: string;

    config: OM.ConfigurazionePrenotazioni = null;
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];
    
    created(){
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
            this.init();
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        this.config = null;
        if(this.selectedProfessione){
            ConfigurazionePrenotazioniClient.getByUser(this.userIdentifier, this.selectedProfessione.identifier)
            .then(x => {
                this.config = x;
            })
        }
    }

    openModalOrari(){
        this.$opModal.show(OrariPrenotazioneModal, {
            userIdentifier: this.userIdentifier
        }, () => {
            this.init();
        })
    }

    openInclusioneOrari(){
        this.$opModal.show(inclusioneOrariModal, {
            userIdentifier: this.userIdentifier
        }, () => {
            this.init();
        })
    }

    openEsclusioneOrari(){
        this.$opModal.show(esclusioneOrariModal, {
            userIdentifier: this.userIdentifier
        }, () => {
            this.init();
        })
    }

    copyShippingAddress(){
        BaseUserClient.getShippingAddress(this.userIdentifier)
        .then(x => {
            this.config.address = x;
        })
    }

    save(){
        ConfigurazionePrenotazioniClient.save(this.config)
        .then(x => {
            window.toastr.success(LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
        })
    }

}

