
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { QualificaProvvigioniConfigClient } from '@/services/Services';

@Options({})
export default class qualificaProvvigioniSelector extends mixins(WithModel) {

    selectedProvvigioni: OM.QualificaProvvigioniConfig = null;
    provvigioniConfig: OM.QualificaProvvigioniConfig[] = [];

    declare localValue: OM.Collaboratore;

    created(){
        QualificaProvvigioniConfigClient.getAll()
        .then(x => {
            this.provvigioniConfig = x;
            if(!this.localValue){
                this.localValue.pianoProvvigioni = new OM.PianoProvvigioni();
            }
            if(this.localValue){
                this.selectedProvvigioni = this.provvigioniConfig.find(x => x.codiceQualifica == this.localValue.pianoProvvigioni.qualifica.codiceQualifica);
            }
        })
    }

    onSelectedProvvigioniChange(){
        this.localValue.pianoProvvigioni.qualifica.codiceQualifica = this.selectedProvvigioni.codiceQualifica;
        this.localValue.pianoProvvigioni.qualifica.puntiTotaliNecessari = this.selectedProvvigioni.puntiTotaliNecessari;
        this.localValue.pianoProvvigioni.qualifica.euroPerPunto = this.selectedProvvigioni.euroPerPunto;
        // this.emitModel(this.localValue);
    }


}
