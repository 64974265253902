import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "mt-5 mw-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Report fine mese per: ",
          text: "Report fine mese per: "
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.username), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Mese",
            text: "Mese"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mese) = $event))
        }, null, 512), [
          [_vModelText, _ctx.mese]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Anno",
            text: "Anno"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.anno) = $event))
        }, null, 512), [
          [_vModelText, _ctx.anno]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-block btn-primary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Genera e scarica",
            text: "Genera e scarica"
          })
        ])
      ])
    ])
  ]))
}