
import { StripeClient } from "@/services/Services";
import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";

@Options({})
export default class StripeCreateNewCard extends Vue {

    @Prop() userIdentifier: string;
    @Prop() userName: string;
    @Prop({
        required: true
    }) stripeKey: string;

	// stripe = Stripe(process.env.VUE_APP_STRIPE);
	stripe = null;
	elements = undefined;
	card = undefined;
	cardholderName: string = "";
	cardNumber = undefined;
	cardExpiry = undefined;
	cardCvc = undefined;

	addButtonDisabled: boolean = false;

	created() {
        this.stripe = Stripe(this.stripeKey);
		this.cardholderName = this.userName;

        this.elements = this.stripe.elements({ 
            hidePostalCode: true, 
            locale: "IT-it"
        });
	}

	mounted() {
        var elementStyles = {
            base: {
                color: '#000',
                fontWeight: 400,
                fontFamily: '"OpenSans", sans-serif',
                fontSize: '14px',

                '::placeholder': {
                    color: '#9BACC8',
                },

                ':focus::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
                ':focus': {
                    color: '#FA755A',
                },
                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };

         var elementClasses = {
            focus: 'focus',
            empty: 'empty',
            invalid: 'invalid',
        };
		
        // creo il form stripe di inserimento della carta
        var cardNumber = this.elements.create('cardNumber', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardNumber.mount('#card-number');
        this.cardNumber = cardNumber;
        
        var cardExpiry = this.elements.create('cardExpiry', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardExpiry.mount('#card-expiry');
        this.cardExpiry = cardExpiry;

        var cardCvc = this.elements.create('cardCvc', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardCvc.mount('#card-cvc');
        this.cardCvc = cardCvc;

        this.card = this.elements._elements[0];
	}
	
	addCard() {
        this.addButtonDisabled = true;
        var paymentMethod = null;
        
		if(this.card._complete){
			paymentMethod = {
				card: this.card,
				billing_details: { name: this.cardholderName }
			}
		}
		
		var self = this;
		this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
                name: this.cardholderName,
            },
        })
        .then(function(result) {
            if (result.error) {
                self.addButtonDisabled = false;
                alert(result.error);
            }
            self.addPaymentMethodToCustomer(result.paymentMethod.id);
            self.addButtonDisabled = false;
        });
	}

	addPaymentMethodToCustomer(paymentMethodId: string) {
        StripeClient.addCardToCustomer(paymentMethodId, this.stripeKey)
        .then( newCard => {
            this.$emit('addedNewCard', newCard);
            this.card.clear();
			this.cardNumber.clear();
			this.cardCvc.clear();
            this.cardExpiry.clear();
            
            alert('La carta è stata aggiunta correttamente!');
		}).catch( error => {
            alert('Si è verificato un errore nell\'aggiunta della carta')
		})
	}
}
