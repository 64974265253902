
    import * as OM from '@/Model';
    import { Options, Vue } from 'vue-class-component';
    import { LeadClient, OffertaClient, PayPalClient, ShopCategoryClient, StripeClient } from '@/services/Services';
    import { Component, Prop, Watch } from "vue-property-decorator";
    import { ModalServices } from '@/services/ModalServices';
    import { LocalizationServices } from '@/services/LocalizationServices';
    import OpAddress from './user/components/opAddress.vue';
    import { JsonClone } from '@/utils';
    
    @Options({})
    export default class selectAndBuyOffers extends Vue {
    
        @Prop() offers: OM.Offerta[];
    
        selectedOffer: OM.Offerta = null;
        shopCategory: OM.ShopCategoryDetailsVm = null;
        metodoPagamento: string = "";
        indirizzoOk: boolean = false;
        codiceFiscaleOk: boolean = false;
    
        tempMail: string = "";
        modificaMail: boolean = false;
    
        showMegaSpinner: boolean = false;
        paymentDone: boolean = false;
    
        created(){
            this.tempMail = this.$store.state.user.contactData.email;
            if(this.offers.length == 1){
                this.setOffer(this.offers[0]);
            }
            this.indirizzoOk = this.canIndirizzoOk;
            this.codiceFiscaleOk = this.canCodiceFiscaleOk;
        }
    
        get canCodiceFiscaleOk(){
            return this.$store.state.user.personalData.nazione == "it" && 
                !!this.$store.state.user.fiscalData.codiceFiscale
        }
    
        codiceFiscaleNext(){
            this.codiceFiscaleOk = true;
            LeadClient.updateCodiceFiscale(this.$store.state.user.identifier, this.$store.state.user.fiscalData.codiceFiscale);
        }
    
        get canIndirizzoOk(){
            return !!this.$store.state.user.fiscalData.fiscalAddress.via &&
                !!this.$store.state.user.fiscalData.fiscalAddress.civico &&
                !!this.$store.state.user.fiscalData.fiscalAddress.citta &&
                !!this.$store.state.user.fiscalData.fiscalAddress.nazione &&
                !!this.$store.state.user.fiscalData.fiscalAddress.cap
        }
    
        indirizzoNext(){
            this.indirizzoOk = true;
            this.$store.state.user.contactData.shippingAddress = JsonClone(this.$store.state.user.fiscalData.fiscalAddress);
            let vm: OM.UpdateAddressVm = {
                identifier: this.$store.state.user.identifier,
                address: this.$store.state.user.fiscalData.fiscalAddress
            }
            LeadClient.updateFiscalAddress(vm);
            LeadClient.updateContactAddress(vm);
        }
    
        saveMail(){
            LeadClient.saveContactEmail(this.$store.state.user.identifier, this.tempMail)
            .then(x => {
                this.$store.state.user.contactData.email = this.tempMail;
                this.modificaMail = false;
            });
        }
    
        setOffer(offer: OM.Offerta){
            if(this.offers.length == 1 && this.selectedOffer)
                return;
            if(this.paymentDone)
                return;
    
            this.shopCategory = null;
            this.selectedOffer = offer;
            ShopCategoryClient.getDetails(this.selectedOffer.shopCategoryIdentifier)
            .then(x => {
                this.shopCategory = x;
            })
        }
    
        get canStripe(){
            return this.shopCategory.metodiPagamento.some(x => x == OM.MetodoPagamento.Stripe)
        }
        
        get canBonifico(){
            return this.shopCategory.metodiPagamento.some(x => x == OM.MetodoPagamento.Bonifico)
        }
    
        intentCbStripe(){
            return StripeClient.createIntentFromOffer(this.selectedOffer.identifier, this.shopCategory.stripePublicKey)
        }
        
        intentCbPayPal(){
            return PayPalClient.createIntentFromOffer(this.selectedOffer.identifier, this.shopCategory.payPalClientId)
        }
        currencyCbPayPal(){
            return PayPalClient.getOfferCurrency(this.selectedOffer.identifier)
        }
    
        onPayCompleteCard(paymentId){
            OffertaClient.completeBuyOfferta({
                stripeToken: paymentId,
                stripePublicKey: this.shopCategory.stripePublicKey,
                identifier: this.selectedOffer.identifier,
                appleIapId: null,
                upsellOfferIdentifier: null,
                activateOnCliente: null
            })
            .then(x => {
                this.$emit('cardpaid');
                this.paymentDone = true;
            })
        }
    
        payBonifico(){
            this.showMegaSpinner = true;
            OffertaClient.buyOffertaBonifico({
                stripeToken: '',
                stripePublicKey: '',
                identifier: this.selectedOffer.identifier,
                appleIapId: null,
                upsellOfferIdentifier: null,
                activateOnCliente: null
            })
            .then(x => {
                this.$emit('bonificopaid');
                this.paymentDone = true;
            })
            .catch(err => {
                ModalServices.alertModal(
                    LocalizationServices.GetLocalizedValue(err, err),
                    LocalizationServices.GetLocalizedValue("Chiudi", "Chiudi")
                );
            })
            .finally(() => this.showMegaSpinner = false)
        }
    };
    