

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { ChatRoomServices } from '@/services/ChatRoomServices';
import toastr from 'toastr';
import { ChatRoomClient } from '@/services/Services';
import store from '@/store';
import Chat from '@/components/chat/chat.vue'

@Options({})
export default class ChatRoomView extends Vue {

    chatRooms: OM.ChatRoomVm[] = [];
    selectedChatRoom: OM.ChatRoomVm = null;
    editDetails: boolean = false;
    loadedMessages: number = 0;

    created(){
        this.init();
    }

    init(){
        this.selectedChatRoom = null;
        ChatRoomClient.getAll()
        .then( x => {
            this.chatRooms = x;
            store.state.layoutSlugs = x.map(x => {
                return {
                    text: x.title,
                    selected: x.identifier == this.$route.params.identifier as string,
                    callback: (item: VM.LayoutSlug) => {
                        this.openChat(x);
                    },
                }
            })
        });
        store.state.layoutSlugButtonAdd = {
            text: "+",
            callback: () => {
                this.createChatRoom();
            }
        }
    }
    createChatRoom(){
        let toSave = new OM.ChatRoom();
        toSave.title = "Nuova stanza";
        toSave.disabled = true;
        ChatRoomClient.save(toSave)
        .then( x => {
            let newChatRoom = new OM.ChatRoomVm();
            newChatRoom.disabled = false,
            newChatRoom.identifier = x,
            newChatRoom.title = toSave.title,
            newChatRoom.subscribersCount = 0,
            this.chatRooms.push(newChatRoom);
            this.init();
        });
    }
    openChat(item: OM.ChatRoomVm){
        ChatRoomClient.getVmById(item.identifier)
        .then(x => {
            this.selectedChatRoom = x;
        })
    }
    save(){
        ChatRoomClient.updateChatRoom(this.selectedChatRoom)
        .then(x => {
            store.state.layoutSlugs.forEach(x => {
                if(x.selected){
                    x.text = this.selectedChatRoom.title
                }
            })
            this.editDetails = false;
        })
    }
    disableChatRoom(){
        ChatRoomClient.disableChat(this.selectedChatRoom.identifier, this.selectedChatRoom.disabled)
        .then(x => {
            ChatRoomServices.SendDisableMessage(this.selectedChatRoom.identifier, this.selectedChatRoom.disabled)
        })
    }
    removeChatRoom(){
        if(confirm("Sei sicuro?")){
            ChatRoomClient.deleteChatRoom(this.selectedChatRoom.identifier)
            .then(x => {
                this.chatRooms.forEach((x, i) => {
                    if(x.identifier == this.selectedChatRoom.identifier){
                        this.chatRooms.splice(i, 1);
                    }
                });
                toastr.success("Eliminata");
               this.init();
            })
        }
    }
    onMessage(){
        this.selectedChatRoom.messagesCount++;
    }
    onMessageRemove(){
        this.selectedChatRoom.messagesCount--;
    }
    onLoadedMessages(loadedMessages){
        this.loadedMessages = loadedMessages;
    }
}

