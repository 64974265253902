
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import { LeadClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';
import ProfileModal from './profileModal.vue';
import EventBus from '@/services/EventBus';

@Options({})
export default class Dashboard extends Vue {

    provvigioni: OM.ProvvigioniListVm[] = [];
    loadingProvvigioni: boolean = false;
    loadingUsers: boolean = false;
    subUsers: OM.UserCardVm[] = [];
    user: OM.Lead = null;

    reload: number = 0;
    
    userIdentifier: string = "";


    created(){
        this.userIdentifier = this.$route.params.userIdentifier as string || store.state.loginData.userIdentifier as string;
        this.init();
        EventBus.$on('reloaddash', () => {
            this.init();
        })
    }
    init(){
        this.loadUser();
        this.reload++;
    }

    imageToUpload: File = null;
    @Watch('imageToUpload')
    onImageToUploadChange(next, prev){
        UploadServices.UploadUserImage({
            itemIdentifier: this.userIdentifier,
            file: next
        })
        .then(x => {
            this.user.personalData.profilePicture = x;
        })
    }

    loadUser(){
        LeadClient.getById(this.userIdentifier)
        .then(x => this.user = x)
    }

    profileModal(){
        this.$opModal.show(ProfileModal, {
            userIdentifier: this.user.identifier,
            role: this.user.role
        }, () => {
            this.loadUser();
        })
    }

    

}

