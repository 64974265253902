import Shop from './shop.vue';
import ShopCategory from './shopCategory.vue';
import OfferDetail from './offerDetail.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/shop',
        component: Shop,
        meta: { title: "Negozio"},
    },
    {
        path: '/shop/:categoryIdentifier',
        component: ShopCategory,
        meta: { title: "Categoria"},
    }
];

export default routes;