import messagesOfTheDay from'@/views/messageOfTheDay/messagesOfTheDay.vue';
import { RouteRecordRaw } from 'vue-router';

let messageOfTheDayRoutes: RouteRecordRaw[] = [
    {
        path: '/messagesOfTheDay',
        component: messagesOfTheDay,
    }
];

export default messageOfTheDayRoutes;