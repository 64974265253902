import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { ref: "paymentContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_mini_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("form", _hoisted_2, null, 512)
  ]))
}