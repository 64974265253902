import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/clienti/list',
        component: () => import('./Clienti.vue'),
        name: 'clientiList',
        meta: { title: "Clienti"},
    },
    {
        path: '/clienti/detail/:identifier',
        component: () => import ('./ClienteDetail.vue'),
        name: 'clienteDetail',
        meta: { title: "Dettaglio cliente"},
    },
];

export default routes;