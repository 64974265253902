import { baseUrl } from "@/config";
import { CommonServices } from "./common";
import * as OM from '@/Model'

class _ExportServices {

    DownloadExport(fileId: string, name: string) {
        window.open(baseUrl + `Export/DownloadExport?fileId=${fileId}&name=${name}`, '_blank');
    }
    
    DownloadPdf(fileId: string, name: string) {
        window.open(baseUrl + `Export/DownloadPdf?fileId=${fileId}&name=${name}`, '_blank');
    }

    generaRicevutaCliente(acquistoIdentifier: string){
        window.open(baseUrl + `RicevuteCliente/generaRicevutaCliente?acquistoIdentifier=${acquistoIdentifier}`, '_blank');
    }

    generaRicevutaUtente(acquistoIdentifier: string){
        window.open(baseUrl + `RicevuteCliente/generaRicevutaCliente?acquistoIdentifier=${acquistoIdentifier}`, '_blank');
    }

}

export let ExportServices = new _ExportServices();
