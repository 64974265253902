
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import FileDropper from '@/components/fileDropper.vue'
import { UploadServices } from '@/services/UploadServices';

@Options({})
export default class CourseContentModal extends Vue {

    @Prop() content: OM.CourseContent;

    backup: OM.CourseContent = null;

    loadingVideo: boolean = false;
    fileToUpload: any = null;
    preview: string = null;


    @Watch('fileToUpload')
    onFileToUploadChange(next, prev){
        if(next){
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.preview = e.target.result;
            }
            reader.readAsDataURL(next);
        }
    }

    uploadVideo(){
        this.loadingVideo = true;
        UploadServices.UploadFile({
            itemIdentifier: "",
            file: this.fileToUpload
        })
        .then(x => {
            this.content.url = x.publicUrl;
            toastr.success("File caricato correttamente!!");
        })
        .catch(err => toastr.error("Errore nel caricamento"))
        .finally(() => this.loadingVideo = false);
    }

    created(){
        this.backup = {...this.content};
        this.preview = this.content.url;
    }

    annulla(){
        this.content.title = this.backup.title;
        this.content.subTitle = this.backup.subTitle;
        this.content.url = this.backup.url;
        this.$emit('close');
    }

}

