
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { CollaboratoreClient, InclusioneOrariPrenotazioneClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class inclusioneOrariModal extends Vue {

    @Prop() userIdentifier: string;

    inclusioni: OM.InclusioneOrariPrenotazione[] = [];
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];

    created(){
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        InclusioneOrariPrenotazioneClient.getByConsulente(this.userIdentifier, this.selectedProfessione.identifier)
        .then(x => {
            this.inclusioni = x;
        })
    }

    addInclusione(){
        let toPush = new OM.InclusioneOrariPrenotazione();
        toPush.userIdentifier = this.userIdentifier;
        toPush.professione = this.selectedProfessione;
        this.inclusioni.unshift(toPush);
    }

    isDataDuplicata(item: OM.InclusioneOrariPrenotazione){
        return this.inclusioni.some(x => x != item && x.date == item.date);
    }

    removeInclusione(item: OM.InclusioneOrariPrenotazione){
        if(item.identifier){
            InclusioneOrariPrenotazioneClient.deleteById(item.identifier)
            .then(x => {
                let index = this.inclusioni.findIndex(x => x == item);
                this.inclusioni.splice(index, 1);
            });
        } else {
            let index = this.inclusioni.findIndex(x => x == item);
            this.inclusioni.splice(index, 1);
        }
    }

    ok(){
        InclusioneOrariPrenotazioneClient.save(this.inclusioni)
        .then(x => {
            window.toastr.success("OK");
            this.$emit('close');
        })
    }

    addFascia(item: OM.InclusioneOrariPrenotazione){
        item.orario.push({
            from: "",
            to: ""
        })
    }

}
