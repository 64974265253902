
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class answerValue extends Vue {

    @Prop() config: OM.DomandaConfig;
    @Prop() answerValue: OM.AnswerValue;

    created(){
        this.answerValue.domanda = {
            name: this.config.label,
            identifier: this.config.identifier
        };
    }

    @Watch('config')
    @Watch('answerValue')
    onConfigChange(next, prev){
        this.answerValue.domanda = {
            name: this.config.label,
            identifier: this.config.identifier
        };
    }

}
