
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import assenzaCliente from './assenzaCliente.vue';
import generic from './generic.vue';
import messaggioInPrenotazione from './messaggioInPrenotazione.vue';
import prenotazioneRicevuta from './prenotazioneRicevuta.vue';
import ticketRicevuto from './ticketRicevuto.vue';
import notaSuCliente from './notaSuCliente.vue';
import commentoSuCliente from './commentoSuCliente.vue';

@Options({
    components: {
        assenzaCliente,
        generic,
        messaggioInPrenotazione,
        prenotazioneRicevuta,
        ticketRicevuto,
        notaSuCliente,
        commentoSuCliente
    }
})
export default class backendNotificationsDrawer extends Vue {

    @Prop() user: OM.Lead;

    pagedVm = { pageNumber: 1, perPage: 8 };
    tableResult: OM.PagedResultOfBackendNotification = new OM.PagedResultOfBackendNotification();
    showUnreadOnly: boolean = false;
    newNotificationCount: number = 0;
    
    created(){
        this.init();
    }

    init(){
        // BackendNotificationClient.countUnread(this.user.identifier)
        // .then(x => {
        //     this.newNotificationCount = x;
        // })
        BackendNotificationClient.getByUser(this.user.identifier, this.showUnreadOnly, this.pagedVm.pageNumber, this.pagedVm.perPage)
        .then(x => {
            this.tableResult = x;
        })
    }

    setRead(item: OM.BackendNotification){
        BackendNotificationClient.setRead(item.identifier)
        .then(x => {
            item.readOn = new Date().toISOString();
        })
    }

    setAllRead(){
        BackendNotificationClient.setAllRead(this.user.identifier)
        .then(x => {
            this.init();
        })
    }
    
}

