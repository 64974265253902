import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as VM from '@/viewModel'
import * as OM from '@/Model'

class _PagamentoBonificoServices {

    AddCommento(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "PagamentoBonifico/AddCommento", vm)
    }

}

export let PagamentoBonificoServices = new _PagamentoBonificoServices();
