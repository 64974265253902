
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { LeadClient } from '@/services/Services';

@Options({})
export default class cronologiaStati extends Vue {

    @Prop() user: OM.Lead;

    remove(item: OM.Comment){
        LeadClient.removeComment(item.identifier, this.user.identifier)
        .then(x => {
            let index = this.user.cronologiaStati.findIndex(x => x.identifier == item.identifier);
            this.user.cronologiaStati.splice(index, 1);
        })
    }

}
