
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import CartItem from './cartItem.vue';

@Options({})
export default class OffersModal extends Vue {
 
    @Prop() offers: OM.OfferInCartVm[];

}

