import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex mt-3" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group ml-2" }
const _hoisted_4 = { class: "form-group ml-2" }
const _hoisted_5 = { value: null }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "ml-2 d-flex flex-column" }
const _hoisted_8 = { class: "ml-4 d-flex align-items-center" }
const _hoisted_9 = {
  filter: "storicoAssegnazione.date",
  sort: "storicoAssegnazione.date"
}
const _hoisted_10 = {
  filter: "lead.name",
  sort: "lead.name"
}
const _hoisted_11 = {
  filter: "storicoAssegnazione.value.assigner.name",
  sort: "storicoAssegnazione.value.assigner.name"
}
const _hoisted_12 = {
  filter: "storicoAssegnazione.value.assigned.name",
  sort: "storicoAssegnazione.value.assigned.name"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { colspan: "99" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_date = _resolveComponent("op-date")!
  const _component_toggler = _resolveComponent("toggler")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Assegnazione clienti e lead",
          text: "Assegnazione clienti e lead"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Dal",
            text: "Dal"
          })
        ]),
        _createVNode(_component_op_date, {
          class: "form-control",
          initialvalue: null,
          modelValue: _ctx.startDate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Al",
            text: "Al"
          })
        ]),
        _createVNode(_component_op_date, {
          class: "form-control",
          initialvalue: null,
          modelValue: _ctx.endDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Filtra per nodo della rete",
            text: "Filtra per nodo della rete"
          })
        ]),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userIdentifier) = $event)),
          class: "form-control"
        }, [
          _createElementVNode("option", _hoisted_5, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Mostra tutti", "Mostra tutti")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersForDropdownReferrer, (user) => {
            return (_openBlock(), _createElementBlock("option", {
              value: user.identifier,
              key: user.identifier
            }, _toDisplayString(user.name), 9, _hoisted_6))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.userIdentifier]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Vedi sottonodi",
            text: "Vedi sottonodi"
          })
        ]),
        _createVNode(_component_toggler, {
          class: "mt-2",
          modelValue: _ctx.descendants,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.descendants) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.init && _ctx.init(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Applica",
            text: "Applica"
          })
        ])
      ])
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.assegnazioni
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_9, [
              _createVNode(_component_localized_text, {
                localizedKey: "Data assegnazione",
                text: "Data assegnazione"
              })
            ]),
            _createElementVNode("td", _hoisted_10, [
              _createVNode(_component_localized_text, {
                localizedKey: "Lead",
                text: "Lead"
              })
            ]),
            _createElementVNode("td", _hoisted_11, [
              _createVNode(_component_localized_text, {
                localizedKey: "Assegnatore",
                text: "Assegnatore"
              })
            ]),
            _createElementVNode("td", _hoisted_12, [
              _createVNode(_component_localized_text, {
                localizedKey: "Assegnato A",
                text: "Assegnato A"
              })
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                _createElementVNode("td", _hoisted_14, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opData.items, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.storicoAssegnazione.date, 'YYYY-MM-DD HH:mm')), 1),
              _createElementVNode("td", null, _toDisplayString(item.lead.name), 1),
              _createElementVNode("td", null, _toDisplayString(item.storicoAssegnazione.value.assigner.name), 1),
              _createElementVNode("td", null, _toDisplayString(item.storicoAssegnazione.value.assigned.name), 1)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}