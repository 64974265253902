import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_telephone = _resolveComponent("telephone")!
  const _component_op_address = _resolveComponent("op-address")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.isAdmin() || _ctx.$store.state.isManager())
      ? (_openBlock(), _createBlock(_component_form_input, {
          key: 0,
          type: "text",
          label: "Nome contatto (visibile solo ai manager)",
          modelValue: _ctx.value.contactName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.contactName) = $event)),
          placeholder: "Nome contatto",
          readonly: _ctx.readonly
        }, null, 8, ["modelValue", "readonly"]))
      : _createCommentVNode("", true),
    _createVNode(_component_form_input, {
      type: "text",
      label: "Email di contatto",
      modelValue: _ctx.value.email,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.email) = $event)),
      placeholder: "Email",
      readonly: _ctx.readonly
    }, null, 8, ["modelValue", "readonly"]),
    _createVNode(_component_telephone, {
      modelValue: _ctx.value.telephone,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.telephone) = $event)),
      readonly: _ctx.readonly,
      label: "Telefono"
    }, null, 8, ["modelValue", "readonly"]),
    _createVNode(_component_op_address, {
      modelValue: _ctx.value.shippingAddress,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.shippingAddress) = $event)),
      readonly: _ctx.readonly,
      label: "Indirizzo di contatto"
    }, null, 8, ["modelValue", "readonly"])
  ]))
}