
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { OffertaClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class buyAppuntamentoModal extends Vue {

    @Prop() user: OM.Lead;
    @Prop() nextCallback: () => void;

    offers: OM.Offerta[] = [];

    cardPaid: boolean = false;
    bonificoPaid: boolean = false;

    created(){
        OffertaClient.getByCategoryTypeAndClienteLevel(store.state.user.identifier, Enum.ProductType.Appuntamento)
        .then(x => {
            this.offers = x;
        })
    }
    
    callNext(){
        this.$emit('close');
        this.nextCallback();
    }

    onCardPaid(){
        this.cardPaid = true;
    }

    onBonificoPaid(){
        this.bonificoPaid = true;
    }
    
}

