
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ReadModal extends Vue {

    @Prop() item: OM.DocumentoDashboard;
    
}

