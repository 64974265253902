import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bigTitle" }
const _hoisted_2 = { class: "midTitle mt-3" }
const _hoisted_3 = { class: "wrapContainer" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "midTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "Negozio",
        text: "Negozio"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "Seleziona la categoria",
        text: "Seleziona la categoria"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "wrapSpacer",
          key: item.identifier
        }, [
          _createElementVNode("div", {
            class: "offerBox",
            onClick: ($event: any) => (_ctx.$router.push('/shop/' + item.identifier))
          }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1)
          ], 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}