
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { NazioneClient, RicevuteUtenteClient } from '@/services/Services';

@Options({})
export default class NuovaRicevutaModal extends Vue {

    @Prop() userIdentifier: string;

    nazioni: string[] = [];
    ricevuta: OM.RicevutaUtenteInsertVm = new OM.RicevutaUtenteInsertVm();

    created(){
        NazioneClient.getEnabled().then(x => this.nazioni = x.map(x => x.alpha2Code));
    }
    
    insertRicevuta(){

        this.ricevuta.userIdentifier = this.userIdentifier;
        RicevuteUtenteClient.add(this.ricevuta)
        .then(x => { 
            this.$emit('close');
        });
    }
}

