
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import RiepilogoModal from '@/views/gestioneLead/scadenze/riepilogoModal.vue';
import { CollaboratoreClient, ConfigurazionePrenotazioniClient, ConfigurazioneProfessioneClient, EsclusioneOrariPrenotazioneClient, LeadClient, PrenotazioneClient, ProductAppuntamentoClient } from '@/services/Services';
import moment from 'moment';
import appuntamentiOfDateModal from '../appuntamentiOfDateModal.vue';
import nuovoAppuntamentoModal from './nuovoAppuntamentoModal.vue';

@Options({})
export default class calendarAppuntamenti extends Vue {

    @Prop() userIdentifier: string;
    @Prop() user: OM.Lead;
    @Prop() reload: number;
    @Prop({
        default: true
    }) scrollToHour: boolean;

    loading: boolean = false;
    avails: OM.OrariPerPrenotareVm[] = [];
    prenotazioni: OM.PrenotazioneForListVm[] = [];
    esclusioni: OM.EsclusioneOrariPrenotazione[] = [];
    start: Date;
    end: Date;
    lastData: any = null;
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];

    created(){
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }

    @Watch('selectedProfessione')
    onSelectedProfessioneChange(next, prev){
        if(prev)
            this.getCalendarData(this.lastData);
    }

    @Watch('reload')
    onReload(next, prev){
        this.getCalendarData(this.lastData);
    }

    getCalendarData(data){
        if(!this.selectedProfessione)
            return;

        this.lastData = data;
        this.loading = true;
        this.start = data.start;
        this.end = data.end;
        Promise.all([
            ConfigurazionePrenotazioniClient.getAvailabilitiesOfUser(this.userIdentifier, this.start.toISOString(), this.end.toISOString(), this.selectedProfessione.identifier, null),
            PrenotazioneClient.getByCentroFumoInterval(this.userIdentifier, this.start.toISOString(), this.end.toISOString()),
            EsclusioneOrariPrenotazioneClient.getByPeriod(this.userIdentifier, this.selectedProfessione.identifier, this.start.toISOString(), this.end.toISOString())
        ])
        .then(xs => {
            this.avails = xs[0];
            this.prenotazioni = xs[1];
            this.esclusioni = xs[2];
        }).finally(() => this.loading = false);
    }
    
    isPast(date, hour){
        if(hour){
            let minHour = parseInt(hour.split(':')[0]);
            return new Date(date).setHours(minHour) <= Date.now();
        } else {
            return new Date(date).getTime() <= Date.now();
        }
    }
    
    getItemsOfHour(date: Date, hour: string){
        let minHour = parseInt(hour.split(':')[0]);
        let min = new Date(date).setHours(minHour,0,0,0);
        let max = new Date(date).setHours(minHour,59,59,0);
        let filteredItems = this.prenotazioni.filter(x => new Date(x.data).getTime() >= min && new Date(x.data).getTime() <= max);
        let ris = filteredItems.reduce((acc: VM.NameCount[], y) => {
            let ind = acc.findIndex(c => c.name == y.cliente.name);
            if(ind == -1){
                acc.push({ name: y.cliente.name, count: 1});
            } else {
                acc[ind].count++;
            }
            return acc;
        }, [])
        return ris;
    }
    
    openRiepilogo(date: string, status: string, hour: string){
        let min: Date, max: Date;
        if(hour){
            let minHour = parseInt(hour.split(':')[0]);
            min = new Date(new Date(date).setHours(minHour,0,0,0));
            max = new Date(new Date(date).setHours(minHour,59,59,0));
        } else {
            min = new Date(new Date(date).setHours(0,0,0,0));
            max = new Date(new Date(date).setHours(23,59,59,0));
        }
        this.$opModal.show(RiepilogoModal, {
            date: date,
            startDate: min,
            endDate: max,
            userIdentifier: this.userIdentifier,
            descendants: false
        }, () => {
            
        });
    }

    getDayClasses(day){
        let ris = day.classes.slice();
        let orarioDate = this.avails.find(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        let prenotazioni = this.prenotazioni.filter(x => 
            moment(x.data).get('date') == moment(day.date).get('date') &&
            moment(x.data).get('month') == moment(day.date).get('month')
        );
        let esclusioni = this.esclusioni.filter(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        let prenotazioniFull = prenotazioni.length > 0 && (!orarioDate || orarioDate.fasceOrarie.length == 0);
        if(prenotazioniFull){
            ris.push('full');
        } else if(prenotazioni.length > 0){
            ris.push('prenotato');
        } else if(orarioDate){
            if(orarioDate.fasceOrarie.length > 0){
                ris.push('available');
            } else {
                if(esclusioni.length > 0){
                    ris.push('canceled');
                }
            }
        } 
        let dayOfWeek = moment(day.date).day();
        if(dayOfWeek == 0 || dayOfWeek == 6){
            ris.push('weekend');
        }
        return ris;
    }

    getAvailsOfDate(day){
        let avails = this.avails.find(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        return avails ? avails.fasceOrarie.length : 0;
    }
    
    getPrenotazioniOfDate(day){
        let prenotazioni = this.prenotazioni.filter(x => 
            moment(x.data).get('date') == moment(day.date).get('date') &&
            moment(x.data).get('month') == moment(day.date).get('month')
        );
        return prenotazioni.length;
    }

    getEsclusioniOfDate(day){
        let esclusioni = this.esclusioni.filter(x =>
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        return esclusioni.length;
    }

    checkDate(date: Date){
        let orarioDate = this.avails.find(x => 
            moment(x.date).get('date') == moment(date).get('date') &&
            moment(x.date).get('month') == moment(date).get('month')
        );
        let prenotazioni = this.prenotazioni.filter(x => 
            moment(x.data).get('date') == moment(date).get('date') &&
            moment(x.data).get('month') == moment(date).get('month')
        );

        // if((!orarioDate || orarioDate.fasceOrarie.length == 0) && prenotazioni.length == 0){
        //     return;
        // }

        this.$opModal.show(appuntamentiOfDateModal, {
            userIdentifier: this.userIdentifier,
            vm: orarioDate,
            prenotazioni: prenotazioni,
            professione: this.selectedProfessione,
            date: date
        })
        // this.selectedDate = new Date(date).toISOString();
        // this.prenotazioniOfDate = prenotazioni;
        // if(orarioDate){
        //     this.fasceOrarie = orarioDate.fasceOrarie;
        // }
    }


    openAppuntamentoModal(){
        this.$opModal.show(nuovoAppuntamentoModal, {
            user: this.user,
        }, () => {
            this.getCalendarData(this.lastData);
        })
    }

}

