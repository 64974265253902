
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({})
export default class Comment extends Vue {

    @Prop() comment: OM.Comment;
    @Prop() removable: boolean;
    @Prop() alwaysRemovable: boolean;

    get canRemove(){
        return this.alwaysRemovable || this.removable && (this.comment.author.identifier == store.state.loginData.userIdentifier || store.state.isAdmin())
    }

    removeComment(){
        this.$emit('remove', this.comment);
    }

}

