import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "mb-3 col-sm-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "mb-3 col-sm-6" }
const _hoisted_6 = { class: "mb-3 col-sm-6" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "mb-3 col-sm-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-12 mb-3" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 mb-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-12 mb-3" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-12 mb-3" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md-12 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabella_prenotazioni = _resolveComponent("tabella-prenotazioni")!
  const _component_portafoglio = _resolveComponent("portafoglio")!
  const _component_documenti = _resolveComponent("documenti")!
  const _component_backend_notifications_drawer = _resolveComponent("backend-notifications-drawer")!
  const _component_performance = _resolveComponent("performance")!
  const _component_obiettivo = _resolveComponent("obiettivo")!
  const _component_il_mio_team = _resolveComponent("il-mio-team")!
  const _component_classifica = _resolveComponent("classifica")!
  const _component_estratto_conto = _resolveComponent("estratto-conto")!
  const _component_provvigioni = _resolveComponent("provvigioni")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tabella_prenotazioni, {
          user: _ctx.user,
          reload: _ctx.localReload,
          useOpTable: false
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_portafoglio, {
          class: "mb-3",
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"]),
        _createVNode(_component_documenti, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_backend_notifications_drawer, {
          class: "mb-3",
          user: _ctx.user
        }, null, 8, ["user"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_performance, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_obiettivo, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_il_mio_team, {
          user: _ctx.user,
          rootUserName: _ctx.user && _ctx.user.personalData.completeName,
          reload: _ctx.localReload
        }, null, 8, ["user", "rootUserName", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_classifica, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_estratto_conto, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_provvigioni, {
          user: _ctx.user,
          reload: _ctx.localReload,
          firstLineOnly: true
        }, null, 8, ["user", "reload"])
      ])
    ])
  ]))
}