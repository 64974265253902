import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/videoDellaSettimana',
            name: 'videoDellaSettimana',
            component: () => import("./views/videoDellaSettimana.vue")
        },
        {
            path: '/videoDellaSettimana/:identifier',
            name: 'videoDellaSettimanaDetail',
            component: () => import("./views/videoDellaSettimanaDetail.vue")
        },
    ];
};