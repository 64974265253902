

import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import WithModel from '@/mixins/withModel';

@Options({})
export default class orariAperturaTicket extends mixins(WithModel) {

    days: any = Enum.DayOfWeek.GetAll();
    declare localValue: OM.OrariAperturaTicket;

    created(){
        this.localValue.text = this.localValue.text || "";
    }

    addTime(){
        this.localValue.times.push(new OM.OrariAperturaTicketTimes());
    }

    removeTime(i: number){
        this.localValue.times.splice(i, 1);
    }

}

