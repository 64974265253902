
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js'
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class LineChart extends Vue {
    
    @Prop({
        default: () => []
    }) values: OM.DateValueOfDecimal[];
    @Prop({
        default: () => []
    }) values2: OM.DateValueOfDecimal[];
    @Prop() label: string;
    @Prop() label2: string;
    @Prop({
        default: '#7FD6DC'
    }) color: string;
    @Prop({
        default: '#7FD6DC'
    }) color2: string;
    @Prop() maxy: number;

    chart: any;
    mounted(){
        this.chartIt();
    }

    @Watch('values')
    onDataChange(next, prev){
        this.chart.destroy();
        this.chartIt();
    }
    @Watch('values2')
    onData2Change(next, prev){
        this.chart.destroy();
        this.chartIt();
    }

    chartIt(){
        let ctx: any = (this.$refs.chart as any).getContext('2d');
        let data: any[] = this.values.map(x => {
            return {
                x: new Date(x.date).getTime(),
                y: x.value
            }
        })
        let data2: any[] = this.values2.map(x => {
            return {
                x: new Date(x.date).getTime(),
                y: x.value
            }
        })
        let dataSets = [];
        if(data.length > 0){
            dataSets.push({
                label: this.label,
                data: data,
                fill: false,
                borderColor: this.color,
                backgroundColor: this.color,
                tension: 0
            });
        } 
        if(data2.length > 0){
            dataSets.push({
                label: this.label2,
                data: data2,
                fill: false,
                borderColor: this.color2,
                backgroundColor: this.color2,
                tension: 0
            });
        }
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [],
                datasets: dataSets
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                legend: {
                    display: this.label || this.label2
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 15
                    },
                },
                scales: {
                    xAxes: [{
						type: 'time',
						time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'DD'
                            }
						},
						scaleLabel: {
							display: true,
							labelString: 'Date'
						}
					}],
					yAxes: [{
                        ticks: {
                            suggestedMax: this.maxy || null,
                            beginAtZero: true,
                        },
						scaleLabel: {
							display: true,
						}
					}]
                },
                showAllTooltips: true,
                animation: false,
                tooltips: {
                    displayColors: false,
                    callbacks: {
                        title: function(context){
                            return "";
                        },
                        label: function(context) {
                            return context.value;
                        }
                    }
                }
            }
        });
    }

}

