
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { LeadClient } from '@/services/Services';

@Options({})
export default class ActionModal extends Vue {

    @Prop() title: string;
    @Prop() leadIdentifier: string;
    @Prop({
        default: true
    }) showDate: boolean
    @Prop() commentType: string
    @Prop() status: string
    @Prop() nota: string

    scadenza: string = "";
    xnota: string = "";

    today = new Date();

    created(){
        this.xnota = this.nota;
    }

    get canSave(){
        // if(!this.nota.trim())
        //     return false;
        if(this.showDate){
            if(!this.scadenza)
                return false;
        }
        return true;
    }

    save(){
        if(!this.canSave){
            return;
        }
        if(!this.showDate){
            this.scadenza = new Date().toISOString();
        }

        let vm: OM.SaveLeadActionVm = {
            leadIdentifier: this.leadIdentifier,
            type: this.commentType,
            comment: this.xnota,
            status: this.status,
            scadenza: this.scadenza,
            durata: null
        }

        LeadClient.saveAction(vm)
        .then(x => {
            this.$emit('close');
        })
    }

}

