
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { OffertaClient } from '@/services/Services';

@Options({})
export default class UpsellModal extends Vue {

    @Prop() offer: OM.Offerta;

    local: OM.Offerta = null;
    offertas: OM.Offerta[] = [];

    upsellCategoryNotFound: boolean = false;

    created(){
        this.local = JSON.parse(JSON.stringify(this.offer));
        OffertaClient.getAllUpsellOffers()
        .then(x => this.offertas = x)
        .catch(err => {
            if(err == 'upsell_category_not_found'){
                this.upsellCategoryNotFound = true;
            }
        })
    }
    
    annulla(){
        this.$emit('close');
    }

    ok(){
        this.offer.upsellOffers = this.local.upsellOffers;
        this.$emit('close');
    }

    toggleOffer(item: OM.Offerta){
        let index = this.local.upsellOffers.indexOf(item.identifier);
        if(index > -1){
            this.local.upsellOffers.splice(index, 1);
        } else {
            this.local.upsellOffers.push(item.identifier);
        }
    }

    hasOffer(item: OM.Offerta){
        return this.local.upsellOffers.some(x => x == item.identifier);
    }

}

