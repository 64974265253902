import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01376ee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "control-label"
}
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["disabled", "readonly", "type", "placeholder", "value", "required"]
const _hoisted_5 = ["disabled", "readonly", "type", "placeholder", "required"]
const _hoisted_6 = { class: "inputIcon" }
const _hoisted_7 = {
  key: 0,
  class: "fa fa-font"
}
const _hoisted_8 = {
  key: 1,
  class: "fa fa-hashtag"
}
const _hoisted_9 = {
  key: 2,
  class: "fa fa-phone"
}
const _hoisted_10 = {
  key: 3,
  class: "fa fa-lock"
}
const _hoisted_11 = {
  key: 4,
  class: "fa fa-calendar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asterisk = _resolveComponent("asterisk")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createBlock(_component_asterisk, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.type != 'number')
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            class: "form-control",
            ref: "inputTag",
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            type: _ctx.showPassword ? 'text' : _ctx.type,
            placeholder: _ctx.placeholder,
            value: _ctx.localValue,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            required: _ctx.required
          }, null, 40, _hoisted_4))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            class: "form-control",
            ref: "inputTag",
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            type: _ctx.showPassword ? 'text' : _ctx.type,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            required: _ctx.required
          }, null, 40, _hoisted_5)), [
            [
              _vModelDynamic,
              _ctx.localValue,
              void 0,
              { number: true }
            ]
          ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.type=='text')
          ? (_openBlock(), _createElementBlock("i", _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.type=='number')
          ? (_openBlock(), _createElementBlock("i", _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.type=='tel')
          ? (_openBlock(), _createElementBlock("i", _hoisted_9))
          : _createCommentVNode("", true),
        (_ctx.type=='password')
          ? (_openBlock(), _createElementBlock("i", _hoisted_10))
          : _createCommentVNode("", true),
        (_ctx.type=='date')
          ? (_openBlock(), _createElementBlock("i", _hoisted_11))
          : _createCommentVNode("", true)
      ]),
      (_ctx.type=='password')
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "showPassword",
            type: "button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword=!_ctx.showPassword))
          }, " Mostra "))
        : _createCommentVNode("", true)
    ])
  ]))
}