
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class CommentiModal extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop() attachments: OM.Comment[];
    @Prop() addCallback: (vm: VM.AddCommentEvent) => void;

}

