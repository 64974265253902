
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class NewListModal extends Vue {

    @Prop() saveCb: (item: string) => void;

    name: string = "";

    save(){
        this.saveCb(this.name);
        this.$emit('close');
    }

}

