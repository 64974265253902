
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient, DashboardClient, LeadClient } from '@/services/Services';

@Options({})
export default class Classifica extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;
    
    loading: boolean = false;
    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";

    venditori: OM.VenditoreBestVm[] = [];
    // clienti: OM.ClienteBestVm[] = [];
    // offerte: OM.OffertaBestVm[] = [];

    created(){
        this.fromDate = moment(new Date()).startOf('month').toISOString();
        this.toDate = moment(new Date()).endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    init(){
        this.loading = true;
        // Promise.all([
        //     DashboardClient.getBestCustomers(this.user.identifier, 3, this.fromDate, this.toDate),
        //     DashboardClient.getBestOffers(this.user.identifier, 3, this.fromDate, this.toDate)
        // ])
        DashboardClient.getBestVenditori(this.user.identifier, 3, this.fromDate, this.toDate, false)
        .then(x => {
            // this.clienti = x[0];
            // this.offerte = x[1];
            this.venditori = x;
        }).finally(() => this.loading = false);
    }
    prevMonth(){
        this.fromDate = moment(this.fromDate).subtract(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    nextMonth(){
        this.fromDate = moment(this.fromDate).add(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).add(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }

}

