import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center mb-3" }
const _hoisted_2 = {
  filter: "codiceQualifica",
  sort: "codiceQualifica"
}
const _hoisted_3 = {
  filter: "puntiTotaliNecessari",
  sort: "puntiTotaliNecessari"
}
const _hoisted_4 = {
  filter: "euroPerPunto",
  sort: "euroPerPunto"
}
const _hoisted_5 = {
  filter: "manuale",
  sort: "manuale"
}
const _hoisted_6 = {
  filter: "isQualificaBase",
  sort: "isQualificaBase"
}
const _hoisted_7 = {
  filter: "isQualificaInizioCollaboratori",
  sort: "isQualificaInizioCollaboratori"
}
const _hoisted_8 = {
  filter: "modalitaCalcolo",
  sort: "modalitaCalcolo"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { colspan: "99" }
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_toggler = _resolveComponent("toggler")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Configurazione qualifiche provvigionali",
          text: "Configurazione qualifiche provvigionali"
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.newQualifica && _ctx.newQualifica(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Aggiungi qualifica",
            text: "Aggiungi qualifica"
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary ml-2",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveAll && _ctx.saveAll(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Salva tutte",
            text: "Salva tutte"
          })
        ])
      ])
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.qualifiche
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Codice qualifica",
                text: "Codice qualifica"
              })
            ]),
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_localized_text, {
                localizedKey: "Punti totali necessari",
                text: "Punti totali necessari"
              })
            ]),
            _createElementVNode("td", _hoisted_4, [
              _createVNode(_component_localized_text, {
                localizedKey: "Euro per punto",
                text: "Euro per punto"
              })
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: "Manuale",
                text: "Manuale"
              })
            ]),
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_localized_text, {
                localizedKey: "Qualifica base",
                text: "Qualifica base"
              })
            ]),
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_localized_text, {
                localizedKey: "Qualifica inizio collaboratori",
                text: "Qualifica inizio collaboratori"
              })
            ]),
            _createElementVNode("td", _hoisted_8, [
              _createVNode(_component_localized_text, {
                localizedKey: "Modalita calcolo",
                text: "Modalita calcolo"
              })
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                _createElementVNode("td", _hoisted_10, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opData.items, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.codiceQualifica) = $event)
                }, null, 8, _hoisted_11), [
                  [_vModelText, item.codiceQualifica]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": ($event: any) => ((item.puntiTotaliNecessari) = $event)
                }, null, 8, _hoisted_12), [
                  [
                    _vModelText,
                    item.puntiTotaliNecessari,
                    void 0,
                    { number: true }
                  ]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": ($event: any) => ((item.euroPerPunto) = $event)
                }, null, 8, _hoisted_13), [
                  [
                    _vModelText,
                    item.euroPerPunto,
                    void 0,
                    { number: true }
                  ]
                ])
              ]),
              _createElementVNode("td", null, [
                (_openBlock(), _createBlock(_component_toggler, {
                  modelValue: item.manuale,
                  "onUpdate:modelValue": ($event: any) => ((item.manuale) = $event),
                  key: i
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_toggler, {
                  modelValue: item.isQualificaBase,
                  "onUpdate:modelValue": ($event: any) => ((item.isQualificaBase) = $event),
                  onInput: ($event: any) => (_ctx.uncheckOthers(item))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_toggler, {
                  modelValue: item.isQualificaInizioCollaboratori,
                  "onUpdate:modelValue": ($event: any) => ((item.isQualificaInizioCollaboratori) = $event),
                  onInput: ($event: any) => (_ctx.uncheckOthersCollab(item))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((item.modalitaCalcolo) = $event),
                  class: "form-control"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalitaCalcolos, (item, i) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: i,
                      value: item
                    }, _toDisplayString(item), 9, _hoisted_15))
                  }), 128))
                ], 8, _hoisted_14), [
                  [_vModelSelect, item.modalitaCalcolo]
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger",
                  onClick: ($event: any) => (_ctx.remove(item))
                }, "X", 8, _hoisted_16)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}