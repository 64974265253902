
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import * as Enum from '@/enum';
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { BaseUserClient, QualificaProvvigioniConfigClient } from '@/services/Services';

@Options({})
export default class networkUserSelector extends mixins(WithModel) {

    @Prop() label: string;
    @Prop() userIdentifier: string;

    users: OM.NameIdentifier[] = [];
    search: string = "";
    roleFilter: string = "";
    roles: string[] = Enum.UserRoles.GetAll();
    scrollListContainer: HTMLDivElement = null;

    created(){
        BaseUserClient.getListForNetwork(this.roleFilter, this.userIdentifier)
        .then(x => {
            this.users = x;
            setTimeout(() => {
                if(this.localValue){
                    let activeElement = this.$refs[this.localValue.identifier][0];
                    this.scrollListContainer.scrollTop = activeElement.offsetTop;
                }
                // var rect = activeElement.getBoundingClientRect();
                // var offset = { 
                //     top: rect.top + window.scrollY, 
                //     left: rect.left + window.scrollX, 
                // };
                // console.log(offset);

                // let topPos = offset.top + activeElement.parent().scrollTop() - activeElement.offsetParent().offset().top;
                // console.log(topPos);
                // this.scrollListContainer.scrollTo({top: topPos });
            }, 50);
        })
    }

    mounted(){
        this.scrollListContainer = this.$refs.scrollListContainer as any;
    }

    scrollIntoView(){
        this.$refs[this.localValue.identifier][0].scrollIntoView();
    }

    get filteredUsers(){
        return this.users.filter(x => x.name.toLowerCase().indexOf(this.search) > -1);
    }

    @Watch('roleFilter')
    onSearchChange(next, prev){
        BaseUserClient.getListForNetwork(this.roleFilter, this.userIdentifier)
        .then(x => {
            this.users = x;
        })
    }

}
