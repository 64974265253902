
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { PayPalClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';


@Options({})
export default class paypalForm extends Vue {

    @Prop() intentCb: () => Promise<string>;
    @Prop() currencyCb: () => Promise<string>;
    @Prop({
        required: true
    }) clientId: string;

    paypal: PayPalNamespace = null;
    loaded: boolean = false;
    paymentContainer: any = null;

    mounted(){
        try {
            this.currencyCb()
            .then(currency => {
                loadScript({ 
                    "client-id": this.clientId,
                    intent: 'capture',
                    "disable-funding": 'card',
                    currency: currency
                })
                .then(x => {
                    this.paypal = x;
                    if (this.paypal) {
                        try {
                            this.paymentContainer = this.$refs.paymentContainer;
                            this.paypal.Buttons({
                                // Call your server to set up the transaction
                                createOrder: (data, actions) => {
                                    return this.intentCb()
                                },
                                // Call your server to finalize the transaction
                                onApprove: (data, actions) => {
                                    return PayPalClient.completeOrder(data.orderID, this.clientId)
                                    .then(x => {
                                        console.log(x);
                                        let paymentCaptureId = x.purchase_units[0].payments.captures[0].id;
                                        this.$emit('complete', paymentCaptureId);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        ModalServices.alertModal("Errore", "Si è verificato un errore durante il pagamento");
                                    })
                                    // return fetch('/demo/checkout/api/paypal/order/' + data.orderID + '/capture/', {
                                    //     method: 'post'
                                    // }).then(function(res) {
                                    //     return res.json();
                                    // }).then(function(orderData) {
                                    //     // Three cases to handle:
                                    //     //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    //     //   (2) Other non-recoverable errors -> Show a failure message
                                    //     //   (3) Successful transaction -> Show confirmation or thank you

                                    //     // This example reads a v2/checkout/orders capture response, propagated from the server
                                    //     // You could use a different API or structure for your 'orderData'
                                    //     var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

                                    //     if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                                    //         return actions.restart(); // Recoverable state, per:
                                    //         // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                                    //     }

                                    //     if (errorDetail) {
                                    //         var msg = 'Sorry, your transaction could not be processed.';
                                    //         if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                                    //         if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                                    //         return alert(msg); // Show a failure message (try to avoid alerts in production environments)
                                    //     }

                                    //     // Successful capture! For demo purposes:
                                    //     console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                                    //     var transaction = orderData.purchase_units[0].payments.captures[0];
                                    //     alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');

                                    //     // Replace the above to show a success message within this page, e.g.
                                    //     // const element = document.getElementById('paypal-button-container');
                                    //     // element.innerHTML = '';
                                    //     // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                                    //     // Or go to another URL:  actions.redirect('thank_you.html');
                                    // });
                                }
                            })
                            .render(this.paymentContainer)
                            .then(x => {
                                this.loaded = true;
                            });
                        } catch (error) {
                            console.error("failed to render the PayPal Buttons", error);
                        }
                    }
                });
            })
        } catch (error) {
            console.error("failed to load the PayPal JS SDK script", error);
        }

    }

}

