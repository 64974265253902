import { RouteRecordRaw } from 'vue-router';
import ricettarioNodesConfigurator from './ricettarioNodesConfigurator.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/ricettario',
        component: ricettarioNodesConfigurator,
        meta: { title: "Ricettario"},
    },
];

export default routes;