

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { ClienteClient, OffertaClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class newBonificoModal extends Vue {

    customers: OM.NameIdentifier[] = [];
    offers: OM.NameIdentifier[] = [];
    
    selectedCliente: OM.NameIdentifier = null;
    selectedOffer: OM.NameIdentifier = null;

    created(){
        ClienteClient.getNameIdentifiers()
        .then(x => this.customers = x)
        OffertaClient.getAllAsNameIdentifier(false)
        .then(x => this.offers = x)
    }

    searchCb(input: string){
        return this.customers.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    get canSave(){
        return this.selectedCliente && this.selectedOffer;
    }

    save(){
        OffertaClient.buyOffertaBonificoForCliente(this.selectedOffer.identifier, this.selectedCliente.identifier)
        .then(x => {
            window.toastr.success("Inserito!");
            this.$emit('close');
        })
    }
    

}

