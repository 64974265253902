import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as VM from '@/viewModel'
import * as OM from '@/Model'

class _RichiestaPagamentoUserServices {

    richiediPagamento(vm: VM.RichiediPagamentoUserVm){
        return CommonServices.uploadFileToUrl<string>(baseUrl + "RichiestaPagamentoUser/RichiediPagamento", vm)
    }

}

export let RichiestaPagamentoUserServices = new _RichiestaPagamentoUserServices();
