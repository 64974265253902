import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-3" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "col-sm-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "col-sm-3" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Punti assegnati al gestore",
              text: "Punti assegnati al gestore"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            step: "0.01",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product.puntiAssegnatiAlGestore) = $event))
          }, null, 512), [
            [_vModelText, _ctx.product.puntiAssegnatiAlGestore]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Voucher prenotazione creati",
              text: "Voucher prenotazione creati"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.product.numeroTicketCreati) = $event))
          }, null, 512), [
            [_vModelText, _ctx.product.numeroTicketCreati]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Ruolo prenotazione",
              text: "Ruolo prenotazione"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.product.ruoloPrenotazione) = $event)),
            disabled: ""
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (item, i) => {
              return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item), 1))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.product.ruoloPrenotazione]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Professione",
              text: "Professione"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.product.professione) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.professioni, (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: item
              }, _toDisplayString(item.name), 9, _hoisted_10))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.product.professione]
          ])
        ])
      ])
    ])
  ]))
}