
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class FormInput extends mixins(WithModel) {

    @Prop() type: string;
    @Prop() label: string;
    @Prop() placeholder: string;

    @Prop({
        type: String,
        default: ""
    }) language: string;
    @Prop({
        type: Boolean,
        default: false
    }) required: boolean;
    showPassword: boolean = false;
    @Prop({
        default: false
    }) disabled: boolean;
    @Prop({
        default: false
    }) readonly: boolean;

    mounted(){
        if(this.type == "checkbox"){
            if(this.localValue){
                (this.$refs.inputTag as any).checked = true;
            }
        } else if (this.type == "date"){
            if(this.localValue){
                let data = new Date(this.localValue);
                let anno = data.getFullYear().toString();
                let mese = (data.getMonth() + 1).toString();
                if(mese.length == 1){
                    mese = "0" + mese;
                }
                let giorno = data.getDate().toString();
                if(giorno.length == 1){
                    giorno = "0" + giorno;
                }

                (this.$refs.inputTag as any).value = anno + "-" + mese + "-" + giorno;
                // let splitted = this.value.split('T');
                // if(splitted.length > 1){
                //     (<any>this.$refs.inputTag).value = splitted[0];
                // }
            }
        }
    }
    onChange(ev: any){
        if(this.type == "checkbox"){
            this.emitModel(ev.target.checked)
        } else {
            this.emitModel(ev.target.value)
        }
    }
}
