
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import * as Enum from '@/enum'
import { JsonClone } from '@/utils';
import { ConfigurazionePrenotazioniClient, CollaboratoreClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class OrariPrenotazioneModal extends Vue {

    @Prop() userIdentifier: string;

    config: OM.ConfigurazionePrenotazioni = null;
    daysOfWeek = Enum.DayOfWeek.GetDictionary();
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];

    created(){
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        this.config = null;
        ConfigurazionePrenotazioniClient.getByUser(this.userIdentifier, this.selectedProfessione.identifier)
        .then(x => {
            this.config = x;
        })
    }

    addOrarioPrenotazione(){
        let toPush = new OM.OrarioPrenotazione();
        this.config.calendarioConfig.orariPrenotazione.push(toPush);
    }

    addFasciaOraria(item: OM.OrarioPrenotazione){
        let toPush = new OM.FasciaOraria();
        item.fasceOrarie.push(toPush);
    }

    removeOrarioPrenotazione(item: OM.OrarioPrenotazione){
        let index = this.config.orariPrenotazione.indexOf(item);
        this.config.calendarioConfig.orariPrenotazione.splice(index, 1);
    }

    removeFascia(fascia, fasceOrarie){
        let index = fasceOrarie.indexOf(fascia);
        fasceOrarie.splice(index, 1);
    }

    addGiornoDaEscludere(){
        let toPush = new OM.GiornoDaEscludere();
        this.config.calendarioConfig.giorniDaEscludere.push(toPush);
    }

    addPeriodoDaEscludere(){
        let toPush = new OM.PeriodoDaEscludere();
        this.config.calendarioConfig.periodiDaEscludere.push(toPush);
    }

    ok(){
        ConfigurazionePrenotazioniClient.save(this.config)
        .then(x => {
            window.toastr.success("OK!");
            this.$emit('close');
        })
    }

}
