
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import calendarModal from './calendarModal.vue';
import store from '@/store';
import appuntamentoRecapModal from './appuntamentoRecapModal.vue';

@Options({})
export default class orariModal extends Vue {

    @Prop() fasce: OM.FasciaOraria[];
    @Prop() modificaPrenotazioneIdentifier: string;

    selectedFascia: OM.FasciaOraria = null;

    back(){
        this.$emit('close');
        this.$opModal.show(calendarModal, {
            identifier: store.state.prenotazioneData.centro.identifier
        })
    }

    next(){
        store.state.prenotazioneData.fascia = this.selectedFascia;

        this.$opModal.closeAll();
        this.$opModal.show(appuntamentoRecapModal, {
            modificaPrenotazioneIdentifier: this.modificaPrenotazioneIdentifier
        })
    }
    
}
