

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { ClienteClient, LeadClient, LoginClient } from '@/services/Services';
import store from '@/store';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class newLeadModal extends Vue {

    vm: OM.NewLeadVm = new OM.NewLeadVm();
    isMailAvailable: boolean = true;
    lists: string[] = [];

    created(){
        LeadClient.getLists()
        .then(x => {
            this.lists = x;
        })
    }

    save(){
        if(!this.canSend)
            return;

        this.$emit('close');
        return LeadClient.saveNewLeadVm(this.vm)
    }

    saveModify(){
        this.save().then(x => {
            this.$router.push('/lead/detail/' + x);
        })
    }

    saveChiudi(){
        this.save();
    }

    @Watch('vm.contactData.shippingAddress.nazione')
    onNazioneChange(next, prev){
        console.log(next);
    }
    
    debuncio: any = null;
    @Watch('vm.authData.username')
    onEmailChange(next, prev){
        clearTimeout(this.debuncio);
        this.debuncio = setTimeout(() => {
            LoginClient.checkEmailAvailable(this.vm.authData.username)
            .then(x => {
                this.isMailAvailable = x;
            })
        }, 800);
    }

    get canSend(){
        return this.vm.personalData.nazione && this.vm.authData.username.trim() && this.vm.authData.password;
    }

}

