
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { ClienteClient, OffertaClient, PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class nuovoAppuntamentoModal extends Vue {

    @Prop() user: OM.Lead;

    searchText: string = "";
    clientiList: OM.NameIdentifier[] = [];
    tickets: string[] = [];

    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();

    created(){
        this.vm.user = { identifier: this.user.identifier, name: this.user.personalData.completeName };
    }

    debuncio: any = null;
    @Watch('searchText')
    onSearchTextChange(next, prev){
        clearTimeout(this.debuncio);
        this.debuncio = setTimeout(() => {
            this.vm.cliente = null;
            ClienteClient.searchClienteByDottore(this.searchText, this.vm.user.identifier)
            .then(x => {
                this.clientiList = x;
            })
        }, 600);
    }

    selectCliente(item: OM.NameIdentifier){
        TicketPrenotazioneClient.getAvailableTickets(item.identifier)
        .then(x => {
            this.vm.cliente = item;
            this.tickets = x;
            if(this.tickets.length > 0){
                this.vm.ticketPrenotazioneIdentifier = this.tickets[0];
            }
        })
    }
    
    selectDate(day){
        this.vm.date = day.date;
    }


    get canSave(){
        return this.vm.cliente && this.vm.ticketPrenotazioneIdentifier && this.vm.date && (this.vm.fasciaOraria && this.vm.fasciaOraria.from && this.vm.fasciaOraria.to);
    }

    save(){
        PrenotazioneClient.makePrenotazione(this.vm)
        .then(x => {
            ModalServices.alertModal(
                LocalizationServices.GetLocalizedValue('Appuntamento confermato!', 'Appuntamento confermato!'),
                LocalizationServices.GetLocalizedValue('Appuntamento registrato con successo', 'Appuntamento registrato con successo'),
                null, 
                () => {
                    this.$emit('close');
                }
            )
        })
    }
    
}

