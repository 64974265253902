
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import TrelloContainer from '@/components/trelloContainer.vue';
 import Toggler from '@/components/toggler.vue';
import { CollaboratoreClient, LeadClient } from '@/services/Services';

@Options({})
export default class Pipeline extends Vue {

    colonne: OM.LeadColonneVm = null;

    
    descendants: boolean = false;
    @Watch('descendants')
    onDescendantsChange(next, prev){
        this.init();
    }
    userFilter: any = null;
    @Watch('userFilter')
    onUserFilterChange(next, prev){
        this.init();
    }
    usersForDropdownReferrer: any[] = [];

    created(){
        CollaboratoreClient.getDescendantsManagersNameIdentifier()
        .then(x => {
            this.usersForDropdownReferrer = x;
            this.init();
        })
    }

    init(){
        let userId = this.userFilter || store.state.loginData.userIdentifier;
        LeadClient.getByStatusAsColonne(userId, this.descendants)
        .then(x => {
            this.colonne = x;
        })
    }
    
    getRitardo(item: OM.Lead | OM.LeadTableVm){
        if(item.status)
            return 0;
        if(item.scadenze.length == 0)
            return 0;
        if(new Date(item.scadenze[0].startDate).getTime() > Date.now())
            return 0;

        let today = new Date();
        let lamezza = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        if(new Date(item.scadenze[0].startDate).getTime() <= lamezza.getTime()){
            var diff = lamezza.getTime() - new Date(item.scadenze[0].startDate).getTime();
            var days = diff / 1000 / 60 / 60 / 24;
            return Math.floor(days);
        }
    }

    isOfToday(item: OM.Lead | OM.LeadTableVm){
        if(item.scadenze.length == 0)
            return 0;

        let today = new Date();
        let checkDate = new Date(item.scadenze[0].startDate);
        return checkDate.getDate() == today.getDate() && checkDate.getMonth() == today.getMonth() && checkDate.getFullYear() == today.getFullYear();
    }
    
    orderLeads(leadList: OM.Lead[]){
        return leadList.sort((a, b) => {
            if(new Date(a.scadenze[0].startDate) > new Date(b.scadenze[0].startDate))
                return 1;
            else
                return -1;
        });
    }

    openLeadModal(item: OM.LeadTableVm, leadList: OM.LeadTableVm[]){
        if(item.role == Enum.UserRoles.Cliente){
            this.$router.push('/clienti/detail/' + item.identifier)
        } else if(item.role == Enum.UserRoles.Lead){
            this.$router.push('/lead/detail/' + item.identifier)
        }
        // this.$opModal.show(LeadModal, 
        //     {
        //         leadIdentifier: item.identifier,
        //         leadIdentifierList: leadList.map(x => x.identifier),
        //     }, 
        //     () => {
        //         this.init();
        //     }
        // )
    }

    getLeadStatus(item: OM.Lead | OM.LeadTableVm){
        if(item.status){
            return item.status;
        } else {
            let today = new Date();
            let lamezza = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
            if(item.scadenze.length == 0 || new Date(item.scadenze[0].startDate).getTime() <= lamezza.getTime()){
                return "Da fare";
            } else {
                return "In attesa";
            }
        }
    }


    onDragStart(ev, item: OM.LeadTableVm, draggedFrom: string) {
        if(item.subStatus == Enum.LeadSubStatus.ConclusionePositiva){
            ev.preventDefault();
            return;
        }
        
        ev.dataTransfer.setData("itemId", item.identifier);
        ev.dataTransfer.setData("itemSubStatus", item.subStatus);
        ev.dataTransfer.setData("draggedFrom", draggedFrom);
    }
    onDragOver(ev) {
        ev.preventDefault();
    }
    onDrop(ev, droppedOn: string) {
        ev.preventDefault();

        let itemSubStatus = ev.dataTransfer.getData("itemSubStatus");
        if(itemSubStatus == droppedOn) return;
        if(droppedOn == 'conclusionePositiva') return;
        // if(droppedOn == 'conclusioneNegativa') return;

        let leadIdentifier = ev.dataTransfer.getData("itemId");
        let newSubStatus = "";


        if(droppedOn == 'inArrivo') newSubStatus = Enum.LeadSubStatus.InArrivo;
        if(droppedOn == 'daContattare') newSubStatus = Enum.LeadSubStatus.DaContattare;
        if(droppedOn == 'visita') newSubStatus = Enum.LeadSubStatus.Visita;
        if(droppedOn == 'inAttesaDiDecisione') newSubStatus = Enum.LeadSubStatus.InAttesaDiDecisione;
        if(droppedOn == 'senzaPossibilitaEconomiche') newSubStatus = Enum.LeadSubStatus.SenzaPossibilitaEconomiche;
        if(droppedOn == 'conclusioneNegativa') newSubStatus = Enum.LeadSubStatus.ConclusioneNegativa;
        if(droppedOn == 'rifiutatoDalNutrizionista') newSubStatus = Enum.LeadSubStatus.RifiutatoDalNutrizionista;
        if(droppedOn == 'inAttesaDiPagamento') newSubStatus = Enum.LeadSubStatus.InAttesaDiPagamento;
        if(droppedOn == 'conclusionePositiva') newSubStatus = Enum.LeadSubStatus.ConclusionePositiva;
        if(droppedOn == 'percorsoConcluso') newSubStatus = Enum.LeadSubStatus.PercorsoConcluso;
        if(droppedOn == 'sospesoConConsenso') newSubStatus = Enum.LeadSubStatus.SospesoConConsenso;
        if(droppedOn == 'sospesoSenzaConsenso') newSubStatus = Enum.LeadSubStatus.SospesoSenzaConsenso;
        if(droppedOn == 'mantenimento') newSubStatus = Enum.LeadSubStatus.Mantenimento;
        if(droppedOn == 'postVisita') newSubStatus = Enum.LeadSubStatus.PostVisita;

        LeadClient.changeSubStatus(leadIdentifier, newSubStatus)
        .then(x => {
            this.init();
        })
    }

}

