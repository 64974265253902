

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { ClienteClient, LeadClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class leadDetail extends Vue {
 
    user: OM.UpdateLeadVm = null;
    showAs: string = "DatiBase";

    rolesList: string[] = Enum.UserRoles.GetAll();

    created(){
        if(this.$route.params.identifier as string == '0'){
            this.user = new OM.UpdateLeadVm();
            this.user.role = Enum.UserRoles.Lead;
        } else {
            this.init();
        }
    }

    init(){
        LeadClient.getByIdVm(this.$route.params.identifier as string)
        .then( x => {
            this.user = x;
            this.user.authData.password = "";
        });
    }

    save(){
        LeadClient.save(this.user)
        .then(x => {
            window.toastr.success("OK!");
            if(this.$route.params.identifier as string == '0'){
                this.$router.replace('/lead/detail/' + x);
            }
            this.init();
        })
    }

    promote(){
        ClienteClient.promoteToCliente(this.user.identifier)
        .then(x => {
            this.$router.replace('/clienti/detail/' + x);
        })
    }

}

