

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import store from '@/store';
import NewListModal from '../components/newListModal.vue';
import toastr from 'toastr';
import Toggler from '@/components/toggler.vue';
import SelectUserModal from '../components/selectUserModal.vue';
import SelectListModal from '../components/selectListModal.vue';
import { CollaboratoreClient, LeadClient } from '@/services/Services';
import { ExportServices } from '../../../services/ExportServices';
import setSubStatoModal from '../components/setSubStatoModal.vue';
import newLeadModal from '../newLeadModal.vue';
import centroDetailModal from '@/components/common/user/components/centroDetailModal.vue';

@Options({})
export default class GestioneLead extends Vue {

    tableResult: OM.PagedResultOfLeadTableVm = new OM.PagedResultOfLeadTableVm();
    lists: string[] = [];
    
    subStatuses: string[] = Enum.LeadSubStatus.GetAll();
    selectedLeads: string[] = [];

    filter: OM.LeadListFilterVm = new OM.LeadListFilterVm();

    created(){
        this.filter.pageNumber = 1;
        this.filter.perPage = 25;
        this.filter.sortBy = null;
        this.filter.showCollaboratori = false;
        this.filter.showCustomers = true;
        this.init();
        store.state.layoutSlugButtonAdd = {
            text: "+",
            callback: () => {
                this.$opModal.show(NewListModal, {
                    saveCb: (name: string) => {
                        LeadClient.createList(name)
                        .then(x => {
                            toastr.success("Salvato!");
                            this.init();
                        })
                    }
                })
            }
        }
    }

    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    ritardo(item: OM.LeadTableVm){
        if(item.scadenze.length == 0){
            return "";
        }
        if(new Date(item.scadenze[0].startDate).getTime() > Date.now())
            return "";

        let today = new Date();
        let lamezza = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        if(new Date(item.scadenze[0].startDate).getTime() <= lamezza.getTime()){
            var diff = lamezza.getTime() - new Date(item.scadenze[0].startDate).getTime();
            var days = diff / 1000 / 60 / 60 / 24;
            return Math.floor(days);
        }
    }

    init(clearSlugs: boolean = false){
        LeadClient.getLists()
        .then( x => {
            this.lists = x;
            if(clearSlugs){
                store.state.layoutSlugs = [];
            } else {
                store.state.layoutSlugs = this.lists.map(x => {
                    return {
                        text: x,
                        selected: this.filter.selectedList == x,
                        callback: (item: VM.LayoutSlug) => {
                            this.filter.selectedList = item.text;
                            this.search();
                        },
                        removeCallback: (item: VM.LayoutSlug) => {
                            if(!confirm("Confermi rimozione lista?"))
                                return;
                                
                            LeadClient.removeList(item.text)
                            .then(x => {
                                store.state.layoutSlugs = store.state.layoutSlugs.filter(x => x != item);
                            });
                        }
                    }
                }).sort((a, b) => a.text > b.text ? 1 : -1)

                store.state.layoutSlugs.unshift({
                    text: "Tutte",
                    selected: this.filter.selectedList == "",
                    callback: (item: VM.LayoutSlug) => {
                        this.filter.selectedList = "";
                        this.search();
                    }
                })
            }
            // LeadClient.getByList(this.selectedList, this.showCustomers, this.showLeads, this.showTelephoneOnly, this.subStatusFilter, this.showInRitardoOnly)
            LeadClient.getByList(this.filter)
            .then(x => {
                this.tableResult = x;
            });
        });
    }

    centroDetails(centro: OM.NameIdentifier){
        CollaboratoreClient.getCentroFumoDetails(centro.identifier)
        .then(x => {
            this.$opModal.show(centroDetailModal, {
                centro: x
            })
        })
    }

    checkall(items: OM.Lead[]){
        if(this.selectedLeads.length > 0){
            this.selectedLeads.splice(0);
        } else {
            items.forEach(x => {
                let ind = this.selectedLeads.indexOf(x.identifier);
                if(ind == -1)
                    this.selectedLeads.push(x.identifier);
                // if(ind > -1){
                    // this.selectedLeads.splice(ind, 1);
                // } else {
                //     this.selectedLeads.push(x.identifier);
                // }
            })
        }
    }

    multipleAssignToUser(type: string){
        this.$opModal.show(SelectUserModal, {
            showAll: type == "commerciale",
            saveCb: (user: OM.NameIdentifier) => {
                LeadClient.assignGestioneMultipleToUser({
                    leadList: this.selectedLeads,
                    user: user.identifier,
                    list: "",
                    treeType: type
                })
                .then(x => {
                    this.selectedLeads = [];
                    this.init();
                })
            }
        })
    }
    multipleAssignToList(){
        this.$opModal.show(SelectListModal, {
            saveCb: (list: string) => {
                LeadClient.assignMultipleToList({
                    leadList: this.selectedLeads,
                    list: list,
                    user: "",
                    treeType: ''
                })
                .then(x => {
                    this.selectedLeads = [];
                    this.init();
                })
            }
        })
    }
    changeSubStatusToList(){
        this.$opModal.show(setSubStatoModal, {
            saveCb: (newSubStatus: string) => {
                LeadClient.setSubStatusToMultiple({
                    leadList: this.selectedLeads,
                    list: newSubStatus,
                    user: "",
                    treeType: ''
                })
                .then(x => {
                    this.selectedLeads = [];
                    this.init();
                })
            }
        })
    }

    leadModal(item: OM.Lead){
        // this.$opModal.show(LeadModal, {
        //     leadIdentifier: item ? item.identifier : "",
        //     leadIdentifierList: this.tableResult.data.map(x => x.identifier),
        //     list: this.filter.selectedList,
        // }, () => {
        //     this.init();
        // })
    }

    removeItem(item: OM.LeadTableVm){
        if(!confirm("Confermi la rimozione?")){
            return;
        }

        LeadClient.delete(item.identifier)
        .then(x => {
            toastr.success("Lead rimosso");
            this.init();
        })
    }

    exportToExcel(){
        LeadClient.exportLeads(this.filter)
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }

    newLeadModal(){
        this.$opModal.show(newLeadModal, {}, () => {
            this.init()
        })
    }

}

