
import { Component, Prop } from "vue-property-decorator";
import * as OM from "@/Model";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class StripeSavedCards extends Vue {

    @Prop({
        default: () => []
    }) cards: OM.StripeCardDetailVm[];

    @Prop() value: string;

	selectedCard: OM.StripeCardDetailVm = new OM.StripeCardDetailVm();
	loaded = false;

	created() {
        this.$nextTick( () => {
            this.loaded = true;
        })
	}

	selectCard(card: OM.StripeCardDetailVm) {
		this.$emit('input', card.cardId);
    }
    
}
