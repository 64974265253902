import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Titolo push",
            text: "Titolo push"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event))
        }, null, 512), [
          [_vModelText, _ctx.title]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Testo push",
            text: "Testo push"
          })
        ]),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
          rows: "3"
        }, "\r\n                ", 512), [
          [_vModelText, _ctx.text]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-success",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Invia",
            text: "Invia"
          })
        ])
      ])
    ])
  ]))
}