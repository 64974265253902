
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class menuEntry extends Vue {

    @Prop() entry: OM.MenuEntry;
    @Prop() readonly: boolean;

    edit: boolean = false;
    periodi: string[] = Enum.PeriodoGiornata.GetAll();

}

