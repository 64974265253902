
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

@Options({})
export default class menuStepTable extends Vue {

    @Prop() items: OM.MenuStep[];
    @Prop() readonly: boolean;

    addItem(){
        let toPush = new OM.MenuStep();
        toPush.numeroGiornata = this.items.length + 1;
        toPush.entries = [
            { periodoPasto: Enum.PeriodoGiornata.Colazione, htmlText: '' },
            { periodoPasto: Enum.PeriodoGiornata.MetaMattina, htmlText: '' },
            { periodoPasto: Enum.PeriodoGiornata.Pranzo, htmlText: '' },
            { periodoPasto: Enum.PeriodoGiornata.Merenda, htmlText: '' },
            { periodoPasto: Enum.PeriodoGiornata.Cena, htmlText: '' },
            { periodoPasto: Enum.PeriodoGiornata.SpuntinoSerale, htmlText: '' },
        ]

        if(this.items[this.items.length - 1] && this.items[this.items.length - 1].dataMenu){
            toPush.dataMenu = moment(this.items[this.items.length - 1].dataMenu).add(1, 'day').toISOString();
        }

        this.items.push(toPush);
    }

    removeItem(index: number){
        this.items.splice(index, 1);
    }

}

