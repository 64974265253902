
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, OffertaClient, ShopCategoryClient } from '@/services/Services';
import { taggedTemplateExpression } from '@babel/types';
import store from '@/store';

@Options({})
export default class ShopCategory extends Vue {

    offers: OM.Offerta[] = [];
    cardPaid: boolean = false;
    bonificoPaid: boolean = false;
    shopCategory: OM.ShopCategoryDetailsVm = null;
    loaded: boolean = false;

    clientiCollegati: OM.NameIdentifier[] = [];
    activateOnCliente: OM.NameIdentifier = null;
    
    created(){
        Promise.all([
            ShopCategoryClient.getDetails(this.$route.params.categoryIdentifier as string).then(x => this.shopCategory = x),
            OffertaClient.getOffersForCliente(store.state.user.identifier, this.$route.params.categoryIdentifier as string).then(x => this.offers = x),
            CollaboratoreClient.getCentriFumoByCommerciale(store.state.user.identifier)
            .then(x => {
                this.clientiCollegati = x;
                this.clientiCollegati.unshift({
                    identifier: store.state.user.identifier,
                    name: store.state.user.personalData.completeName
                })
            })
        ]).then(x => this.loaded = true)
    }

    onCardPaid(){
        this.cardPaid = true;
    }

    onBonificoPaid(){
        this.bonificoPaid = true;
    }

    next(){
        this.$router.back();
    }

}

