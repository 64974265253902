import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_payment_data = _resolveComponent("user-payment-data")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_fiscal_data = _resolveComponent("fiscal-data")!
  const _component_contact_data = _resolveComponent("contact-data")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_user_payment_data, { value: _ctx.value }, null, 8, ["value"]),
    _createVNode(_component_personal_data, {
      value: _ctx.value.personalData
    }, null, 8, ["value"]),
    _createVNode(_component_fiscal_data, {
      value: _ctx.value.fiscalData
    }, null, 8, ["value"]),
    _createVNode(_component_contact_data, {
      value: _ctx.value.contactData
    }, null, 8, ["value"])
  ]))
}