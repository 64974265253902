import { CommonServices } from "@/services/common";
import * as OM from "@/Model";
import * as VM from '@/viewModel';
import { baseUrl } from '@/config';

class _PrenotazioneServices {

    addCommentoInterno(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "Prenotazione/AddCommentoInterno", vm)
    }
    
    addCommento(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "Prenotazione/AddCommento", vm)
    }

}

export let PrenotazioneServices = new _PrenotazioneServices();