import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_calendar_prenotazione = _resolveComponent("calendar-prenotazione")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Professione",
          text: "Professione"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.professione) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.professioni, (item) => {
          return (_openBlock(), _createElementBlock("option", {
            key: item.identifier,
            value: item
          }, _toDisplayString(item.name), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.professione]
      ])
    ]),
    _createVNode(_component_calendar_prenotazione, {
      availabilities: _ctx.availabilities,
      value: _ctx.value
    }, null, 8, ["availabilities", "value"])
  ]))
}