import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d425e550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "modalBody" }
const _hoisted_4 = {
  filter: "date",
  sort: "date"
}
const _hoisted_5 = {
  filter: "nome",
  sort: "nome"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { colspan: "99" }
const _hoisted_8 = { class: "modalFooter text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Lettura utenti",
          text: "Lettura utenti"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_op_table, {
        class: "table table-striped",
        items: _ctx.item.userRead
      }, {
        default: _withCtx((opData) => [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_4, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Data",
                  text: "Data"
                })
              ]),
              _createElementVNode("td", _hoisted_5, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Nome",
                  text: "Nome"
                })
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (opData.items.length == 0)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                  _createElementVNode("td", _hoisted_7, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Nessun elemento trovato",
                      text: "Nessun elemento trovato"
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opData.items, (item, i) => {
              return (_openBlock(), _createElementBlock("tr", { key: i }, [
                _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.date)), 1),
                _createElementVNode("td", null, _toDisplayString(item.value.name), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["items"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-danger",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}