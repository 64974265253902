
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { NazioneClient, VideoDellaSettimanaClient } from '@/services/Services';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class videoDellaSettimanaViewer extends Vue {

    @Prop() video: OM.NameIdentifier;

    item: OM.VideoDellaSettimana = null;

    created(){
        this.init();
    }

    init(){
        if(!this.video)
            return;

        this.item = null;
        VideoDellaSettimanaClient.getById(this.video.identifier)
        .then(x => {
            this.item = x;
        })
    }

    @Watch('video')
    onVideoChange(next, prev){
        this.init();
    }

}
