
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import { NetworkClient } from '@/services/Services';

@Options({})
export default class Node extends Vue {

    @Prop() isCommerciale: boolean;
    @Prop() showFilters: boolean;

    @Prop({
        type: [Object, VM.NetworkUserVM]
    }) node: VM.NetworkUserVM;

    @Prop({
        default: true
    }) allowNavigation: boolean;

    children: VM.NetworkUserVM[] = [];
    childrenLoaded: boolean = false;
    
    showClienti: boolean = true;
    showLead: boolean = true;

    loading: boolean = false;

    @Prop({
        default: false
    }) open: boolean;

    @Watch('node.hide')
    onHideChange(next, prev){
        this.childrenLoaded = false;
        this.children = [];
    }

    @Watch('isCommerciale')
    @Watch('showClienti')
    @Watch('showLead')
    onIsCommercialeChange(next, prev){
        this.fetchChildren();
    }

    created(){
        this.init();
    }

    init(){
        if(this.open){
            this.toggleChildren();
        }
    }

    showSiblings(){
        this.children.forEach(x => {
            x.hide = false;
        })
    }

    hideSiblings(item: OM.NetworkUserVM){
        this.children.forEach(x => {
            if(x.identifier != item.identifier){
                x.hide = true;
            }
        })
    }

    onNodeClick(){
        if(!this.allowNavigation)
            return;

        this.toggleChildren();
    }

    toggleChildren(){
        this.node.hide = false;
        if(this.childrenLoaded){
            this.children = [];
            this.childrenLoaded = false;
            this.$emit('closed');
            return;
        }
        this.fetchChildren();
    }

    fetchChildren(){
        this.loading = true;
        let prom = this.isCommerciale ? 
            NetworkClient.getCommercialeDescendantsOf(this.node.identifier, this.showClienti, this.showLead, false) : 
            NetworkClient.getGestioneDescendantsOf(this.node.identifier, this.showClienti, this.showLead, false);
        prom
        .then(x => {
            this.children = x;
            this.childrenLoaded = true;

            let adder = 174;
            let counter = -Math.floor(this.children.length / 2); //0;
            let isPair = this.children.length % 2 == 0 ? 1 : 0;
            if(isPair){
                counter += 0.5;
                //adder = adder * -1;
            }
            this.children.forEach((x, i) => {
                x.hide = false;
                x.offset = 'left: ' + (counter * adder) + 'px;'
                counter++;
                // if((i + isPair) % 2 == 0)
                //     counter = Math.abs(counter) + 1;
                // else
                //     counter *= -1;
            })
            //ordinamento dei nodi per essere messi belli nel dom
            let reg = /[0-9,-]/g;
            this.children.sort((a, b) => {
                let aOffset = parseInt(a.offset.match(reg).join(''));
                let bOffset = parseInt(b.offset.match(reg).join(''));
                if(aOffset > bOffset)
                    return 1;
                else
                    return -1;
            })
            this.$emit('opened');
        })
        .finally(() => this.loading = false);
    }
}

