

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { LeadClient, CollaboratoreClient } from '@/services/Services';

@Options({})
export default class Assegnazioni extends Vue {

    assegnazioni: OM.StoricoAssegnazioneVm[] = [];

    startDate: string = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    endDate: string = new Date().toISOString();
    descendants: boolean = false;
    userIdentifier: string = "";

    usersForDropdownReferrer: any[] = [];

    created(){
        CollaboratoreClient.getDescendantsNameIdentifier()
        .then(x => {
            this.usersForDropdownReferrer = x;
            this.init();
        })
    }

    init(){
        LeadClient.getAssegnazioni(this.startDate, this.endDate, this.userIdentifier, this.descendants)
        .then(x => {
            this.assegnazioni = x;
        })
    }

    openLeadModal(item: OM.NameIdentifier){
        // this.$opModal.show(LeadModal, {
        //     leadIdentifier: item.identifier,
        //     leadIdentifierList: this.assegnazioni.map(x => x.lead.identifier),
        // }, () => {
        // })
    }

}

