
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import CommentText from '@/components/common/comment/commentText.vue';
import { LeadClient } from '@/services/Services';

@Options({})
export default class RiepilogoModal extends Vue {

    @Prop() date: string;
    @Prop() startDate: string;
    @Prop() endDate: string;
    @Prop() userIdentifier: string;
    @Prop() descendants: boolean;
    @Prop() showLavorati: boolean;

    items: OM.LeadScadenzaVm[] = [];

    created(){
        this.init();
    }
    init(){
        LeadClient.getScadenze(this.startDate, this.endDate, this.userIdentifier, this.descendants, this.showLavorati)
        .then(x => {
            this.items = x.scadenze;
        })
    }

    isPast(date, hour){
        if(hour){
            let minHour = parseInt(hour.split(':')[0]);
            return new Date(date).setHours(minHour) <= Date.now();
        } else {
            return new Date(date).getTime() <= Date.now();
        }
    }

    
    leadModal(item: OM.LeadScadenzaVm){
        // this.$opModal.show(LeadModal, {
        //     leadIdentifier: item ? item.identifier : "",
        //     leadIdentifierList: this.items.map(x => x.identifier),
        // }, () => {
        //     this.init();
        // })
    }
}

