import { RouteRecordRaw } from 'vue-router';
import Nazioni from './nazioni.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/nazioni',
        component: Nazioni,
        meta: { title: "Nazioni"},
    },
];

export default routes;