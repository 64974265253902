
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import { AcquistoClient, ClienteClient, LeadClient } from '@/services/Services';

@Options({})
export default class VenditeStorno extends Vue {

    clientiForDropdown: OM.NameIdentifier[] = [];
    selectedCliente: OM.NameIdentifier = null;
    acquistoIdentifier: string = "";
    acquistiList: OM.Vendita[] = [];
    percentualePuntiStorno: number = 100;
    actualProductLevel: number = 0;
    productLevelApplied: number = null;

    @Watch('selectedCliente')
    onSelectedClienteChange(next, prev){
        Promise.all([
            LeadClient.getByIdVm(this.selectedCliente.identifier),
            AcquistoClient.getByCliente(this.selectedCliente.identifier)
        ])
        .then(xs => {
            this.actualProductLevel = xs[0].productLevel;
            this.acquistiList = xs[1];
        })
    }

    created() {
        LeadClient.getNameIdentifiers()
        .then(x => {
            this.clientiForDropdown = x;
        })
    }

    searchCb(input: string){
        return this.clientiForDropdown.filter(x => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }

    saveAcquisto() {
        if(!window.confirm("Confermi lo storno?"))
            return;

        AcquistoClient.saveStorno(this.acquistoIdentifier, this.percentualePuntiStorno, this.productLevelApplied)
        .then(x => {
            toastr.success('Storno eseguito');
        })
    }

}

