import { RouteRecordRaw } from 'vue-router';
import contrattiCollaborazione from './contrattiCollaborazione.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/contrattiCollaborazione',
        component: contrattiCollaborazione,
        meta: { title: "Contratti collaborazione"},
    },
];

export default routes;