import { RouteRecordRaw } from 'vue-router';
import mailSentLog from './mailSentLog.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/mailSentLog',
        component: mailSentLog,
        meta: { title: "Log email inviate"},
    },
];

export default routes;