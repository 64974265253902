

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { TicketPianoAlimentareClient, LeadClient, PianoAlimentareClient, MisuraClient, SignedDocumentsClient, AcquistoClienteClient } from '@/services/Services';
import store from '@/store';
import risposteQuestionarioModal from '@/components/risposteQuestionarioModal.vue';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import misureTable from './components/misureTable.vue';

@Options({
    components: {
        misureTable
    }
})
export default class leadDetail extends Vue {
 
    user: OM.UpdateLeadVm = null;
    showAs: string = "DatiBase";

    rolesList: string[] = Enum.UserRoles.GetAll();
    ticketPianoAlimentare: string[] = [];
    pianiAlimentari: OM.PianoAlimentare[] = [];
    misure: OM.Misura[] = [];
    acquisti: OM.AcquistoClienteMiniVm[] = [];

    docs: OM.SignedDocument[] = [];

    created(){
        if(this.$route.params.identifier as string == '0'){
            this.user = new OM.UpdateLeadVm();
            this.user.role = Enum.UserRoles.Lead;
        } else {
            this.init();
        }
    }

    init(){
        LeadClient.getByIdVm(this.$route.params.identifier as string).then( x => {
            this.user = x
            this.user.authData.password = "";
        });
        PianoAlimentareClient.getByClienteIdentifier(this.$route.params.identifier as string).then(x => this.pianiAlimentari = x)
        TicketPianoAlimentareClient.getAvailableTickets(this.$route.params.identifier as string).then(x => this.ticketPianoAlimentare = x)
        AcquistoClienteClient.getAcquistoClienteMiniVm(this.$route.params.identifier as string).then(x => this.acquisti = x);
        SignedDocumentsClient.getByUser(this.$route.params.identifier as string).then(x => this.docs = x);
    }

    vediQuestionario(){
        this.$opModal.show(risposteQuestionarioModal, {
            userIdentifier: this.user.identifier
        })
    }

    vediQuestionarioInterno(){
        this.$opModal.show(risposteQuestionarioModal, {
            userIdentifier: this.user.identifier,
            isInterno: true
        })
    }

    newPianoAlimentare(){
        ModalServices.confirmModal(
            LocalizationServices.GetLocalizedValue('Confermi?', 'Confermi?'),
            LocalizationServices.GetLocalizedValue('Scaleremo un ticket', 'Scaleremo un ticket'),
            LocalizationServices.GetLocalizedValue('Si', 'Si'),
            LocalizationServices.GetLocalizedValue('No', 'No'),
            () => {
                let nutrizionista = this.user.professionistiAssegnati.find(x => x.professione.name == 'Nutrizionista');
                PianoAlimentareClient.createPianoAlimentare(this.user.identifier, nutrizionista.user.identifier, this.ticketPianoAlimentare[0])
                .then(x => {
                    this.$router.push('/pianoAlimentareDetail/' + x);
                })
            }
        );
    }

    saveMisura(item: OM.Misura){
        MisuraClient.save(item)
        .then(x => {
            delete (<any>item).modify;
        });
    }

}

