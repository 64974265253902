
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { DashboardClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';
import ProfileModal from '../profileModal.vue';

@Options({})
export default class Profilo extends Vue {

    @Prop() userIdentifier: string;
    @Prop() reload;

    profileDash: OM.DashboardProfileVm  = null;
    
    imageToUpload: File = null;
    @Watch('imageToUpload')
    onImageToUploadChange(next, prev){
        UploadServices.UploadUserImage({
            itemIdentifier: this.userIdentifier,
            file: next
        })
        .then(x => {
            this.profileDash.imageUrl = x;
        })
    }
    
    created(){
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }

    init(){
        DashboardClient.getDashProfile(this.userIdentifier)
        .then( x => {
            this.profileDash = x;
        });
    }
    
    openProfileModal(){
        this.$opModal.show(ProfileModal, {}, () => this.init());
    }

}

