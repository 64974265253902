
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { LeadClient } from '@/services/Services'

@Options({})
export default class datiBaseUser extends Vue {
 
    @Prop() user: OM.Lead;
    @Prop({
        default: null
    }) roles: string[];
    @Prop() readonly: boolean;
    @Prop() canEditStatoCommerciale: boolean;

    rolesList: string[] = Enum.UserRoles.GetAll();
    leadStatoCommercialeList: string[] = Enum.LeadStatoCommerciale.GetAll();

    created(){
        if(this.roles){
            this.rolesList = this.roles;
        }
    }

    saveStatoCommerciale(){
        LeadClient.setStatoCommerciale(this.user.identifier, this.user.statoCommerciale)
        .then(x => {
            this.$emit('init');
        })
    }

}

