
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, PrenotazioneClient, ClienteClient } from '@/services/Services';
import { PrenotazioneServices } from '@/services/PrenotazioneServices';
import questionarioInternoModal from '@/components/questionarioInternoModal.vue';
import risposteQuestionarioModal from '@/components/risposteQuestionarioModal.vue';
import uploadPostVisitaModal from './uploadPostVisitaModal.vue';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class prenotazioniDetail extends Vue {

    prenotazione: OM.Prenotazione = null;
    dottore: OM.Collaboratore = null;
    cliente: OM.Cliente = null;

    created(){
        this.init();
    }

    init(){
        this.prenotazione = null;
        PrenotazioneClient.getById(this.$route.params.identifier as string)
        .then(x => {
            this.prenotazione = x;
            Promise.all([
                CollaboratoreClient.getById(this.prenotazione.user.identifier),
                ClienteClient.getById(this.prenotazione.cliente.identifier)
            ])
            .then(xs => {
                this.dottore = xs[0];
                this.cliente = xs[1];
            })
        })
    }

    addCommentoInterno(vm: VM.AddCommentEvent){
        PrenotazioneServices.addCommentoInterno({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.prenotazione.identifier
        })
        .then(x => {
            this.prenotazione.commentiInterni.unshift(x);
        })
    }

    addCommento(vm: VM.AddCommentEvent){
        PrenotazioneServices.addCommento({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.prenotazione.identifier
        })
        .then(x => {
            this.prenotazione.commenti.unshift(x);
        })
    }

    get canConcludere(){
        return new Date(this.prenotazione.date) < new Date() && this.prenotazione.statoHistory[0].value == 'In attesa';
    }
    
    concludiPrenotazione(esitoPositivo){
        if(!this.canConcludere){
            ModalServices.alertModal(
                LocalizationServices.GetLocalizedValue('Attenzione', 'Attenzione'),
                LocalizationServices.GetLocalizedValue("Devi attendere la data dell'appuntamento per poterlo segnare come concluso", "Devi attendere la data dell'appuntamento per poterlo segnare come concluso"),
            )
            return;
        }

        let text = "Confermi lo svolgimento dell'appuntamento, " + (esitoPositivo ? 'POSITIVO' : 'NEGATIVO') + "?";
        if(!confirm(text))
            return;

        PrenotazioneClient.concludiPrenotazioneWithEsito(this.prenotazione.identifier, esitoPositivo)
        .then(x => {
            window.toastr.success("Prenotazione conclusa");
            this.init();
        })
    }

    respingiPrenotazione(){
        if(!confirm("Confermi di voler respingere la prenotazione?"))
            return;

        PrenotazioneClient.respingiPrenotazione(this.prenotazione.identifier)
        .then(x => {
            window.toastr.success("Prenotazione respinta");
            this.init();
        })
    }

    vediQuestionario(){
        this.$opModal.show(risposteQuestionarioModal, {
            userIdentifier: this.cliente.identifier
        })
    }

    compilaQuestionarioInterno(){
        this.$opModal.show(questionarioInternoModal, {
            cliente: this.cliente
        })
    }

    vediQuestionarioInterno(){
        this.$opModal.show(risposteQuestionarioModal, {
            userIdentifier: this.cliente.identifier,
            isInterno: true
        })
    }

    openUploadPostVisitaModal(){
        this.$opModal.show(uploadPostVisitaModal, {
            prenotazioneIdentifier: this.prenotazione.identifier,
        })
    }

    deleteCommento(item: OM.Comment){
        PrenotazioneClient.removeCommento(this.prenotazione.identifier, item.identifier)
        .then(x => {
            this.init();
        })
    }

    deleteCommentoInterno(item: OM.Comment){
        PrenotazioneClient.removeCommentoInterno(this.prenotazione.identifier, item.identifier)
        .then(x => {
            this.init();
        })
    }



}
