
import { LeadClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class SelectListModal extends Vue {

    @Prop() saveCb: (item: string) => void;

    lists: string[] = [];
    selectedList: string = "";

    created(){
        LeadClient.getLists()
        .then(x => {
            this.lists = x;
        })
    }

    save(){
        this.saveCb(this.selectedList);
        this.$emit('close');
    }

}

