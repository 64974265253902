

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr'
import { HtmlTextConfigClient } from '@/services/Services';

@Options({})
export default class HtmlTextConfigView extends Vue {
    config: OM.HtmlTextConfig = new OM.HtmlTextConfig();
    days: any = Enum.DayOfWeek.GetAll();

    mounted(){
        HtmlTextConfigClient.getConfig()
        .then( x => {
            if(x){
                this.config = x;
            }
        });
    }

    save(){
        HtmlTextConfigClient.save(this.config)
        .then(x => {
            toastr.success("Salvato!");  
        })
        .catch( err => {
            toastr.error("Errore");  
        });
    }

    addTime(){
        this.config.orariTicket.times.push(new OM.OrariAperturaTicketTimes());
    }

    removeTime(i: number){
        this.config.orariTicket.times.splice(i, 1);
    }

}

