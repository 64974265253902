import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group ml-2" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onUpdate:modelValue", "onChange"]
const _hoisted_7 = { value: "" }
const _hoisted_8 = ["value"]
const _hoisted_9 = ["onUpdate:modelValue", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_toggler = _resolveComponent("toggler")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Cerca",
            text: "Cerca"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
        }, null, 512), [
          [_vModelText, _ctx.search]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Solo abilitati",
            text: "Solo abilitati"
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_toggler, {
            modelValue: _ctx.soloAbilitati,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.soloAbilitati) = $event))
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _createElementVNode("table", _hoisted_4, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nome",
              text: "Nome"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Codice 2",
              text: "Codice 2"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Codice 3",
              text: "Codice 3"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Prefisso",
              text: "Prefisso"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Valuta",
              text: "Valuta"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Abilitato",
              text: "Abilitato"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Ricezione lead",
              text: "Ricezione lead"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Punti assegnazione",
              text: "Punti assegnazione"
            })
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered, (item, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", null, _toDisplayString(item.name), 1),
            _createElementVNode("td", null, _toDisplayString(item.alpha2Code), 1),
            _createElementVNode("td", null, _toDisplayString(item.alpha3Code), 1),
            _createElementVNode("td", null, _toDisplayString(item.callingCode), 1),
            _createElementVNode("td", null, _toDisplayString(item.currency), 1),
            _createElementVNode("td", null, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn", {'btn-danger': item.disabled, 'btn-success': !item.disabled}]),
                onClick: ($event: any) => (_ctx.toggleNazione(item))
              }, _toDisplayString(item.disabled ? 'Disabilitato' : 'Abilitato'), 11, _hoisted_5)
            ]),
            _createElementVNode("td", null, [
              _withDirectives(_createElementVNode("select", {
                class: "form-control",
                "onUpdate:modelValue": ($event: any) => ((item.userRicezioneLead) = $event),
                onChange: ($event: any) => (_ctx.save(item))
              }, [
                _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Seleziona", "Seleziona")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (item, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: item
                  }, _toDisplayString(item.name), 9, _hoisted_8))
                }), 128))
              ], 40, _hoisted_6), [
                [_vModelSelect, item.userRicezioneLead]
              ])
            ]),
            _createElementVNode("td", null, [
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": ($event: any) => ((item.puntiAccettazione) = $event),
                onInput: ($event: any) => (_ctx.save(item)),
                placeholder: "Punti accettazione"
              }, null, 40, _hoisted_9), [
                [_vModelText, item.puntiAccettazione]
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}