
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { LeadClient, LoginClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import store from '@/store';

@Options({})
export default class addLinkedAccountModal extends Vue {

    vm = { email: '', password: ''};
    showPassword: boolean = false;
    error: string = "";

    login(){
        let loginVm: OM.LoginVm = {
            username: this.vm.email, 
            password: this.vm.password
        }
        LeadClient.addLinkedAccount(loginVm)
        .then(x => {
            store.state.user.linkedAccounts.push(x);
            this.$emit('close');
        })
        .catch(err => {
            if(err == 'not_found'){
                this.error = "Dati di login non corretti";
            } else {
                this.error = err;
            }
        });
    }

}

