
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({})
export default class ChatMessage extends Vue {
    nowstamp: number = new Date().getTime();
    userId: string = store.state.loginData.userIdentifier;
    flagged: boolean = false;

    @Prop() canRemove: boolean;
    @Prop() messagesToRemove: OM.ChatMessage[];
    @Prop({
        type: [Object, OM.ChatMessage]
    }) message: OM.ChatMessage;

    @Watch('messagesToRemove.length')
    onMessagesToRemoveChange(next, prev){
        if(next == 0){
            this.flagged = false;
        }
    }

    getElapsedTime(timestamp: number){
        let elapsed = this.nowstamp - timestamp;
        let minutes = elapsed / 1000 / 60;
        if(minutes > 60){
            let hours = minutes / 60;
            if(hours > 24){
                let days = hours / 24;
                return Math.abs(days).toFixed(0) + ' giorni fa';
            } else {
                return Math.abs(hours).toFixed(0) + ' ore fa';
            }
        } else {
            return Math.abs(minutes).toFixed(0) + ' minuti fa';
        }
    }
    
    mounted(){
        let container: any = this.$refs.messageContainer;
        let imgs = container.querySelectorAll('._chatImg');
        imgs.forEach(x => {
            x.addEventListener('click', (ev) => {
                let background = getComputedStyle(ev.target).backgroundImage;
                let splitted = background.split(',');
                let url = splitted[0].substring(5, splitted[0].length -2);
                window.open(url, '_blank');
            });
        })
    }

    emitRemove(){
        this.flagged = !this.flagged;
        this.$emit('removeMessage', this.message);
    }
    
}
