

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { MessageOfTheDayClient } from '@/services/Services';

@Options({})
export default class MessagesOfTheDay extends Vue {

    messages: OM.MessageOfTheDay[] = [];
    newMessage: OM.MessageOfTheDay = null;

    created(){
        this.init();
    }
    init(){
        MessageOfTheDayClient.getAll()
        .then( x => {
            this.messages = x;
        });
    }

    addMessage(){
        this.newMessage = new OM.MessageOfTheDay();
        if(this.messages[0] && this.messages[0].identifier){
            this.messages.unshift(this.newMessage);
        } else {
            if(this.messages[0]){
                this.messages[0] = this.newMessage;
            } else {
                this.messages.unshift(this.newMessage);
            }
        }
    }

    removeItem(item: OM.MessageOfTheDay){
        if(!confirm("Vuoi eliminare " + item.message + "?"))
            return;

        MessageOfTheDayClient.delete(item.identifier)
        .then(x => {
            this.init();
        });
    }

    disableItem(item: OM.MessageOfTheDay){
        item.disabled = !item.disabled;
        this.saveItem(item);
    }

    saveItem(item: OM.MessageOfTheDay){
        MessageOfTheDayClient.save(item)
        .then(x => {
            this.init();
        });
    }

}

