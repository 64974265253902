
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient } from '@/services/Services';

@Options({})
export default class FatturatoPerMese extends Vue {

    @Prop() userIdentifier: string;
    
    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";
    fatturato: number = null;

    created(){
        this.fromDate = moment(new Date()).startOf('month').toISOString();
        this.toDate = moment(new Date()).endOf('month').toISOString();
        this.periodo = moment(this.fromDate).format("MMMM YYYY");
        this.getFatturato();
    }

    getFatturato(){
        AcquistoClient.getFatturatoByPeriod(this.fromDate, this.toDate, this.userIdentifier)
        .then(x => {
            this.fatturato = x;
        })
    }

    prevMonth(){
        this.fromDate = moment(this.fromDate).subtract(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).format("MMMM YYYY");
        this.getFatturato();
    }

    nextMonth(){
        this.fromDate = moment(this.fromDate).add(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).add(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).format("MMMM YYYY");
        this.getFatturato();
    }

}

