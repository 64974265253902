
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient, PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import appuntamentoModal from '@/components/common/user/cliente/appuntamentoModal.vue';
import SpostaPrenotazioneModal from '@/components/appuntamentiNutrizionista/spostaPrenotazioneModal.vue';
import { ModalServices } from '@/services/ModalServices';
import centroDetailModal from './centroDetailModal.vue';
import prenotaAppuntamentoModal from '@/views/dashboard/prenotazione/prenotaAppuntamentoModal.vue';
import EventBus from '@/services/EventBus';

@Options({})
export default class tabellaPrenotazioni extends Vue {
 
    @Prop() user: OM.Cliente;
    @Prop() reload;
    @Prop({
        default: true
    }) useOpTable: boolean;

    tickets: OM.TicketPrenotazioneWithRoleVm[] = [];
    prenotazioni: OM.PrenotazioneForListVm[] = [];
    
    filtersFn = {
        fasciaOraria: (prop: OM.FasciaOraria, val) => {
            return prop.from.indexOf(val) > -1 || prop.to.indexOf(val) > -1;
        }
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    created(){
        this.init();
    }

    init(){
        TicketPrenotazioneClient.getAvailableTicketsWithRole(this.user.identifier)
        .then(x => {
            this.tickets = x;
        })
        PrenotazioneClient.getByCliente(this.user.identifier)
        .then(x => {
            this.prenotazioni = x;
        })
    }

    makePrenotazione(){
        if(this.tickets.length <= 0)
            return;

        this.$opModal.show(prenotaAppuntamentoModal, {
            ticketPrenotazione: this.tickets[0],
            lead: this.user
        }, () => {
            EventBus.$emit('reloaddash');
        })

        // this.$opModal.show(appuntamentoModal, {
        //     cliente: this.user,
        //     ticketPrenotazioneIdentifier: this.tickets[0]
        // }, () => {
        //     this.init();
        // })
    }

    centroDetails(centro: OM.NameIdentifier){
        CollaboratoreClient.getCentroFumoDetails(centro.identifier)
        .then(x => {
            this.$opModal.show(centroDetailModal, {
                centro: x
            })
        })
    }

    spostaPrenotazione(item: OM.PrenotazioneForListVm){
        this.$opModal.show(SpostaPrenotazioneModal, {
            prenotazioneIdentifier: item.identifier,
            userIdentifier: item.user.identifier
        })
    }

    respingiPrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.respingiPrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione respinta");
            this.init();
        })
    }
    
    deletePrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.deletePrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione eliminata");
            this.init();
        })
    }
    
    // concludiPrenotazione(prenoId: string){
    //     if(!confirm("Confermi lo svolgimento dell'appuntamento?"))
    //         return;

    //     PrenotazioneClient.concludiPrenotazione(prenoId)
    //     .then(x => {
    //         window.toastr.success("Prenotazione conclusa");
    //         this.init();
    //     })
    // }

}

