
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class BarreRisultato extends Vue {

    @Prop() user: OM.UserObiettivoCardVm;
    
    resultHeight: string = "";
    targetHeight: string = "";
    isOver: boolean = false;

    created(){
        this.calc();
    }

    calc(){
        let ris =  this.user.risultato * 100 / this.user.obiettivo;
        if(ris > 100){
            let targetPerc = this.user.obiettivo * 100 / this.user.risultato;
            this.resultHeight = `height: 100%;`;
            this.targetHeight = `height: ${targetPerc}%;`;
            this.isOver = true;
            ris = ris - 100;
        } else {
            this.targetHeight = `height: 100%;`;
            this.resultHeight = `height: ${ris}%;`;
        }
    }

}

