
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class CartItem extends Vue {
    
    @Prop() cartItem: OM.OfferInCartVm;
    @Prop() edit: boolean;

    get totalPrice(){
        return Math.round((this.cartItem.priceEntry.importo * this.cartItem.quantity) * 100) / 100;
    }
    
    lower(){
        if(this.cartItem.quantity > 0){
            this.cartItem.quantity--;
            this.$emit('quantity', this.cartItem.quantity)
        }
    }

    higher(){
        if(this.cartItem.quantity < 99){
            this.cartItem.quantity++;
            this.$emit('quantity', this.cartItem.quantity)
        }
    }   
}

