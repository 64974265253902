
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { CollaboratoreClient, DashboardClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';

@Options({})
export default class Obiettivo extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    useNegatives: boolean = false;
    vm: OM.ObiettivoMensileVm = new OM.ObiettivoMensileVm();

    targetWidth: string = "width: 0%;";
    resultWidth: string = "width: 0%;";
    risultatoPerc: number = 0;
    isOver: boolean = false;
    periodo: string = "";
    
    created(){
        this.periodo = new Date().toISOString();
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    @Watch('useNegatives')
    onUseNegativesChange(next, prev){
        this.init();
    }

    init(){
        let mese = new Date(this.periodo).getMonth() + 1;
        let anno = new Date(this.periodo).getFullYear();
        DashboardClient.getGuadagniObiettivoMensileByMonthResult(this.user.identifier, mese, anno, this.useNegatives)
        .then( x => {
            this.vm = x;
            if(this.vm.obiettivoMensile == 0){
                this.targetWidth = "width: auto;";
                this.resultWidth = "width: auto;";
                this.risultatoPerc = 0;
                return;
            }
            setTimeout(() => {
                this.isOver = false;
                this.risultatoPerc = this.vm.risultato * 100 / this.vm.obiettivoMensile;
                if(this.risultatoPerc > 100){
                    let targetPerc = this.vm.obiettivoMensile * 100 / this.vm.risultato;
                    this.resultWidth = `width: 100%;`;
                    this.targetWidth = `width: ${targetPerc}%;`;
                    this.isOver = true;
                    this.risultatoPerc = this.risultatoPerc - 100;
                } else {
                    this.targetWidth = `width: 100%;`;
                    this.resultWidth = `width: ${this.risultatoPerc}%;`;
                }
            }, 100)
        });
    }

    openModalObiettivo(){
        ModalServices.inputModal("Modifica obiettivo mensile", "Inserisci il tuo obiettivo", "", "", "", "", (value) => {
            CollaboratoreClient.setObiettivoMensile(this.user.identifier, parseFloat(value))
            .then(x => {
                window.toastr.success("Obiettivo salvato!");
                this.init();
            })
        })
    }


}

