
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class authData extends Vue {

    @Prop() value: OM.AuthData;
    @Prop({
        default: false
    }) canEnableLogin: boolean;

    showPassword: boolean = false;

    @Watch('value')
    onValueChange(){
        this.init();
    }

    created(){
        this.init();
    }

    init(){
        this.value.password = "";
    }

    showPasswordChange(){
        this.value.password = "";
        this.showPassword = !this.showPassword;
    }

}
