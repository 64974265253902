

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr'
import { AppConfigClient, HtmlTextConfigClient } from '@/services/Services';
import orariAperturaTicket from './orariAperturaTicket.vue';
import roleModules from './roleModules.vue';
import pagamentoRequestConfig from './pagamentoRequestConfig.vue';

@Options({
    components: {
        orariAperturaTicket,
        roleModules,
        pagamentoRequestConfig
    }
})
export default class AppConfig extends Vue {

    config: OM.AppConfig = new OM.AppConfig();
    days: any = Enum.DayOfWeek.GetAll();

    mounted(){
        AppConfigClient.getConfig()
        .then( x => {
            this.config = x;
            this.config.primoMessaggioAperturaTicket = this.config.primoMessaggioAperturaTicket || "";
        });
    }

    save(){
        AppConfigClient.save(this.config)
        .then(x => {
            toastr.success("Salvato!");  
        })
        .catch( err => {
            toastr.error("Errore");  
        });
    }

}

