import GestioneLead from './table/gestioneLead.vue';
import InAccettazione from './table/inAccettazione.vue';
import leadDetail from './leadDetail.vue';
import leadDetailVisualizza from './leadDetailVisualizza.vue';
import Pipeline from './pipeline/pipeline.vue';
import Assegnazioni from './assegnazioni/assegnazioni.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/lead/table',
        component: GestioneLead,
        meta: { title: "Tabella lead"},
    },
    {
        path: '/lead/accettazione',
        component: InAccettazione,
        meta: { title: "Lead in accettazione"},
    },
    {
        path: '/lead/detail/:identifier',
        component: leadDetail,
        meta: { title: "Dettaglio lead"},
    },
    {
        path: '/lead/detail/:identifier/visualizza',
        component: leadDetailVisualizza,
        meta: { title: "Visualizzazione lead"},
    },
    {
        path: '/lead/pipeline',
        component: Pipeline,
        meta: { title: "Pipeline"},
    },
    {
        path: '/lead/assegnazioni',
        component: Assegnazioni,
        meta: { title: "Assegnazioni"},
    },
];

export default routes;