
import * as VM from '@/viewModel';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class NetworkView extends Vue {

    mostraLead: boolean = true;
    mostraClienti: boolean = true;

    isCommerciale: boolean = false;
    firstNode: VM.NetworkUserVM = {
        createdOn: null,
        role: null,
        level: 0,
        username: store.state.user.personalData.completeName,
        qualifica: store.state.user.pianoProvvigioni.qualifica,
        puntiReteMaturati: 0,
        identifier: store.state.loginData.userIdentifier,
        parentIdentifier: '',
        nome: store.state.user.personalData.completeName,
        imageUrl: "",
        referralCode: "",
        totaleClienti: 0,
        disattivaCalcolo: false,
        nonFumatore: false
    }

    created(){
        this.isCommerciale = this.$route.name == 'networkCommerciale';
    }
}

