
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import { Prop } from 'vue-property-decorator';
import EventBus from '@/services/EventBus';

@Options({})
export default class appuntamentoRecapModal extends Vue {

    @Prop() modificaPrenotazioneIdentifier: string;
    
    prenotazioneData: VM.PrenotazioneDataVm = null;
    // pins: OM.LatLongIdentifierVm[] = [];
    
    prevPrenotazione: OM.PrenotazioneForListVm = null;

    created(){
        this.prenotazioneData = store.state.prenotazioneData;
        // this.pins.push({
        //     latLong: this.prenotazioneData.centro.contactData.shippingAddress.latLong,
        //     identifier: this.prenotazioneData.centro.identifier
        // });
        if(this.$route.params.modificaPrenotazioneIdentifier){
            PrenotazioneClient.getByCliente(store.state.user.identifier)
            .then(x => {
                this.prevPrenotazione = x.find(c => c.identifier == this.$route.params.modificaPrenotazioneIdentifier);
            })
        }
    }

    next(){
        if(this.prevPrenotazione){
            PrenotazioneClient.spostaPrenotazione({
                ticketPrenotazioneIdentifier: null,
                cliente: null,
                user: null,
                date: this.prenotazioneData.data,
                fasciaOraria: this.prenotazioneData.fascia,
                prenotazioneIdentifier: this.prevPrenotazione.identifier,
                note: ""
            })
            .then(x => {
                ModalServices.alertModal("", "Spostamento prenotazione riuscito!", "OK", () => {
                    EventBus.$emit('reloaddash');
                    this.$opModal.closeAll();
                })
            })
        } else {
            TicketPrenotazioneClient.getAvailableTicketsWithRole(store.state.user.identifier)
            .then(x => {
                if(x.length == 0)
                    return;

                PrenotazioneClient.makePrenotazione({
                    ticketPrenotazioneIdentifier: x[0].identifier,
                    cliente: {
                        identifier: store.state.user.identifier,
                        name: store.state.user.personalData.completeName,
                    },
                    user: {
                        identifier: this.prenotazioneData.centro.identifier,
                        name: this.prenotazioneData.centro.personalData.completeName,
                    },
                    date: this.prenotazioneData.data,
                    fasciaOraria: this.prenotazioneData.fascia,
                    prenotazioneIdentifier: null,
                    note: ""
                })
                .then(x => {
                    ModalServices.alertModal("", "Prenotazione riuscita!", "Avanti", () => {
                        EventBus.$emit('reloaddash');
                        this.$opModal.closeAll();
                    })
                })
            })
        }
    }
    
}
