
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { UploadServices } from '@/services/UploadServices';
import * as VM from '@/viewModel';
import toastr from 'toastr'
import FileDropper from '@/components/fileDropper.vue'
import { PagamentoBonificoClient } from '@/services/Services';
import { PagamentoBonificoServices } from '@/services/PagamentoBonificoServices';

@Options({})
export default class PagamentoBonificoDetail extends Vue {

    pagamento: OM.PagamentoBonifico = null; 
    tipoPagamento: string = "";

    created(){
        this.init();
    }

    init(){
        this.pagamento = null;
        this.tipoPagamento = "";
        PagamentoBonificoClient.getById(this.$route.params.identifier as string)
        .then( x => {
            this.pagamento = x;
            if(!this.pagamento.note)
                this.pagamento.note = "";
        });
    }

    onDeleteComment(item: OM.Comment){
        if(!confirm("Confermi eliminazione commento?"))
            return;
            
        PagamentoBonificoClient.removeCommento(this.pagamento.identifier, item.identifier)
        .then(x => {
            this.init();
        })
    }

    save(){
        PagamentoBonificoClient.save(this.pagamento)
        .then(x => {
            toastr.success("Salvato");
        });
    }

    conferma(){
        if(!this.tipoPagamento)
            return;
        if(!confirm("Approvare pagamento e generare commissioni?"))
            return;

        PagamentoBonificoClient.confermaPagamento(this.$route.params.identifier as string, this.tipoPagamento)
        .then(x => {
            toastr.success("Pagamento confermato e provvigione generata");
            this.init();
        });
    }

    addCommento(vm: VM.AddCommentEvent){
        PagamentoBonificoServices.AddCommento({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.pagamento.identifier
        })
        .then(x => {
            this.pagamento.comments.unshift(x);
            window.toastr.success("Salvato!");
        })
    }

}

