
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import FaqTextModal from './faqTextModal.vue';
import { FaqNodeClient } from '@/services/Services';

@Options({})
export default class FAQ extends Vue {
    
    @Prop() node: OM.FaqNode;

    addAnswer(){
        FaqNodeClient.addAnswerToNode(this.node.identifier)
        .then(x => {
            this.node.answersList.push(x);
        });
    }

    removeAnswer(answer: OM.TextIdentifier, index: number){
        FaqNodeClient.removeAnswerToNode(this.node.identifier, answer.identifier)
        .then(x => {
            this.node.answersList.splice(index, 1);
        });
    }

    openFaqTextModal(){
        this.$opModal.show(FaqTextModal, {
            node: this.node
        })
    }

    navigate(item: OM.TextIdentifier){
        this.$emit('navigate', item);
    }

}

