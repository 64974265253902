

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { ProvvigioniClient } from '@/services/Services';
import { ExportServices } from '../../services/ExportServices';
import OffersModal from '@/components/offersModal.vue';
import { toDisplayString } from 'vue';

@Options({})
export default class ProvvigioniListView extends Vue {

    pagedResult: OM.PagedResultOfProvvigioniListVm = new OM.PagedResultOfProvvigioniListVm();
    vm: OM.ProvvigioniListFilterVm = new OM.ProvvigioniListFilterVm();
    lowerPages: number = 0;
    upperPages: number = 0;
    pages: number[] = [];

    
    tableResult: OM.PagedResultOfProvvigioniListVm = new OM.PagedResultOfProvvigioniListVm();
    filter: OM.ProvvigioniFilterVm = new OM.ProvvigioniFilterVm();

    created(){
        if(this.$route.params.identifier as string)
            this.filter.userIdentifier = this.$route.params.identifier as string;
        this.filter.perPage = 10;
        this.filter.pageNumber = 1;
        setTimeout(this.init, 0);
    }

    init(){
        ProvvigioniClient.getByFilterVm(this.filter)
        .then(x => {
            this.tableResult = x;
        })
    }
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    exportExcel(){
        ProvvigioniClient.exportExcel()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }

    removeItem(item: OM.ProvvigioniListVm){
        if(!confirm("Confermi?"))
            return;

        ProvvigioniClient.deleteById(item.identifier)
        .then(x => {
            window.toastr.success("Rimosso");
            this.init();
        })
    }

    openOffersModal(offers){
        this.$opModal.show(OffersModal, {
            offers
        })
    }

}

