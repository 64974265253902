
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { NazioneClient } from '@/services/Services';

@Options({})
export default class personalData extends Vue {

    @Prop() value: OM.PersonalData;
    @Prop() readonly: boolean;

    nazioni: OM.Nazione[] = [];

    created(){
        NazioneClient.getEnabled()
        .then(x => {
            this.nazioni = x;
        })
    }

}
