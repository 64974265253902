
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import Component, { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class commentsContainer extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop({
        type: Boolean,
        default: true
    }) modify: boolean;

    tempFile: File = null;
    filesToUpload: File[] = [];

    commentText: string = "";

    addComment(){
        if(!this.canSaveComment)
            return;

        let addBody: VM.AddCommentEvent = {
            fileList: this.filesToUpload,
            text: this.commentText,
        }
        this.$emit('add', addBody);
        this.commentText = "";
        this.filesToUpload = [];
    }

    deleteComment(item: OM.Comment){
        if(!confirm("Confermi?"))
            return;

        this.$emit('deleteComment', item);
    }

    onDrop(ev){
        this.tempFile = ev.dataTransfer.files[0];
        this.addFile();
    }

    addFile(){
        this.filesToUpload.push(this.tempFile);
        this.tempFile = null;
    }

    removeFile(item: File){
        let index = this.filesToUpload.findIndex(x => x == item);
        this.filesToUpload.splice(index, 1);
    }
    
    get canSaveComment(){
        return this.commentText || this.filesToUpload.length > 0;
    }

}

