import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pageTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_tree = _resolveComponent("node-tree")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, "Network " + _toDisplayString(_ctx.isCommerciale ? 'commerciale' : 'gestione'), 1),
    (_ctx.firstNode)
      ? (_openBlock(), _createBlock(_component_node_tree, {
          key: 0,
          "root-node": _ctx.firstNode,
          isCommerciale: _ctx.isCommerciale,
          showFilters: true
        }, null, 8, ["root-node", "isCommerciale"]))
      : _createCommentVNode("", true)
  ]))
}