import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/questionariConfigList',
        component: () => import('./questionariConfigList.vue'),
        meta: { title: "Configurazione questionari"},
    },
];

export default routes;