import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue, (item, i) => {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(item.professione.name) + " - ", 1),
        _createElementVNode("span", {
          class: "text-danger pointer",
          onClick: ($event: any) => (_ctx.removeLine(i))
        }, "Elimina riga", 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("select", {
          class: "form-control",
          "onUpdate:modelValue": ($event: any) => ((item.user) = $event)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.dottori as OM.NameIdentifier[]), (item, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: item
            }, _toDisplayString(item.name), 9, _hoisted_5))
          }), 128))
        ], 8, _hoisted_4), [
          [_vModelSelect, item.user]
        ]),
        (item.user)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-danger btn-sm",
              onClick: ($event: any) => (item.user = null)
            }, " x ", 8, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ]))
  }), 256))
}