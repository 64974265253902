
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import Switcher from '@/components/switcher/switcher.vue';
import { OffertaClient } from '@/services/Services';

@Options({})
export default class OfferRequirementsModal extends Vue {

    @Prop() offer: OM.Offerta;

    local: string[] = null;
    offerte: OM.NameIdentifier[] = [];

    created(){
        this.local = [...this.offer.requirements];
        OffertaClient.getAllAsNameIdentifier(true)
        .then(x => this.offerte = x)
    }
    
    annulla(){
        this.$emit('close');
    }

    ok(){
        this.offer.requirements = [...this.local];
        this.$emit('close');
    }

}

