
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { DashboardClient, RicevuteUtenteClient } from '@/services/Services';
import NuovaRicevutaModal from '../nuovaRicevutaModal.vue';
import { ExportServices } from '@/services/ExportServices';

@Options({})
export default class EstrattoConto extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    ricevute: OM.RicevutaUtente[] = [];

    created(){
        this.init();
    }

    init(){
        DashboardClient.getDashRicevute(this.user.identifier).then(x => this.ricevute = x);
    }

    aggiungiRicevuta(){
        this.$opModal.show(NuovaRicevutaModal, {
            userIdentifier: this.user.identifier
        }, () => this.init());
    }
    

    downloadRicevuta(item: OM.RicevutaUtente){
        RicevuteUtenteClient.download(item.identifier)
        .then(x => {
            ExportServices.DownloadPdf(x.identifier, x.name);
        });
    }
    deleteRicevuta(item: OM.RicevutaUtente){
        if(!window.confirm("Confermi la rimozione di " + item.note))
            return;

        RicevuteUtenteClient.delete(item.identifier)
        .then(x => {
            window.toastr.success("Ricevuta rimossa");
            this.init();
        });
    }

}

