
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import appuntamentoModal from './appuntamentoModal.vue'

@Options({})
export default class datiCliente extends Vue {
 
    @Prop() user: OM.Collaboratore;

    prenotazioni: OM.PrenotazioneForListVm[] = [];
    
    filtersFn = {
        fasciaOraria: (prop: OM.FasciaOraria, val) => {
            return prop.from.indexOf(val) > -1 || prop.to.indexOf(val) > -1;
        }
    }
    
    created(){
        this.init();
    }

    init(){
        PrenotazioneClient.getByCliente(this.user.identifier)
        .then(x => {
            this.prenotazioni = x;
        })
    }

}

