
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';

@Options({})
export default class reteData extends Vue {
 
    @Prop() user: OM.BaseUser;

}

