
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class productPianoAlimentare extends Vue {
 
    @Prop() product: OM.ProductPianoAlimentare;

}

