
import * as OM from '@/Model';
import { ConfigurazioneProfessioneClient, QuestionarioClient } from '@/services/Services';
import { JsonClone } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class questionarioInternoModal extends Vue {

    @Prop() cliente: OM.Cliente;
    
    loaded: boolean = false;
    questionario: OM.Questionario = null;
    tempAnswerValue: OM.AnswerValue = new OM.AnswerValue();
    risposteQuestionario: OM.RisposteQuestionario = null;
    currentQuestion: number = 0;
    professioni: OM.NameIdentifier[] = [];
    selectedProfessione: OM.NameIdentifier = null;

    created(){
        ConfigurazioneProfessioneClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
    }

    init(){
        if(!this.selectedProfessione)
            return;

        Promise.all([
            QuestionarioClient.getActiveInterno(this.selectedProfessione.identifier),
            QuestionarioClient.getQuestionarioInternoOfUser(this.cliente.identifier, this.selectedProfessione.identifier)
        ])
        .then(xs => {
            this.questionario = xs[0];
            this.risposteQuestionario = xs[1];

            if(!this.risposteQuestionario){
                this.risposteQuestionario = new OM.RisposteQuestionario();
                this.risposteQuestionario.cliente = {
                    identifier: this.cliente.identifier,
                    name: this.cliente.personalData.completeName,
                }
                this.risposteQuestionario.questionario = {
                    identifier: this.questionario.identifier,
                    name: this.questionario.name
                };
                this.risposteQuestionario.professione = {
                    identifier: this.selectedProfessione.identifier,
                    name: this.selectedProfessione.name
                }
                this.risposteQuestionario.isQuestionarioInterno = true;
            } else {
                this.risposteQuestionario.professione = this.selectedProfessione;
                if(!this.risposteQuestionario.isCompleted){
                    this.currentQuestion = this.risposteQuestionario.answerValues.length - 1;
                }
                this.tempAnswerValue = this.risposteQuestionario.answerValues[this.currentQuestion];
            }
            this.loaded = true;
        })
    }

    @Watch('currentQuestion')
    onCurrentQuestionChange(next, prev){
        this.tempAnswerValue = this.risposteQuestionario.answerValues[this.currentQuestion] || new OM.AnswerValue();
    }

    prev(){
        this.currentQuestion--;
    }

    get canNext(){
        if(!this.currentDomandaConfig.required)
            return true;

        if(this.tempAnswerValue.answer.length == 0 || !this.tempAnswerValue.answer[0]){
            return false;
        }

        return true;
    }

    get currentDomandaConfig(){
        return this.questionario.domande[this.currentQuestion];
    }

    next(){
        if(!this.canNext)
            return;

        this.risposteQuestionario.answerValues[this.currentQuestion] = JsonClone(this.tempAnswerValue);
        this.risposteQuestionario.isCompleted = this.risposteQuestionario.answerValues.length == this.questionario.domande.length;
        QuestionarioClient.saveRisposte(this.risposteQuestionario)
        .then(x => {
            this.risposteQuestionario.identifier = x;
            if(this.currentQuestion == this.questionario.domande.length - 1){
                // ModalServices.alertModal(
                //     '',
                //     LocalizationServices.GetLocalizedValue("Questionario completato!", "Questionario completato!"),
                //     LocalizationServices.GetLocalizedValue("Chiudi", "Chiudi")
                // )
            } else {
                this.currentQuestion++;
            }
        })
    }



}
