import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "midTitle text-center mb-2" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_autocomplete = _resolveComponent("autocomplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "Carica ticket",
        text: "Carica ticket"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Cliente",
          text: "Cliente"
        })
      ]),
      _createVNode(_component_autocomplete, {
        list: _ctx.namesForDropDown,
        "search-callback": _ctx.searchCbNames,
        modelValue: _ctx.selectedName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedName) = $event)),
        "obj-key": 'name'
      }, null, 8, ["list", "search-callback", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Prodotto",
          text: "Prodotto"
        })
      ]),
      _createVNode(_component_autocomplete, {
        list: _ctx.prodsForDropDown,
        "search-callback": _ctx.searchCbProds,
        modelValue: _ctx.selectedProd,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedProd) = $event)),
        "obj-key": 'name'
      }, null, 8, ["list", "search-callback", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Quantità",
          text: "Quantità"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.howMany) = $event))
      }, null, 512), [
        [_vModelText, _ctx.howMany]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-success",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}