import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as VM from '@/viewModel'
import * as OM from '@/Model'

class _LeadServices {

    AddNota(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "Lead/AddNota", vm)
    }
    
    AddCommento(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "Lead/AddCommento", vm)
    }

}

export let LeadServices = new _LeadServices();
