import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flexRow" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "control-label" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  type: "submit",
  class: "btn btn-primary pull-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_input = _resolveComponent("form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nuova Utenza",
          text: "Nuova Utenza"
        })
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addUtente && _ctx.addUtente(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_input, {
          type: "text",
          label: "Email",
          modelValue: _ctx.user.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
          placeholder: "Email"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_form_input, {
          type: "password",
          label: "Password",
          modelValue: _ctx.user.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.password) = $event)),
          modelModifiers: { number: true },
          placeholder: "Password"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_form_input, {
          type: "text",
          label: "Username",
          modelValue: _ctx.user.username,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.username) = $event)),
          placeholder: "Username"
        }, null, 8, ["modelValue"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-group",
            key: i
          }, [
            _createElementVNode("label", _hoisted_3, [
              _createTextVNode(_toDisplayString(item.label) + " ", 1),
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.appModules) = $event)),
                value: item.id
              }, null, 8, _hoisted_4), [
                [_vModelCheckbox, _ctx.user.appModules]
              ])
            ])
          ]))
        }), 128)),
        _createElementVNode("button", _hoisted_5, [
          _createVNode(_component_localized_text, {
            localizedKey: "Aggiungi utenza",
            text: "Aggiungi utenza"
          })
        ])
      ], 32)
    ])
  ]))
}