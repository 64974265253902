
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { LeadClient } from '@/services/Services';
import SelectUserModal from '../components/selectUserModal.vue';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class inAccettazione extends Vue {

    response: OM.LeadInAccettazioneResponseVm = new OM.LeadInAccettazioneResponseVm();
    filter: OM.LeadInAccettazioneRequestParam = new OM.LeadInAccettazioneRequestParam();

    selectedLeads: string[] = [];

    created(){
        this.filter.pageNumber = 1;
        this.filter.perPage = 25;
        this.filter.sortBy = null;
        this.init();
    }

    @Watch('showAccepted')
    onShowAcceptedChange(next, prev){
        this.init();
    }

    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    init(){
        LeadClient.getLeadInAccettazione(this.filter)
        .then(x => {
            this.response = x;
        });
    }

    checkall(items: OM.LeadInAccettazioneVm[]){
        if(this.selectedLeads.length > 0){
            this.selectedLeads.splice(0);
        } else {
            items.forEach(x => {
                let ind = this.selectedLeads.indexOf(x.identifier);
                if(ind == -1)
                    this.selectedLeads.push(x.identifier);
            })
        }
    }

    acceptSelected(){
        if(this.selectedLeads.length == 0)
            return;
        if(!confirm("Confermi di prendere i lead selezionati in gestione?"))
            return;

        LeadClient.accettaGestioneMultiple(this.selectedLeads)
        .then(x => {
            window.toastr.success("Accettati!");
            this.init();
        })
    }

    accept(item: OM.LeadInAccettazioneVm){
        if(!confirm("Confermi di prendere il lead in gestione?"))
            return;

        LeadClient.accettaGestioneMultiple([item.identifier])
        .then(x => {
            window.toastr.success("Accettato!");
            this.init();
        })
    }

    reject(item: OM.LeadInAccettazioneVm){
        if(!confirm("Vuoi rifiutare il lead?"))
            return;

        LeadClient.denyGestioneMultiple([item.identifier])
        .then(x => {
            window.toastr.success("Rifiutato!");
            this.init();
        })
    }

    assignSelected(){
        if(this.selectedLeads.length == 0)
            return;

        this.$opModal.show(SelectUserModal, {
            saveCb: (user: OM.NameIdentifier, treeType: string) => {
                if(!confirm("Confermi assegnazione a " + user.name + "?")){
                    return;
                }

                LeadClient.assignGestioneMultipleToUser({
                    leadList: this.selectedLeads,
                    user: user.identifier,
                    list: '',
                    treeType: treeType
                })
                .then(x => {
                    window.toastr.success("Assegnati!");
                    this.init();
                })
            }
        })  
    }

    assignToNone(){
        if(this.selectedLeads.length == 0)
            return;

        if(!confirm("Confermi assegnazione a nessuno?")){
            return;
        }

        LeadClient.assignToNoneMultiple(this.selectedLeads)
        .then(x => {
            window.toastr.success("Assegnati a nessuno");
            this.init();
        })
    }

    getRitardoClass(item: OM.LeadInAccettazioneVm){
        if(!item.assignedOn || item.acceptedOn)
            return;

        let dataAssegnazione = moment(item.assignedOn);
        let dataOggi = moment(new Date());
        let oreTrascorse = dataOggi.diff(dataAssegnazione, 'hours');
        if(oreTrascorse >= 48){
            return "rosso";
        } else if(oreTrascorse >= 36){
            return "arancione";
        } else if(oreTrascorse >= 24){
            return "giallo";
        }

    }

}

