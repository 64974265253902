import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createVNode(_component_localized_text, {
        localizedKey: _ctx.label,
        text: _ctx.label
      }, null, 8, ["localizedKey", "text"])
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-sm btn-success ml-2",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
    }, " + "),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "",
        key: i
      }, [
        _createTextVNode(_toDisplayString(i + 1) + ". ", 1),
        _withDirectives(_createElementVNode("input", {
          class: "mr-2",
          type: "text",
          "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[i]) = $event)
        }, null, 8, _hoisted_2), [
          [_vModelText, _ctx.modelValue[i]]
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-sm btn-danger ml-2",
          onClick: ($event: any) => (_ctx.remove(i))
        }, " - ", 8, _hoisted_3)
      ]))
    }), 128))
  ]))
}