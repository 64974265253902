
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import Switcher from '@/components/switcher/switcher.vue';
import { BaseProductClient } from '@/services/Services';

@Options({})
export default class ProductsModal extends Vue {

    @Prop() offer: OM.Offerta;

    local: string[] = [];
    prodotti: OM.NameIdentifier[] = [];

    created(){
        this.local = [...this.offer.products];
        BaseProductClient.getAllNameIdentifier()
        .then(x => this.prodotti = x)
    }
    
    annulla(){
        this.$emit('close');
    }

    ok(){
        this.offer.products = [...this.local];
        this.$emit('close');
    }

}

