import { RouteComponent, RouteRecordRaw } from 'vue-router';
import Layout from './Layout.vue';
import gestioneLead from '@/views/gestioneLead/routes';
import cliente from '@/views/cliente/routes';
import helpTicket from '@/views/helpTicket/routes';
import utenze from '@/views/utenze/routes';
import chat from '@/views/chatRoom/routes';
import comeFunziona from '@/views/htmlTextConfig/routes';
import network from '@/views/network/routes';
import vendite from '@/views/vendite/routes';
import provvigioni from '@/views/provvigioni/routes';
import dashboardUtente from '@/views/dashboard/routes';
import sendPushToAll from '@/views/pushToAll/routes';
import nazioni from '@/views/nazioni/routes';
import acquisti from '@/views/acquisti/routes';
import shopConfig from '@/views/shopConfig/routes';
import shop from '@/views/shop/routes';
import faq from '@/views/faqNode/routes';
import pagamentoBonifico from'@/views/pagamentoBonifico/routes';
import messageOfTheDay from'@/views/messageOfTheDay/routes';
import contrattiCollaborazione from'@/views/contrattiCollaborazione/routes';
import prenotazioni from'@/views/prenotazioni/routes';
import mailSentLog from'@/views/mailSentLog/routes';
import appConfig from'@/views/appConfig/routes';
import ticketPrenotazione from '@/views/ticketPrenotazione/routes';
import dictionary from '../dictionary';
import assistenza from '../assistenza';
import questionari from '@/views/questionari';
import pianoAlimentare from '@/views/pianoAlimentare';
import ricettarioNode from '../ricettarioNode';
import videoDellaSettimana from '../videoDellaSettimana';
import newsFeed from '../newsFeed';
import configurazioneProfessioni from '../configurazioneProfessioni';

let children: RouteRecordRaw[] = [];
let layoutRoutes: RouteRecordRaw[] = [
    {
        path: '/',
        component: Layout,
        children: children
            .concat(gestioneLead)
            .concat(cliente)
            .concat(helpTicket)
            .concat(utenze)
            .concat(chat)
            .concat(comeFunziona)
            .concat(network)
            .concat(vendite)
            .concat(provvigioni)
            .concat(dashboardUtente)
            .concat(sendPushToAll)
            .concat(nazioni)
            .concat(shopConfig)
            .concat(shop)
            .concat(acquisti)
            .concat(faq)
            .concat(questionari.routes)
            .concat(pagamentoBonifico)
            .concat(messageOfTheDay)
            .concat(contrattiCollaborazione)
            .concat(prenotazioni)
            .concat(mailSentLog)
            .concat(appConfig)
            .concat(ticketPrenotazione)
            .concat(dictionary.routes())
            .concat(assistenza.routes())
            .concat(pianoAlimentare.routes)
            .concat(ricettarioNode.routes)
            .concat(videoDellaSettimana.routes())
            .concat(newsFeed.routes())
            .concat(configurazioneProfessioni.routes())
    }
];

export default layoutRoutes;