import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "box" }
const _hoisted_6 = { class: "boxTitle" }
const _hoisted_7 = { class: "col-sm-6" }
const _hoisted_8 = { class: "box" }
const _hoisted_9 = { class: "boxTitle" }
const _hoisted_10 = { class: "form-group text-center" }
const _hoisted_11 = ["readonly"]
const _hoisted_12 = { class: "text-center mt-4" }
const _hoisted_13 = {
  key: 1,
  class: "text-center mt-3"
}
const _hoisted_14 = { class: "text-danger fs-22" }
const _hoisted_15 = {
  key: 0,
  class: "text-center mt-3"
}
const _hoisted_16 = {
  key: 1,
  class: "text-center mt-3"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { value: "Bonifico" }
const _hoisted_19 = { value: "Stripe" }
const _hoisted_20 = { value: "Paypal" }
const _hoisted_21 = { value: "Postepay" }
const _hoisted_22 = { value: "Alma" }
const _hoisted_23 = { value: "Contanti" }
const _hoisted_24 = { value: "HeidiPay" }
const _hoisted_25 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_comments_container = _resolveComponent("comments-container")!

  return (_ctx.pagamento)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Pagamento con bonifico",
            text: "Pagamento con bonifico"
          })
        ]),
        _createElementVNode("h5", null, [
          _createVNode(_component_router_link, {
            to: '/lead/detail/' + _ctx.pagamento.cliente.identifier
          }, {
            default: _withCtx(() => [
              _createVNode(_component_localized_text, {
                localizedKey: "Cliente",
                text: "Cliente"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.pagamento.cliente.name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        (_ctx.pagamento.prodotto)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Prodotto",
                text: "Prodotto"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.pagamento.prodotto.name), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("h5", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Importo",
            text: "Importo"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.pagamento.importo) + " " + _toDisplayString(_ctx.pagamento.valuta), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Commenti e allegati",
                  text: "Commenti e allegati"
                })
              ]),
              _createVNode(_component_comments_container, {
                comments: _ctx.pagamento.comments,
                onAdd: _ctx.addCommento,
                onDeleteComment: _ctx.onDeleteComment
              }, null, 8, ["comments", "onAdd", "onDeleteComment"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Note",
                  text: "Note"
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Note",
                    text: "Note"
                  })
                ]),
                _withDirectives(_createElementVNode("textarea", {
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagamento.note) = $event)),
                  placeholder: "Note...",
                  readonly: _ctx.pagamento.confirmedOn && !_ctx.$store.state.isAdmin()
                }, null, 8, _hoisted_11), [
                  [_vModelText, _ctx.pagamento.note]
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-success",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Salva",
              text: "Salva"
            })
          ])
        ]),
        (_ctx.pagamento.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_localized_text, {
                  localizedKey: "PAGAMENTO ANNULLATO IL",
                  text: "PAGAMENTO ANNULLATO IL"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$filters.date(_ctx.pagamento.disabledOn, "DD MM YYYY HH:mm")), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.pagamento.confirmedOn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("h4", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "PAGAMENTO APPROVATO",
                        text: "PAGAMENTO APPROVATO"
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.pagamento.confirmedOn && _ctx.$store.state.isAdmin())
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", null, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Tipo Pagamento",
                          text: "Tipo Pagamento"
                        })
                      ]),
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tipoPagamento) = $event)),
                        class: "form-control"
                      }, [
                        _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Bonifico", "Bonifico")), 1),
                        _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Stripe", "Stripe")), 1),
                        _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Paypal", "Paypal")), 1),
                        _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Postepay", "Postepay")), 1),
                        _createElementVNode("option", _hoisted_22, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Alma", "Alma")), 1),
                        _createElementVNode("option", _hoisted_23, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Contanti", "Contanti")), 1),
                        _createElementVNode("option", _hoisted_24, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("HeidiPay", "HeidiPay")), 1)
                      ], 512), [
                        [_vModelSelect, _ctx.tipoPagamento]
                      ])
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-success",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.conferma && _ctx.conferma(...args))),
                      disabled: !_ctx.tipoPagamento
                    }, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Approva",
                        text: "Approva"
                      })
                    ], 8, _hoisted_25)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ]))
    : _createCommentVNode("", true)
}