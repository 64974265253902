
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class polizzaData extends Vue {

    @Prop() value: OM.PolizzaData;

}
