
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class FormDisplay extends Vue {
    @Prop({
        type: [String]
    }) label: string;
}
