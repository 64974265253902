import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16f70cba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nazioni, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flagItem pointer",
        key: i,
        onClick: ($event: any) => (_ctx.select(item))
      }, [
        _createElementVNode("img", {
          src: 'img/countries/' + item.alpha3Code.toUpperCase() + '.svg'
        }, null, 8, _hoisted_3),
        _createTextVNode(" +" + _toDisplayString(item.callingCode), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}