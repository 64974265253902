

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { TicketPrenotazioneClient } from '@/services/Services';
import caricaTicketModal from './caricaTicketModal.vue';

@Options({})
export default class ticketPrenotazione extends Vue {

    tableResult: OM.PagedResultOfTicketPrenotazione = new OM.PagedResultOfTicketPrenotazione();
    filter: OM.TicketPrenotazioneListFilterVm = new OM.TicketPrenotazioneListFilterVm();
    

    created(){
        this.filter.perPage = 10;
        this.filter.pageNumber = 1;
        setTimeout(this.init, 0);
    }

    init(){
        TicketPrenotazioneClient.getAll(this.filter)
        .then(x => {
            this.tableResult = x;
        })
    }
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    caricaTicketModal(){
        this.$opModal.show(caricaTicketModal, {}, () => {
            this.init()
        })
    }

    remove(item: OM.TicketPrenotazione){
        if(!confirm("Confermi la rimozione?"))
            return;

        TicketPrenotazioneClient.deleteById(item.identifier)
        .then(x => {
            window.toastr.success("Rimosso");
            this.init();
        })
    }

}
