
import * as OM from '@/Model';
import * as ENUM from '@/enum';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr';
import { NazioneClient, ShopCategoryClient } from '@/services/Services';

@Options({})
export default class CategoryDetail extends Vue {
 
    category: OM.ShopCategory = null;
    shopCategories: OM.NameIdentifier[] = [];
    categoryTypes: string[] = Enum.ProductType.GetAll();
    naziones: OM.Nazione[] = [];
    roles: string[] = ENUM.UserRoles.GetAll();

    created(){
        NazioneClient.getEnabled()
        .then(x => this.naziones = x)
        
        if(this.$route.params.identifier as string == '0'){
            this.category = new OM.ShopCategory();
        } else {
            ShopCategoryClient.getById(this.$route.params.identifier as string)
            .then(x => {
                this.category = x;
            })
        }
    }

    save(){
        ShopCategoryClient.save(this.category)
        .then(x => {
            toastr.success("Salvato");
            if(this.$route.params.identifier as string == '0'){
                this.category.identifier = x;
                this.$router.replace('/shopConfig/category/' + x);
            }
        })
        .catch(err => {
            if(err == 'upsell_category_already_set'){
                window.toastr.error("C'è già un'altra categoria impostata come categoria di upsell");
            }
        })
    }

    
    get filteredNaziones(){
        return this.naziones.filter(x => !this.category.nazioneFilter.some(c => c == x.alpha2Code));
    }
    get filteredRoles(){
        return this.roles.filter(x => !this.category.rolesFilter.some(c => c == x));
    }
    tempNazione: OM.Nazione = null;
    removeNazione(item: string, index: number){
        this.category.nazioneFilter.splice(index, 1);
    }
    selectNazione(){
        this.category.nazioneFilter.push(this.tempNazione.alpha2Code);
        this.tempNazione = null;
    }
    tempRole: string = null;
    removeRole(item: string, index: number){
        this.category.rolesFilter.splice(index, 1);
    }
    selectRole(){
        this.category.rolesFilter.push(this.tempRole);
        this.tempRole = null;
    }

}

