
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import appuntamentoCentroFumo from './centroFumo/appuntamentoCentroFumo.vue';
import appuntamentoVideoCall from './videoCall/appuntamentoVideoCall.vue';
import { TicketPrenotazioneClient } from '@/services/Services';

@Options({
    components: {
        appuntamentoCentroFumo,
        appuntamentoVideoCall
    }
})
export default class prenotaAppuntamentoModal extends Vue {

    @Prop() ticketPrenotazione: OM.TicketPrenotazioneWithRoleVm;
    @Prop() lead: OM.Lead;

    created(){
    }
    
}

