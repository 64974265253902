
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import { ContrattoCollaborazioneClient } from '@/services/Services';
import { ContrattoCollaborazioneServices } from '@/services/ContrattoCollaborazioneServices';
import CommentiModal from './commentiModal.vue';
import { ModalServices } from '@/services/ModalServices';

@Options({})
export default class contrattiCollaborazione extends Vue {

    contratti: OM.ContrattoCollaborazione[] = [];

    created(){
        this.init()
    }

    init(){
        this.contratti = [];
        ContrattoCollaborazioneClient.getAll()
        .then(x => {
            this.contratti = x;
        })
    }

    approve(item: OM.ContrattoCollaborazione){
        if(!confirm("Confermi? Il cliente diventerà collaboratore"))
            return;

        ContrattoCollaborazioneClient.approvaContratto(item.identifier)
        .then(x => {
            window.toastr.success("Approvato");
            this.init();
        })
    }

    respingi(item: OM.ContrattoCollaborazione){
        ModalServices.inputModal("Inserisci la motivazione della respinta", "Verrà inviata nell'email che arriva all'utente", "Conferma respinta", "Annulla", null, null, (value) => {
            ContrattoCollaborazioneClient.respingiContratto(
            {   
                identifier: item.identifier,
                text: value,
                order: 0,
            })
            .then(x => {
                window.toastr.success("Annullato");
                this.init();
            })
        })
    }

    commenti(item: OM.ContrattoCollaborazione){
        this.$opModal.show(CommentiModal, {
            comments: item.comments,
            attachments: item.attachments,
            addCallback: (vm: VM.AddCommentEvent) => {
                this.addCommento(vm, item);
            } 
        })
    }
    
    addCommento(vm: VM.AddCommentEvent, item: OM.ContrattoCollaborazione){
        ContrattoCollaborazioneServices.AddCommento({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: item.identifier
        })
        .then(x => {
            item.comments.unshift(x);
            window.toastr.success("Salvato!");
        })
    }

}
