
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { CollaboratoreClient } from '@/services/Services';
import store from '@/store';
import calendarModal from '../calendarModal.vue';

@Options({})
export default class appuntamentoVideoCall extends Vue {

    dottori: OM.NameIdentifier[] = [];

    created(){
        CollaboratoreClient.getListDottori()
        .then(x => {
            this.dottori = x;
        })
    }
    
    select(item: OM.NameIdentifier){
        this.$opModal.show(calendarModal, {
            identifier: item.identifier
        })
    }
    
}

