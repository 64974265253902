import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h5", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Configurazione richieste pagamento",
        text: "Configurazione richieste pagamento"
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Giorni in cui si può richiedere il pagamento",
        text: "Giorni in cui si può richiedere il pagamento"
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Minimo",
              text: "Minimo"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.dayFrom) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localValue.dayFrom]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Massimo",
              text: "Massimo"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.dayTo) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localValue.dayTo]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Importo minimo richiedibile",
          text: "Importo minimo richiedibile"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.minimumPayment) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.minimumPayment]
      ])
    ])
  ]))
}