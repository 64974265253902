import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "text-right" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_autocomplete = _resolveComponent("autocomplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "Nuovo pagamento con bonifico",
        text: "Nuovo pagamento con bonifico"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Seleziona il cliente",
          text: "Seleziona il cliente"
        })
      ]),
      _createVNode(_component_autocomplete, {
        list: _ctx.customers,
        "search-callback": _ctx.searchCb,
        modelValue: _ctx.selectedCliente,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCliente) = $event)),
        "obj-key": 'name'
      }, null, 8, ["list", "search-callback", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Seleziona l'offerta",
          text: "Seleziona l'offerta"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOffer) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offers, (item, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: item
          }, _toDisplayString(item.name), 9, _hoisted_5))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.selectedOffer]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        disabled: !_ctx.canSave,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_7)
    ])
  ]))
}