import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fs-22 text-center mb-2" }
const _hoisted_2 = {
  filter: "alimento",
  sort: "alimento"
}
const _hoisted_3 = {
  filter: "quantita",
  sort: "quantita"
}
const _hoisted_4 = {
  key: 0,
  class: "align-bottom text-right"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { colspan: "99" }
const _hoisted_7 = {
  key: 0,
  class: "form-control"
}
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = {
  key: 0,
  class: "form-control"
}
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = {
  key: 0,
  class: "text-right"
}
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "Lista della spesa",
        text: "Lista della spesa"
      })
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.items
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Alimento",
                text: "Alimento"
              })
            ]),
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_localized_text, {
                localizedKey: "Quantità",
                text: "Quantità"
              })
            ]),
            (_ctx.$store.state.isDottore() || _ctx.$store.state.isAdmin())
              ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-success",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addItem && _ctx.addItem(...args)))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Aggiungi",
                      text: "Aggiungi"
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                _createElementVNode("td", _hoisted_6, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.SpesaItem[]), (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                (_ctx.readonly)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.alimento), 1))
                  : _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": ($event: any) => ((item.alimento) = $event)
                    }, null, 8, _hoisted_8)), [
                      [_vModelText, item.alimento]
                    ])
              ]),
              _createElementVNode("td", null, [
                (_ctx.readonly)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.quantita), 1))
                  : _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      type: "text",
                      class: "form-control",
                      "onUpdate:modelValue": ($event: any) => ((item.quantita) = $event)
                    }, null, 8, _hoisted_10)), [
                      [_vModelText, item.quantita]
                    ])
              ]),
              (_ctx.$store.state.isDottore() || _ctx.$store.state.isAdmin())
                ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                    (!_ctx.readonly)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn btn-sm btn-danger",
                          onClick: ($event: any) => (_ctx.removeItem(i))
                        }, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Rimuovi",
                            text: "Rimuovi"
                          })
                        ], 8, _hoisted_12))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}