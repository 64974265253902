
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class PushToAll extends Vue {

    title: string = "";
    text: string = "";

    send(){
        if(!confirm("inviare la notifica a tutti?"))
            return;

        // ClienteClient.sendPushToAll(this.title, this.title)
        // .then(x => {
        //     toastr.success("Inviata con successo")
        // })
    }

}
