import { RouteRecordRaw } from 'vue-router';
import PushToAll from './pushToAll.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/pushToAll',
        component: PushToAll,
        meta: { title: "Invio push massivo"},
    },
];

export default routes;