import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f68ff2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "modalFooter" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_make_prenotazione_calendar = _resolveComponent("make-prenotazione-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_localized_text, {
          localizedKey: "Sposta prenotazione",
          text: "Sposta prenotazione"
        })
      ])
    ]),
    _createVNode(_component_make_prenotazione_calendar, {
      "user-identifier": _ctx.userIdentifier,
      value: _ctx.vm
    }, null, 8, ["user-identifier", "value"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        disabled: !_ctx.vm.fasciaOraria.from || !_ctx.vm.date,
        class: "btn btn-block btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sposta && _ctx.sposta(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_5)
    ])
  ]))
}