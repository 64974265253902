import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/pianoAlimentareList',
        name: 'pianoAlimentareList',
        component: () => import('./pianoAlimentareList.vue'),
    },
    {
        path: '/pianoAlimentareDetail/:identifier/:clienteIdentifier?/:dottoreIdentifier?',
        name: 'pianoAlimentareDetail',
        component: () => import('./pianoAlimentareDetail.vue'),
    },
    {
        path: '/ticketPianoAlimentare/:clienteIdentifier',
        name: 'ticketPianoAlimentare',
        component: () => import('./ticketPianoAlimentare.vue'),
    },
];

export default routes;