
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import OrariPrenotazioneModal from '@/views/utenze/orariPrenotazioneModal.vue';
import { PrenotazioneClient } from '@/services/Services';
import SpostaPrenotazioneModal from './spostaPrenotazioneModal.vue';

@Options({})
export default class AppuntamentiNutrizionista extends Vue {

    @Prop() userIdentifier: string;
    @Prop() reload;
    
    aptNutrizionista: OM.PrenotazioneForListVm[] = [];

    filtersFn = {
        fasciaOraria: (prop: OM.FasciaOraria, val) => {
            return prop.from.indexOf(val) > -1 || prop.to.indexOf(val) > -1;
        }
    }

    created(){
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }

    init(){
        PrenotazioneClient.getByCentroFumo(this.userIdentifier)
        .then(x => {
            this.aptNutrizionista = x;
        })
    }
    openModalOrari(){
        this.$opModal.show(OrariPrenotazioneModal, {
            userIdentifier: this.userIdentifier
        })
    }
    
    spostaPrenotazione(prenoId: string){
        this.$opModal.show(SpostaPrenotazioneModal, {
            prenotazioneIdentifier: prenoId,
            userIdentifier: this.userIdentifier
        })
    }
    
    respingiPrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.respingiPrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione respinta");
            this.init();
        })
    }
    
    deletePrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.deletePrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione eliminata");
            this.init();
        })
    }
    
    // concludiPrenotazione(prenoId: string){
    //     if(!confirm("Confermi lo svolgimento dell'appuntamento?"))
    //         return;

    //     PrenotazioneClient.concludiPrenotazione(prenoId)
    //     .then(x => {
    //         window.toastr.success("Prenotazione conclusa");
    //         this.init();
    //     })
    // }

}

