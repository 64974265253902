import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "midTitle" }
const _hoisted_2 = { class: "mapContainer mt-4" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        localizedKey: "Seleziona il professionista",
        text: "Seleziona il professionista"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dottori, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "listItem",
            key: i,
            onClick: ($event: any) => (_ctx.select(item))
          }, _toDisplayString(item.name), 9, _hoisted_4))
        }), 128))
      ])
    ])
  ], 64))
}