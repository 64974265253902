import ProvvigioniList from './ProvvigioniList.vue';
import ProvvigioniConfig from './ProvvigioniConfig.vue';
import ProvvigioniUtente from './ProvvigioniUtente.vue';
import Simulatore from './simulatore.vue';
import { RouteRecordRaw } from 'vue-router';


let routes: RouteRecordRaw[] = [
    {
        path: '/provvigioni',
        component: ProvvigioniList,
        meta: { title: "Provvigioni"},
    },
    {
        path: '/provvigioni/config',
        component: ProvvigioniConfig,
        meta: { title: "Provvigioni"},
    },
    {
        path: '/provvigioni/detail/:identifier',
        component: ProvvigioniUtente,
        meta: { title: "Provvigioni utente"},
    },
    {
        path: '/provvigioni/simulatore',
        component: Simulatore,
        meta: { title: "Simulatore"},
    }
];

export default routes;