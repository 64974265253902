import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as VM from '@/viewModel'
import * as OM from '@/Model'

class _ContrattoCollaborazioneServices {

    AddCommento(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "ContrattoCollaborazione/AddCommento", vm)
    }

    AddAllegato(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(baseUrl + "ContrattoCollaborazione/AddAllegato", vm)
    }

}

export let ContrattoCollaborazioneServices = new _ContrattoCollaborazioneServices();
