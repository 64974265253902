
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import * as Enum from '@/enum'
import { Options, Vue } from 'vue-class-component';
import { ExportServices } from '@/services/ExportServices';
import { ProvvigioniClient } from '@/services/Services';

@Options({})
export default class reportFineMeseModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() username: string;

    mese: number = 0;
    anno: number = 0;
    
    created(){
        this.mese = new Date().getMonth() + 1;
        this.anno = new Date().getFullYear();
    }

    ok(){
        ProvvigioniClient.reportFineMese(this.userIdentifier, this.mese, this.anno)
        .then(x => {
            ExportServices.DownloadPdf(x.identifier, x.name);
        })
    }

}
