
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { QualificaProvvigioniConfigClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class datiCollaboratore extends Vue {
 
    @Prop() user: OM.UpdateCollaboratoreVm;

    qualifiche: OM.QualificaProvvigioniConfig[] = [];

    created(){
        QualificaProvvigioniConfigClient.getAll()
        .then(x => {
            this.qualifiche = x;
            this.qualificaConfig = this.qualifiche.find(x => x.codiceQualifica == this.user.pianoProvvigioni.qualifica.codiceQualifica);
        })
    }

    qualificaConfig: OM.QualificaProvvigioniConfig = null;
    get qualifica(){
        return this.qualificaConfig;
    }
    set qualifica(value: OM.QualificaProvvigioniConfig){
        this.user.pianoProvvigioni = new OM.PianoProvvigioni();
        if(!value){
            this.qualificaConfig = null;
            return;
        }
        this.qualificaConfig = value;
        this.user.pianoProvvigioni.qualifica.codiceQualifica = value.codiceQualifica;
        this.user.pianoProvvigioni.qualifica.puntiTotaliNecessari = value.puntiTotaliNecessari;
        this.user.pianoProvvigioni.qualifica.euroPerPunto = value.euroPerPunto;
    }

    addDottoreData(){
        let toPush = new OM.DottoreData();
        this.user.dottoreDatas.push(toPush);
    }

    removeDottoreData(i: number){
        this.user.dottoreDatas.splice(i, 1);
    }

}

