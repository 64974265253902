import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/professioniConfig',
            name: 'professioniConfig',
            component: () => import("./views/professioniConfig.vue")
        },
        {
            path: '/professioniConfig/:identifier',
            name: 'professioniConfigDetail',
            component: () => import("./views/professioniConfigDetail.vue")
        },
    ];
};