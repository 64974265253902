

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, DashboardClient } from '@/services/Services';
import { ExportServices } from '../../services/ExportServices';
import reportFineMeseModal from './reportFineMeseModal.vue';

@Options({})
export default class collaboratori extends Vue {

    listaUtenze: OM.CollaboratoreListVm[] = [];
    
    created(){ this.init() }
    init(){
        CollaboratoreClient.getAll()
        .then( x => {
            this.listaUtenze = x;
        });
    }

    filtersFn = {
        // name: (item: OM.CollaboratoreListVm, val) => {
        //     return item.name.indexOf(val) > -1;
        // },
        reteGestioneParent: (item: OM.CollaboratoreListVm, val) => {
            return item.reteGestioneParent.name.indexOf(val) > -1;
        },
        reteCommercialeParent: (item: OM.CollaboratoreListVm, val) => {
            return item.reteCommercialeParent.name.indexOf(val) > -1;
        },
    }

    removeItem(item){
        if(!confirm("Sicuro?"))
            return;

        CollaboratoreClient.delete(item.identifier)
        .then(x => {
            this.init();
            window.toastr.success("Rimosso");
        });
    }

    exportExcel(){
        CollaboratoreClient.exportExcel()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }
    exportDashboard(){
        DashboardClient.exportDashboards()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }
    exportDashboardMensili(){
        DashboardClient.exportDashboardMensile()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });;
    }
    reportFineMese(item: OM.CollaboratoreListVm){
        this.$opModal.show(reportFineMeseModal, {
            userIdentifier: item.identifier,
            username: item.name,
        })
    }
}

