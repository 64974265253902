import { RouteRecordRaw } from 'vue-router';
import Network from './Network.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/network/commerciale',
        component: Network,
        name: 'networkCommerciale',
        meta: { title: "Network", routeBuster: true },
    },
    {
        path: '/network/gestione',
        component: Network,
        name: 'networkGestione',
        meta: { title: "Network", routeBuster: true },
    }
];

export default routes;