import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/assistenza',
            name: 'assistenza',
            component: () => import("./views/assistenza.vue"),
            meta: {
                hasBar: true
            }
        },
        {
            path: '/assistenza/:identifier',
            name: 'assistenzaDetail',
            component: () => import("@/views/chatRoom/ChatPage.vue"),
        },
        {
            path: '/assistenza/new/:subject?',
            name: 'assistenzaNew',
            component: () => import("./views/assistenzaNew.vue"),
        },
    ];
};