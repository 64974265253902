import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54d6ebbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "_modal",
  style: {"width":"50%"}
}
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "modalBody" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { value: "" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "modalFooter text-right" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_input = _resolveComponent("form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nuova ricevuta",
          text: "Nuova ricevuta"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_form_input, {
        type: "text",
        label: "Numero",
        modelValue: _ctx.ricevuta.numeroRicevuta,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ricevuta.numeroRicevuta) = $event)),
        placeholder: "Numero"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_input, {
        type: "number",
        label: "Importo",
        modelValue: _ctx.ricevuta.importo,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ricevuta.importo) = $event)),
        placeholder: "Importo"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_input, {
        label: "Data Acquisto",
        modelValue: _ctx.ricevuta.data,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ricevuta.data) = $event)),
        type: "date"
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Nazione",
            text: "Nazione"
          })
        ]),
        _withDirectives(_createElementVNode("select", {
          class: "form-control",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ricevuta.nazione) = $event))
        }, [
          _createElementVNode("option", _hoisted_5, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Nazione", "Nazione")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nazioni, (item, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: item
            }, _toDisplayString(item), 9, _hoisted_6))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.ricevuta.nazione]
        ])
      ]),
      _createVNode(_component_form_input, {
        label: "Note",
        modelValue: _ctx.ricevuta.note,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ricevuta.note) = $event)),
        type: "text"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-success mr-2",
        disabled: !_ctx.ricevuta.importo,
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.insertRicevuta && _ctx.insertRicevuta(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva ricevuta",
          text: "Salva ricevuta"
        })
      ], 8, _hoisted_8),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-danger",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Annulla",
          text: "Annulla"
        })
      ])
    ])
  ]))
}