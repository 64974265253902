import AllProducts from './products/allProducts.vue';
import ProductDetail from './products/productDetail.vue';
import AllOffers from './offers/allOffers.vue';
import OfferDetail from './offers/offerDetail.vue';
import AllCategories from './categories/allCategories.vue';
import CategoryDetail from './categories/categoryDetail.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/shopConfig/allProducts',
        component: AllProducts,
        meta: { title: "Tutti i prodotti"},
    },
    {
        path: '/shopConfig/product/:identifier',
        component: ProductDetail,
        meta: { title: "Dettaglio prodotto"},
    },
    {
        path: '/shopConfig/offers',
        component: AllOffers,
        meta: { title: "Tutte le offerte"},
    },
    {
        path: '/shopConfig/offers/:categoryIdentifier',
        component: AllOffers,
        meta: { title: "Offerte per categoria"},
    },
    {
        path: '/shopConfig/offer/:identifier',
        component: OfferDetail,
        meta: { title: "Dettaglio offerta"},
    },
    {
        path: '/shopConfig/categories',
        component: AllCategories,
        meta: { title: "Tutte le categorie"},
    },
    {
        path: '/shopConfig/category/:identifier',
        component: CategoryDetail,
        meta: { title: "Dettaglio categoria"},
    },
];

export default routes;