

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import { CollaboratoreClient, NazioneClient } from '@/services/Services';

@Options({})
export default class Nazioni extends Vue {

    search: string = "";
    allNazioni: OM.Nazione[] = [];
    users: OM.NameIdentifier[] = [];
    soloAbilitati: boolean = false;

    created(){
        NazioneClient.getAll()
        .then(x => {
            this.allNazioni = x;
        })
        CollaboratoreClient.getManagerNameIdentifier()
        .then(x => {
            this.users = x;
        })
    }

    toggleNazione(item: OM.Nazione){
        item.disabled = !item.disabled;
        this.save(item);
    }

    save(item){
        NazioneClient.save(item)
        .then(x => {
            toastr.success("Salvato");
        })
    }

    get filtered(){
        return  this.allNazioni.filter(x => {
            let ris = false;
            if(this.soloAbilitati)
                ris = !x.disabled;
            else
                ris = true;

            return ris && x.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        });
    }

}
