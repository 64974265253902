import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  height: "320",
  width: "320",
  controls: "",
  playsinline: ""
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("video", _hoisted_2, [
          (_ctx.item.url)
            ? (_openBlock(), _createElementBlock("source", {
                key: 0,
                src: _ctx.item.url
              }, null, 8, _hoisted_3))
            : (_ctx.item.attachment && _ctx.item.attachment.publicUrl)
              ? (_openBlock(), _createElementBlock("source", {
                  key: 1,
                  src: _ctx.item.url
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}