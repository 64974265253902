
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class spesaItemTable extends Vue {

    @Prop() items: OM.SpesaItem[];
    @Prop() readonly: boolean;

    addItem(){
        let toPush = new OM.SpesaItem();
        this.items.push(toPush);
    }

    removeItem(index: number){
        this.items.splice(index, 1);
    }

}

