
import { Prop } from 'vue-property-decorator';
import * as Enum from '@/enum';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class setSubStatoModal extends Vue {

    @Prop() saveCb: (item: string) => void;

    subStatuses: string[] = [];
    selectedSubStatus: string = "";

    created(){
        this.subStatuses = Enum.LeadSubStatus.GetAll();
        if(!store.state.isAdmin()){
            this.subStatuses = this.subStatuses.filter(x => x != Enum.LeadSubStatus.ConclusionePositiva);
        }
    }

    save(){
        this.saveCb(this.selectedSubStatus);
        this.$emit('close');
    }

}

