import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/img/icon_card_on.svg'
import _imports_1 from '@/img/icon_card_off.svg'


const _withScopeId = n => (_pushScopeId("data-v-09e3c8a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "savedCardContainer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  src: _imports_1
}
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["savedCard", card.cardId == _ctx.value ? 'active':'']),
              key: index,
              onClick: ($event: any) => (_ctx.selectCard(card))
            }, [
              _createElementVNode("div", _hoisted_4, [
                (card.cardId == _ctx.value)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                  : (_openBlock(), _createElementBlock("img", _hoisted_6))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, " XXXX XXXX XXXX " + _toDisplayString(card.last4), 1),
                (card.holder)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(card.holder), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, _toDisplayString(card.expMonth) + "/" + _toDisplayString(card.expYear), 1)
              ])
            ], 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}