
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import GestioneDocModal from '../gestioneDocModal.vue';
import { DocumentoDashboardClient } from '@/services/Services';
import ReadModal from '../readModal.vue';

@Options({})
export default class documenti extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    loading: boolean = false;
    documentiDashboard: OM.DocumentoDashboard[] = [];
    selectedFolder: string = null;
    folders: OM.GetFoldersResponseVm[] = [];

    created(){
        this.init();
    }

    openGestioneDocs(){
        this.$opModal.show(GestioneDocModal, {}, () => {
            this.init();
        });
    }
    
    @Watch('selectedFolder')
    init(){
        this.loading = true;
        Promise.all([
            DocumentoDashboardClient.getFolders(),
            DocumentoDashboardClient.getByFolder(this.selectedFolder)
        ])
        .then(xs => {
            this.folders = xs[0];
            this.documentiDashboard = xs[1];
        })
        .finally(() => this.loading = false);
    }

    isRead(item: OM.DocumentoDashboard){
        return item.userRead.findIndex(x => x.value.identifier == this.user.identifier) == -1
    }

    setReadDoc(item: OM.DocumentoDashboard){
        if(item.userRead.findIndex(x => x.value.identifier == store.state.user.identifier) == -1){
            DocumentoDashboardClient.addUserRead(item.identifier, store.state.user.identifier)
        }
        item.userRead.push({
            date: new Date().toISOString(),
            value: {
                identifier: store.state.user.identifier,
                name: store.state.user.personalData.completeName
            }
        });
    }

    openReadModal(item){
        this.$opModal.show(ReadModal, { item })
    }

    openEditModal(item: OM.DocumentoDashboard){
        this.$opModal.show(GestioneDocModal, {
            docIdentifier: item.identifier
        }, () => {
            this.init();
        });
    }

}

