
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { ConfigurazioneProfessioneClient, QuestionarioClient } from '@/services/Services';

@Options({})
export default class risposteQuestionarioModal extends Vue {
 
    @Prop() userIdentifier: string;
    @Prop() isInterno: boolean;

    risposte: OM.RisposteQuestionario = null;
    selectedProfessione: OM.NameIdentifier = null
    professioni: OM.NameIdentifier[] = [];

    filtersFn = {
        answerFilter: (item: OM.AnswerValue, input) => {
            return item.answer.some(x => x.toLowerCase().indexOf(input.toLowerCase()) > -1); 
        }
    }

    created(){
        ConfigurazioneProfessioneClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
    }

    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione){
            this.getQuestionario();
        }
    }

    getQuestionario(){
        this.risposte = null;
        let prom;
        if(this.isInterno){
            prom = QuestionarioClient.getQuestionarioInternoOfUser(this.userIdentifier, this.selectedProfessione.identifier);
        } else {
            prom = QuestionarioClient.getQuestionarioOfUser(this.userIdentifier, this.selectedProfessione.identifier);
        }
        prom
        .then(x => {
            this.risposte = x;
        })
    }

}

