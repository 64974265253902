

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { ProvvigioniClient, QualificaProvvigioniConfigClient } from '@/services/Services';
import Toggler from '@/components/toggler.vue'

@Options({})
export default class ProvvigioniConfig extends Vue {

    qualifiche: OM.QualificaProvvigioniConfig[] = [];
    modalitaCalcolos: string[] = Enum.ModalitaCalcoloProvvigioni.GetAll();

    created(){ this.init();}
    init(){
        QualificaProvvigioniConfigClient.getAll()
        .then(x => {
            this.qualifiche = x;
        });
    }

    newQualifica(){
        let toPush = new OM.QualificaProvvigioniConfig();
        this.qualifiche.unshift(toPush);
    }

    saveAll(){
        QualificaProvvigioniConfigClient.saveAll(this.qualifiche)
        .then(x => {
            this.init();
            window.toastr.success("OK!");
        })
    }

    uncheckOthers(item: OM.QualificaProvvigioniConfig){
        this.qualifiche.forEach(x => {
            x.isQualificaBase = false;
        })
        item.isQualificaBase = true;
    }

    uncheckOthersCollab(item: OM.QualificaProvvigioniConfig){
        this.qualifiche.forEach(x => {
            x.isQualificaInizioCollaboratori = false;
        })
        item.isQualificaInizioCollaboratori = true;
    }

    remove(item: OM.QualificaProvvigioniConfig){
        if(!confirm("Confermi rimozione?"))
            return;
            
        QualificaProvvigioniConfigClient.deleteById(item.identifier)
        .then(x => {
            window.toastr.success("Rimossa");
            this.init();
        })
    }

}

