

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import OffersModal from '@/components/offersModal.vue';
import { AcquistoClienteClient, ShopCategoryClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class Shop extends Vue {

    categories: OM.NameIdentifier[] = [];

    created(){
        ShopCategoryClient.getCategoriesForCliente(store.state.loginData.userIdentifier)
        .then(x => {
            this.categories = x;
        })
    }

}

