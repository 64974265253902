import { RouteRecordRaw } from 'vue-router';
import ComeFunziona from './HtmlTextConfig.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/htmlTextConfig',
        component: ComeFunziona,
        meta: { title: "Come Funziona"},
    }
];

export default routes;