
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import toastr from 'toastr';
import { RichiestaPagamentoUserServices } from '@/services/RichiestaPagamentoUserServices';
import { RichiestaPagamentoUserClient, CollaboratoreClient, DashboardClient, AppConfigClient } from '@/services/Services';
import { checkIfRichiestaPagamentoPossible } from '@/utils';

@Options({})
export default class RichiediPagamentoModal extends Vue {

    @Prop() user: OM.UpdateCollaboratoreVm;
    @Prop() nazione: string;
    
    vm: VM.RichiediPagamentoUserVm = new VM.RichiediPagamentoUserVm();
    saldo: number = 0;
    minimo: number = 0;

    nazioni: string[] = [];

    created(){
        AppConfigClient.getPaymentRequestMinimumAmount()
        .then(x => {
            this.minimo = x;
        })
        this.vm.nazione = this.nazione;
        this.vm.userIdentifier = this.user.identifier;
        this.vm.useRitenutaAcconto = false;
        this.vm.dataRicevuta = new Date().toISOString();
        this.init();
    }

    // @Watch('vm.nazione')
    // onNazioneSelected(next, prev){
    //     this.init();
    // }
    
    init(){
        // DashboardClient.getNazioniByPagamenti(this.user.identifier).then(x => this.nazioni = x);
        DashboardClient.getDashPortafoglio(this.user.identifier, this.vm.nazione)
        .then( x => {
            this.saldo = x.saldoAttuale;
        });
    }

    save(){
        if(!this.canSend){
            toastr.warning("Controlla i dati della richiesta");
            return;
        }
        if(this.vm.useRitenutaAcconto && !checkIfRichiestaPagamentoPossible(this.user)){
            toastr.warning("Controlla i dati della richiesta");
            return;
        }

        CollaboratoreClient.save(this.user)
        .then(x => {
            RichiestaPagamentoUserServices.richiediPagamento(this.vm)
            .then(x => {
                toastr.success("Richiesta di pagamento inviata");
                this.$emit('close');
            })
        })
    }


    get canSend(){
        let ris: boolean = this.vm.importo > this.minimo && this.vm.importo <= this.saldo && !!this.vm.nazione;
        if(!this.vm.useRitenutaAcconto){
            ris = ris && !!this.vm.fatturaAttachment;
        }
        return ris;
    }

}

