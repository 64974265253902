
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { LeadClient } from '@/services/Services';

@Options({})
export default class Comments extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop() leadIdentifier: string;
    @Prop() removable: boolean;
    @Prop() alwaysRemovable: boolean;
    @Prop() removeCb: (item: OM.Comment) => void;

    remove(item: OM.Comment){
        if(this.removeCb){
            this.removeCb(item);
        } else {
            if(!confirm("Confermi?"))
                return;

            LeadClient.removeComment(item.identifier, this.leadIdentifier)
            .then(x => {
                let index = this.comments.findIndex(x => x.identifier == item.identifier);
                this.comments.splice(index, 1);
            })
        }
    }

}

