
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, ConfigurazionePrenotazioniClient, LeadClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class MakePrenotazioneCalendar extends Vue {

    @Prop() userIdentifier: string;
    @Prop() value: OM.MakePrenotazioneVm;

    availabilities: OM.OrariPerPrenotareVm[] = [];
    professioni: OM.NameIdentifier[] = [];
    professione: string = "";

    @Watch('userIdentifier')
    onUserIdentifierChange(next, prev){
        this.professione = "";
    }

    @Watch('professione')
    onProfessioneChange(next, prev){
        if(this.professione)
            this.getAvailabilities();
    }

    created(){
        CollaboratoreClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
        })
    }

    getAvailabilities(){
        let fromDate = new Date().toISOString();
        let toDate = moment(fromDate).add(2, 'months').toISOString();
        ConfigurazionePrenotazioniClient.getAvailabilitiesOfUser(this.userIdentifier, fromDate, toDate, this.professione, null)
        .then(x => {
            this.availabilities = x;
        })
    }

}

