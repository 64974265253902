import HelpTicket from './HelpTicket.vue';
import Chat from '@/views/chatRoom/ChatPage.vue';
import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/helpTicket',
        component: HelpTicket,
        meta: { title: "Servizio clienti"},
    },
    {
        path: '/helpTicket/detail/:identifier',
        component: Chat,
        meta: { title: "Dettaglio ticket"},
    },
    {
        path: '/helpTicket/read/:identifier',
        component: Chat,
        name: 'readTicket',
        meta: { title: "Dettaglio ticket"},
    }
];

export default routes;