
import { Prop } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import Node from '@/components/nodeTree/node.vue';
import panzoom from 'panzoom';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
        Node
    }
})
export default class NodeTree extends Vue {

    @Prop() isCommerciale: boolean;
    @Prop() showFilters: boolean;

    @Prop({
        type: [Object, VM.NetworkUserVM]
    }) rootNode: VM.NetworkUserVM;

    allowNavigation: boolean = true;

    timeout: any = null;
    mounted(){
        let tree: HTMLElement = this.$refs.tree as HTMLElement;
        let panInstance = panzoom(tree, {
            transformOrigin: {
                x: 0, y: 0
            },
        });
        
        let isTransformEnded = true;
        let isPanEnded = true;
        panInstance.on('panstart', (e) => {
            isPanEnded = false;
            setTimeout(() => {
                this.allowNavigation = false;
            })
        });
        panInstance.on('panend', (e) => {
            isPanEnded = true;
            if(isTransformEnded){
                setTimeout(() => {
                    this.allowNavigation = true;
                })
            }
        });
        panInstance.on('transform', (e) => {
            isTransformEnded = false;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                isTransformEnded = true;
                if(isPanEnded){
                    this.allowNavigation = true;
                }
            }, 50);
        });
    }
}

