
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import { RichiestaPagamentoUserClient } from '@/services/Services';

@Options({})
export default class RichiestaPagamentoModal extends Vue {

    @Prop() richiesta: OM.RichiestaPagamentoUser;

    vm: OM.RicevutaUtenteInsertVm = new OM.RicevutaUtenteInsertVm();

    created(){
        this.vm.data = this.richiesta.dataRicevuta;
        this.vm.importo = this.richiesta.importo;
        this.vm.userIdentifier = this.richiesta.user.identifier;
        let date = new Date().getDate().toString();
        let month = (new Date().getMonth() + 1).toString();
        if(date.length == 1) date = "0" + date;
        if(month.length == 1) month = "0" + month;
        this.vm.note = "Ricevuta del " + date + "/" + month;
        this.vm.nazione = this.richiesta.nazione;
    }

    approve(){
        if(!confirm("Confermi di voler APPROVARE la richiesta?")){
            return;
        }

        RichiestaPagamentoUserClient.confermaRichiestaPagamento(this.vm)
        .then(x => {
            toastr.success("Richiesta di pagamento approvata");
            this.$emit('close');
        })
    }

    remove(){
        if(!confirm("Confermi di voler ELIMINARE la richiesta?")){
            return;
        }
        
        RichiestaPagamentoUserClient.annullaRichiestaPagamento(this.richiesta.user.identifier)
        .then(x => {
            toastr.success("Richiesta di pagamento annullata");
            this.$emit('close');
        })
    }

}

