

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import RichiestaPagamentoModal from './richiestaPagamentoModal.vue';
import { RichiestaPagamentoUserClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';

@Options({})
export default class RichiestePagamento extends Vue {

    richieste: OM.RichiestaPagamentoUser[] = [];

    created(){
        setTimeout(this.init, 0);
    }
    init(){
        this.richieste = [];
        RichiestaPagamentoUserClient.getAll()
        .then(x => {
            this.richieste = x;
        })
    }

    richiestaPagamentoModal(item: OM.RichiestaPagamentoUser){
        this.$opModal.show(RichiestaPagamentoModal, {
            richiesta: item
        }, () => this.init())
    }

    generaRitenuta(item: OM.RichiestaPagamentoUser){
        RichiestaPagamentoUserClient.generaRitenutaAcconto(item.identifier)
        .then(x => {
            ExportServices.DownloadPdf(x.identifier, x.name);
        })
    }

}

