import { CommonServices } from "@/services/common";
import { baseUrl } from '@/config';
import * as VM from '@/viewModel'
import * as OM from '@/Model'
import * as Enum from '@/enum';
import _router, { AppModules } from "@/router";
import store from "@/store";
import { AppConfigClient, LeadClient } from "./Services";
import { StorageServices } from "./StorageServices";

class _LoginServices {

    loginCallback(loginData: OM.LoginData){
        store.state.loginData = loginData;
        StorageServices.setLoginData(loginData);
        CommonServices.setAuthToken(loginData.token);

        return new Promise<void>((resolve, reject) => {
            LeadClient.getById(loginData.userIdentifier)
            .then(x => {
                store.state.user = x;
                AppConfigClient.getModules(store.state.user.role)
                .then(modules => {

                    let mods: VM.AppRoute[] = [];
                    AppModules.forEach(x => {
                        modules.forEach((j) => {
                            if(j == x.id){
                                mods.push(x);
                            }
                        })
                    });
                    store.state.modules = mods;
                    
                    if(_router.currentRoute.value.query.redirect){
                        _router.push(<string>_router.currentRoute.value.query.redirect);
                    } else if(_router.currentRoute.value.path == '/') {
                        // let navTo = this.findFirstRoute(mods);
                        // _router.push(navTo.routeName);
                        _router.push('/dashboard');
                    }
                    resolve();
                })
            })
        }) 
    }

    findFirstRoute(modules: VM.AppRoute[]): any{
        let ris: any;
        modules.some(x => {
            if(x.routeName){
                ris = x;
                return true;
            } else {
                ris = this.findFirstRoute(x.children);
                return true;
            }
        });
        return ris;
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            store.state.user = null;
            store.state.loginData = null;
            StorageServices.setLoginData(null);
            resolve();
        })
        return prom;
    }
}

export let LoginServices = new _LoginServices();
