
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient, LeadClient } from '@/services/Services';
import RiepilogoModal from '@/views/gestioneLead/scadenze/riepilogoModal.vue';

@Options({})
export default class OverviewSettimana extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;
    
    loading: boolean = false;
    fromDate: string = null;
    toDate: string = null;
    leads: OM.LeadScadenzaVm[] = [];
    days: VM.DashboardDateItems[] = [];

    inScadenza: number = 0;
    daFare: number = 0;
    inAttesa: number = 0;

    created(){
        this.fromDate = moment(new Date()).startOf('week').add(1, 'day').toISOString();
        this.toDate = moment(new Date()).endOf('week').add(1, 'day').toISOString();
        this.init();
    }
    init(){
        this.loading = true;
        LeadClient.getScadenze(this.fromDate, this.toDate, this.user.identifier, false, false, false)
        .then(x => {
            this.leads = x.scadenze;
            this.fillDays();
        }).finally(() => this.loading = false);
    }
    prevWeek(){
        this.fromDate = moment(this.fromDate).subtract(1, 'week').startOf('week').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'week').endOf('week').toISOString();
        this.init();
    }
    nextWeek(){
        this.fromDate = moment(this.fromDate).add(1, 'week').startOf('week').toISOString();
        this.toDate = moment(this.toDate).add(1, 'week').endOf('week').toISOString();
        this.init();
    }
    fillDays(){
        let currentDay = moment(this.fromDate);
        let end = moment(this.toDate);
        this.days = [];
        this.inScadenza = 0;
        this.daFare = 0;
        this.inAttesa = 0;
        while(currentDay < end){
            let toPush = new VM.DashboardDateItems();
            toPush.date = currentDay.toDate();
            toPush.inAttesa = this.getLeadsOfDate(currentDay.toDate(), true);
            toPush.daFare = this.getLeadsOfDate(currentDay.toDate(), false);
            this.inScadenza += toPush.piani.length;
            this.daFare += toPush.daFare.length;
            this.inAttesa += toPush.inAttesa.length;
            this.days.push(toPush);
            currentDay = currentDay.add(1, 'day');
        }
    }

    getLeadsOfDate(date: Date, getFuturi: boolean){
        let now = new Date();
        let min = new Date(date).setHours(0,0,0,0);
        let max = new Date(date).setHours(23,59,59,0);
        if(getFuturi){
            if(date.getDate() == now.getDate() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()){
                min = new Date(date).setHours(now.getHours(), now.getMinutes(), now.getSeconds());
            } else {
                if(date < now)
                    return [];
            }
        } else {
            if(date.getDate() == now.getDate() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()){
                max = new Date(date).setHours(now.getHours(), now.getMinutes(), now.getSeconds());
            } else {
                if(date > now)
                    return [];
            }
        }
        let filteredItems = this.leads.filter(x => new Date(x.scadenza.startDate).getTime() >= min && new Date(x.scadenza.startDate).getTime() <= max);
        let ris = filteredItems.reduce((acc: VM.NameCount[], y) => {
            let ind = acc.findIndex(c => c.name == y.contactName);
            if(ind == -1){
                acc.push({ name: y.contactName, count: 1});
            } else {
                acc[ind].count++;
            }
            return acc;
        }, [])
        return ris;
    }
    
    openRiepilogo(date: string | Date){
        let min: Date, max: Date;
        min = new Date(new Date(date).setHours(0, 0, 0, 0));
        max = new Date(new Date(date).setHours(23, 59, 59, 0));
        this.$opModal.show(RiepilogoModal, {
            date: date,
            startDate: min,
            endDate: max,
            userIdentifier: this.user.identifier,
            descendants: false
        }, () => {
            this.init();
        });
    }

}

