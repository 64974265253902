

import * as OM from '@/Model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import WithModel from '@/mixins/withModel';

@Options({})
export default class pagamentoRequestConfig extends mixins(WithModel) {

    declare localValue: OM.PagamentoRequestConfig;

}

