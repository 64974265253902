
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import MakePrenotazioneCalendar from '@/components/makePrenotazioneCalendar.vue'
import { PrenotazioneClient } from '@/services/Services';

@Options({})
export default class SpostaPrenotazioneModal extends Vue {

    @Prop() prenotazioneIdentifier: string;
    @Prop() userIdentifier: string;
    
    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm()

    created(){
        this.vm.prenotazioneIdentifier = this.prenotazioneIdentifier;
    }

    sposta(){
        PrenotazioneClient.spostaPrenotazione(this.vm)
        .then(x => {
            window.toastr.success("Appuntamento spostato!");
            this.$emit('close');
        })
    }

}

