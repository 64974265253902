

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import Toggler from '@/components/toggler.vue';
import { OffertaClient } from '@/services/Services';

@Options({})
export default class AllOffers extends Vue {

    items: OM.Offerta[] = [];
    
    created(){
        setTimeout(this.init, 0);
    }

    init(){
        this.items = [];
        let prom;
        if(this.$route.params.categoryIdentifier as string){
            prom = OffertaClient.getByCategory(this.$route.params.categoryIdentifier as string);
        } else {
            prom = OffertaClient.getAll();
        }
        prom.then(x => {
            this.items = x;
        })
    }

    get filtersFn(){
        return {
            upsellIdentifier: (propVal: string, filterVal: string) => {
                return filterVal ? !!propVal : true;
            }
        }
    }

    disableItem(item: OM.Offerta){
        OffertaClient.disable(item.identifier, !item.disabled)
        .then(x => {
            this.init();
            toastr.success("Salvato");
        })
    }

    removeItem(item: OM.Offerta){
        if(!confirm("Sicuro?"))
            return;
            
        OffertaClient.deleteById(item.identifier)
        .then(x => {
            this.init();
            toastr.success("Eliminato");
        })
    }

    duplica(item: OM.Offerta){
        OffertaClient.duplicaOfferta(item.identifier)
        .then(x => {
            toastr.success("Ok");
            this.init();
        })
    }

}

