import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "barreRisultato" }
const _hoisted_2 = { class: "upper" }
const _hoisted_3 = { class: "lower" }
const _hoisted_4 = { class: "left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "barra target",
        style: _normalizeStyle(_ctx.targetHeight)
      }, null, 4),
      _createElementVNode("div", {
        class: _normalizeClass(["barra result", { red: !_ctx.isOver, green: _ctx.isOver }]),
        style: _normalizeStyle(_ctx.resultHeight)
      }, null, 6)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, "€ " + _toDisplayString(_ctx.$filters.currency(_ctx.user.obiettivo)), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["right", { red: !_ctx.isOver, green: _ctx.isOver }])
      }, "€ " + _toDisplayString(_ctx.$filters.currency(_ctx.user.risultato)), 3)
    ])
  ]))
}